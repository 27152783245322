@use '../../ui/styles/typography';
@use '../../layout/variables';

.body {
  @include typography.text;
  color: var(--text);
  padding: 1.5rem;
  background: var(--background);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;

  @include variables.mobile() {
    border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0;
  }

  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
    .depositInfo {
      padding: 0 1rem;
    }
  }

  .externalIcon {
    display: inline-block;
    text-align: center;
    padding-inline-start: 1rem;
  }

  .iconFlipped {
    transform: rotate(-90deg);
    padding-inline-end: 1rem;
  }
}

.button {
  padding-inline: 2.5rem;
}
