@use '../../layout/variables';
@use '../../ui/styles/typography';

.buttonStyle {
  padding: 0.75rem 2.5rem;
  border-radius: variables.$radius-xxxlarge;
  height: unset;
}

.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
}

.carouselHeaderMobile {
  display: none;

  @include variables.mobile {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
  }
}

.carouselHeaderDesktop {
  display: block;
  max-width: fit-content;
  cursor: default;

  @include variables.mobile {
    display: none;
  }
}

.carouselPagination {
  display: flex;
  gap: 1rem;
  align-items: center;

  @include variables.mobile {
    gap: 1.625rem;
    justify-content: center;
    flex-grow: 3;
  }

  .leftArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  .rightArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }
}

.wrapper {
  padding-bottom: 1.375rem;
}

.carousel {
  padding-bottom: 1.625rem;
  height: auto;

  .carouselChild {
    height: 100%;
    padding: 0.375rem 0;
  }
}

.mobileCarousel {
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;

  > * {
    width: 90%;
    flex: 0 0 auto;
    margin: 0 0.25rem;
  }
}

.card {
  background-color: var(--background-secondary);
  border: 1px solid var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  width: 100%;
  display: flex;
  padding: 1.5rem;
  margin: 0.5rem 0;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;

  .cardWrapper {
    height: 100%;
    @include variables.mobile() {
      width: 100%;
    }
  }

  .infoBlock {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    .leftArrow {
      position: absolute;
      left: 0;
      z-index: 1;
    }

    .rightArrow {
      position: absolute;
      right: 0;
      z-index: 1;
    }

    @include variables.mobile() {
      .close {
        top: 0.5rem;
      }
    }
  }

  .infoContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 1rem;

    &.infoContentMobileApp {
      justify-content: space-around;
    }

    .info {
      display: flex;
      flex-direction: column;
      padding: 0 2rem;
      > h3 {
        margin: 0.5rem 0;
      }

      @include variables.mobile() {
        padding: 0;
      }
    }

    .next {
      @include typography.textSmall;
      color: var(--text-secondary);
      margin-bottom: 1.25rem;
    }

    .action {
      display: flex;
      justify-content: center;
      column-gap: 1rem;

      @include variables.mobile() {
        flex-direction: column;
        row-gap: 1rem;
      }

      button {
        min-width: 15rem;
      }
    }

    .link {
      display: flex;
      flex-direction: column;

      @include variables.mobile() {
        display: none;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background);
    border-radius: variables.$radius-large;
    height: 100%;

    svg {
      width: 100%;
      max-height: 100%;
    }

    @include variables.mobile() {
      display: none;
    }
  }

  .close {
    position: absolute;
    top: 1.25rem;
    right: 0;
    z-index: 1;
  }
}

.carouselWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: grab;
  gap: 4rem;
  transition: all 0.5s ease;
  overflow: hidden;
  position: relative;

  @include variables.desktop() {
    min-height: 18.625rem;
  }

  @include variables.mobile() {
    margin-right: unset;
  }

  .cardWrapper {
    padding-top: 1.5rem;

    @include variables.mobile() {
      padding: 0;
    }
  }

  .button {
    margin: 2rem 0 1rem 0;
  }

  .infoBlock {
    display: flex;
    position: relative;
    height: 100%;
  }

  .infoContent {
    .info {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      justify-items: space-between;

      .description {
        margin-top: 1rem;
        color: var(--text-secondary);
      }

      h2 {
        @include typography.h2;
      }

      > h2 {
        margin: 0.5rem 0;
      }

      @include variables.mobile() {
        padding: 0;
      }
    }

    .next {
      @include typography.textSmall;
      color: var(--text-secondary);
      margin-bottom: 1.25rem;
    }

    .link {
      display: flex;
      flex-direction: column;

      @include variables.mobile() {
        display: none;
      }
    }
  }

  .icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-secondary);
    border-radius: variables.$radius-medium;
    height: 100%;

    svg {
      width: 100%;
      max-height: 100%;
    }

    @include variables.mobile() {
      display: none;
    }
  }

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
  }

  @include variables.mobile {
    height: fit-content;
    padding: 0 1rem;
    gap: 1rem;
    grid-template-columns: 1fr;

    .active {
      color: var(--text-secondary);
    }

    .description {
      padding-top: 1rem;
      color: var(--text-secondary);
    }

    .button {
      margin-top: 1rem;
      width: 100%;
    }

    .appLinkWrapper {
      padding: 1rem 0;
    }

    .title {
      color: var(--text);
      @include typography.h2;
      padding-top: 1rem;
    }
  }
}

.appLinkContainer {
  display: none;

  @include variables.mobile {
    display: block;
  }
}

.slideCFD {
  height: 21.625rem;
  @include variables.mobile {
    height: 18rem;
  }
}

.fourthSlideCFD {
  height: 21.625rem;
  @include variables.mobile {
    height: 27rem;
  }
}

.fifthSlideCFD {
  height: 21.625rem;
  @include variables.mobile {
    height: 41rem;
  }
}

.slideETD {
  height: 21.625rem;
  @include variables.mobile {
    height: 20.5rem;
  }
}

.secondSlideETD {
  height: 28rem;
  @include variables.mobile {
    height: 30rem;
  }
}
