.title {
  margin-bottom: 2.625rem;
}

.multiTierTables {
  display: flex;
  gap: 1.5rem;

  .column {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    width: 50%;
  }
}

.tabsWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
}
