@use '../../layout/variables';
@use '../../ui/styles/typography';

.modal {
  background: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  display: flex;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
  overflow-y: auto;
  overflow-x: hidden;

  @include variables.mobile {
    flex-direction: column;
  }

  .image {
    width: 30%;
    min-width: 400px;
    min-height: 400px;
    height: 100%;
    flex-shrink: 0;
    border-radius: variables.$radius-xlarge 0 0 variables.$radius-xlarge;
    object-fit: cover;
    object-position: right bottom;

    @include variables.mobile {
      width: 100%;
      height: auto;
      max-height: 200px;
      min-height: auto;
      border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0;
    }
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    @include variables.mobile {
      align-items: center;
      padding: 1.5rem;
      gap: 1.5rem;
    }

    .productContainer {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 3rem;
      flex: 1 0 0;
      align-self: stretch;
      align-items: flex-start;

      @include variables.mobile {
        align-items: center;
        padding: 0;
      }

      .title {
        @include variables.mobile {
          text-align: center;
        }
      }

      .description {
        color: var(--text-secondary);
      }
    }
  }

  .actions {
    display: flex;
    padding: 0.5rem 3rem 1.5rem 0;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;

    @include variables.mobile {
      flex-direction: column-reverse;
      align-items: center;
      padding: 0;
      gap: 0.5rem;
    }

    .cancel {
      color: var(--text-secondary);
    }
  }
}
