@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: var(--text-secondary);
  }

  .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .subLabel {
    color: var(--text-secondary);
    padding-inline-end: 0.5rem;
  }

  .buttonText {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .history {
    @include typography.textSmall;
    color: var(--text-interactive);
  }
}
