@use '../../layout/variables';

.formActionWrapper {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  &.childrenFullWidth {
    > * {
      flex: 1;
    }
  }
  @include variables.mobile() {
    &.childrenFullWidth {
      > * {
        width: 100%;
        flex: auto;
      }
    }
  }
}
