@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  margin: auto;
  justify-self: center;
  justify-content: center;
  position: relative;

  border: 2px dashed var(--contrast-medium-low);
  border-radius: variables.$radius-medium;
  padding: 1.5rem 1rem;
  word-break: break-all;

  .title {
    @include typography.textLarge;
    display: block;
    margin-bottom: 25px;
  }

  .content {
    @include typography.textSmall;
  }
}

.textStrong {
  @include typography.textStrong;
}

.content {
  .title {
    @include typography.textStrong;
  }
}

.fileSelectButtonWrapper {
  @include typography.textSmall;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  align-items: center;

  text-align: center;
  margin: auto;
  justify-self: center;
  justify-content: center;

  .fileSelectButton {
    position: relative;
    z-index: 1;

    .buttonWrapper {
      @include typography.textStrong;
      padding-inline-start: 1.5rem;
      padding-inline-end: 1.5rem;
    }
  }

  .label {
    padding-inline-start: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: var(--text-secondary);
  }
}

.tryAgainButton {
  position: relative;
  z-index: 1;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.fileResultInfo,
.fileWarningInfo {
  p {
    @include typography.textSmall;
    word-break: break-word;
  }

  .error {
    color: var(--error);
  }

  .formats {
    color: var(--text-secondary);
  }
}

.fileGeneralIcon {
  * {
    height: 40px;
    width: 100%;
  }
}

.fileClose {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  z-index: 1;
}

.hiddenFileInput {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  text-indent: -9999px;
  width: 100%;
  height: 100%;
}

input[type='file'] {
  font-size: 0px;
}
