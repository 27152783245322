@use '../ui/styles/typography';
@use '../layout/variables';

.topWrapper {
  margin-top: 2rem;

  .wrapper {
    display: flex;
    justify-content: center;
    padding: 2.5rem;
    background: var(--background-secondary);
    border-radius: variables.$radius-xlarge;

    .qrCode {
      padding: 2.5rem 2rem;
      background-color: var(--background);
      border-radius: variables.$radius-xlarge;
      margin-inline-end: 2.5rem;
    }

    @include variables.mobile() {
      display: initial;
      padding: 0;
    }

    .inner {
      padding: 0.25rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;

      @include variables.mobile() {
        max-width: initial;
        align-items: flex-start;
        background: var(--background-secondary);
        border-radius: 16px;
        padding: 1rem 0.875rem 1.5rem 0.875rem;
      }

      .backToLogin {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 1rem;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
      }

      .recoveryCodeButton {
        height: 1rem;
        padding: 0;
        font-size: variables.$size-6;
      }

      h2 {
        padding: 1rem 0 2.5rem 0;
      }

      .action {
        padding-top: 1rem;
        display: flex;
        width: 100%;
        justify-content: flex-end;

        :global(button) {
          background-color: transparent;
        }
      }

      .signup {
        @include typography.textSmall;
        flex: 1;
        font-style: normal;
        text-align: end;
        display: flex;
        justify-content: center;
        align-items: center;

        :global(button) {
          background-color: transparent;

          @include variables.mobile() {
            padding: 0 0.5rem;
          }
        }
      }
    }
  }
}

.form {
  width: 100%;

  .codeWrapper {
    align-items: center;
    gap: 1rem;

    @include variables.mobile() {
      gap: 0.25rem;
    }

    .verificationBoxWrapper {
      display: flex;
      margin: 0;
      outline: none;

      display: flex;

      .verificationBox {
        text-align: center;
        max-width: 49px;

        &.invalid {
          border-color: var(--error);
        }

        &.valid {
          border-color: var(--success);
        }
      }
    }
  }

  .field {
    height: 3.5rem;
    border-radius: variables.$radius-large;
  }

  .submitButton {
    margin-top: 2rem;
    margin-bottom: 3rem;
    width: 100%;
  }

  @include variables.mobile() {
    width: 100%;
  }
}

.modalBodyWrapper {
  background-color: var(--background-secondary) !important;
  padding: 1.5rem 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.recoveryModalTitle {
  display: flex;
  gap: 0.5rem;
  align-items: center;

  .mobileTooltip {
    position: fixed;
    top: 1.5rem;
    left: 36px;
    height: fit-content;
    transform: none;
    line-height: 1.3125rem;
  }
}

.borderedWrapper {
  padding: 1.5rem 1.25rem;
  border-top: 1px solid var(--contrast-low);
  padding-top: 1.25rem;
  background-color: var(--background-secondary) !important;
}

.validationError {
  @include typography.text;
  color: var(--error);
  margin-top: 1rem;
}

.notice {
  @include typography.text;

  margin-top: 1rem;
  color: var(--text-secondary);

  .email {
    color: var(--contrast-max);
  }

  .important {
    color: var(--error);
  }
}
