.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  padding: 0;

  img {
    width: 100%;
    padding: 0.5rem;
  }
}

.error {
  color: var(--error);
}

.isFlex {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.textSecondary {
  color: var(--text-secondary);
}

.hasMarginTop {
  margin-top: 0.125rem;
}
