@use '../../../layout//variables';
@use '../../../ui/styles/typography';

.stepsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 2rem;
  padding: 0.75rem 0;

  @include variables.mobile() {
    margin-top: 1rem;
    padding: 0.25rem 0;
  }

  .backArrow {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
