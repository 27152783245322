@use '../../ui/styles/typography';
@use '../../layout/variables';

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;
}

:global(.ml-3) {
  margin-inline-start: 0.75rem !important;
}

.textGray {
  color: var(--text-secondary);
}

.title {
  text-align: center;
}

.subtitle {
  margin-top: 1rem;
  text-align: center;
}

.note {
  margin: 1rem auto 0 auto;
  text-align: center;
  max-width: 400px;
  @include variables.mobile() {
    max-width: 100%;
    text-align: start;
  }
}

.icons {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0 0.5rem 0;
  justify-content: center;
  gap: 1.5rem;
  @include variables.mobile() {
    margin-top: 1.5rem;
  }

  .icon {
    margin-bottom: 1rem;
  }
}

.wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  gap: 2.5rem;

  @include variables.mobile() {
    margin-top: 1rem;
    padding: 1rem;
  }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .tabs {
      width: 41.25rem;
      max-width: 100%;
    }
  }

  .questionSection {
    display: flex;
    justify-content: center;

    @include variables.mobile() {
      justify-content: left;
    }
  }

  .question {
    text-align: center;
    cursor: pointer;
    color: var(--text-interactive);
  }
}

.toolKits {
  display: flex;
  margin-top: 1.5rem;

  .toolkit {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background-color: var(--background-secondary);
    border-radius: variables.$radius-xlarge;
    width: 50%;

    .consent {
      display: flex;
      margin-top: 1.125rem;

      .checkbox {
        padding-top: 3px;
      }

      p {
        margin-inline-start: 1rem;
      }
    }

    &:first-child {
      margin-inline-end: 1.5rem;
    }
  }
}

.isDisabled {
  cursor: not-allowed;
}

@include variables.mobile() {
  .adminRoutes {
    display: none;
  }

  .toolKits {
    flex-direction: column;

    .toolkit {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.text {
  text-align: center;
  @include variables.mobile() {
    text-align: start;
  }
}

.textSmall {
  @include typography.textSmall;
}

.hasMarginBottom {
  margin-bottom: 1rem;
}
