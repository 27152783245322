@use '../../layout/variables';

.wheelOfFortune {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  @include variables.mobile() {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .wheel {
    display: block;
    border: 13px solid transparent;
    border-radius: variables.$radius-full;
    height: 210px;

    @include variables.mobile() {
      height: 280px;
    }
  }

  .wheelFrame {
    position: absolute;
    height: 228px;
    min-width: 228px;
    z-index: 1;

    @include variables.mobile() {
      top: initial;
      left: initial;
      height: 308px;
      min-width: 308px;
    }
  }

  .spin {
    display: none;
  }
}
