@use '../../ui/styles/typography';
@use '../../layout/variables';

@mixin sectionBase {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
  margin-bottom: 2.5rem;
}

@mixin headerBase {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.container {
  display: flex;
  justify-content: center;
}

.downloadSection {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 9.5rem;

  .textSection {
    max-width: 42%;
    margin-top: 7rem;

    > * {
      margin-bottom: 1.5rem;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  @include variables.mobile() {
    flex-direction: column;
    margin-bottom: 0;
    gap: initial;
  }
}

.linksSection {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;

  @include variables.mobile() {
    justify-content: center;
    gap: 1.5rem;
    margin-top: 2rem;
  }

  .hasMarginRight {
    margin-right: 1rem;
    z-index: 1;
    @include variables.mobile() {
      margin-right: 0;
    }
  }
}

.analysisSection {
  @include sectionBase;
  gap: 2.5rem;
}

.featuresSection {
  @include sectionBase;
  gap: 1rem;
}

.analysisHeader {
  @include headerBase;
  margin-bottom: 0.5rem;

  @include variables.mobile() {
    margin-bottom: 1rem;
  }
}

.featureHeader {
  @include headerBase;
  margin-bottom: 1rem;
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.stepsSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .step {
    display: flex;
    gap: 1rem;
    flex-direction: row;
  }
}

.connectSection {
  display: flex;
  flex-direction: row;
  padding: 2.5rem 0 2.5rem 1.5rem;
  gap: 2.5rem;

  .stepsContainer {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex: 0 0 45%;

    @include variables.mobile() {
      flex: none;
      gap: 1rem;
      padding-left: 0;
    }

    .stepsHeader {
      margin-bottom: 1.5rem;
    }

    .step {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-direction: row;
    }
  }

  .chartContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 55%;
    margin-right: 0.5rem;
  }
}

.title {
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
  margin-top: 7.5rem;
}

.buttonSection {
  display: flex;
  justify-content: center;

  .learnMoreLink {
    width: 100%;
  }

  .button {
    padding: 0 7.5rem;
    margin-bottom: 3.5rem;

    @include variables.mobile() {
      margin-bottom: 2.5rem;
      padding: 0;
    }
  }
}

.linksSectionAbout {
  display: flex;
  justify-content: space-around;
}

.hasMarginBottom {
  margin-bottom: 1rem;
}

.hasMarginTop {
  margin-top: 1rem;
}

.textSecondary {
  color: var(--text-secondary);
}
