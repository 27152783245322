@use '../../../../layout/variables';

.formSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  padding: 2rem;
  border: 1px solid var(--contrast-low);

  @include variables.mobile() {
    border: none;
    margin-inline-start: 0;
    padding: 1rem;
    order: 3;
    padding-bottom: 1.5rem;

    &.reversed {
      order: 1;
    }
  }

  > form > h3 {
    padding-bottom: 1.5rem;
  }
}
