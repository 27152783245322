@use '../../ui/styles/typography';
@use '../../layout/variables';

.radioGroupWrapper {
  display: flex;
  flex-direction: column;
}

.radioGroupWrapper.isRadioButtonHorizontal {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.errorMessage {
  color: var(--error);
}

.wrapper {
  display: flex;

  .radioButton {
    position: relative;
    height: 1.5rem;

    &.disabled {
      cursor: default;
    }

    .input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    .input + .icon .radioOn {
      display: none;
    }

    .input + .icon .radioOff {
      display: block;
    }

    .input:checked + .icon .radioOn {
      display: block;
    }

    .input:checked + .icon .radioOff {
      display: none;
    }

    .icon {
      cursor: pointer;

      .radioOn {
        :global(.border) {
          stroke: var(--contrast-high);
          fill: none;
        }

        :global(.dot) {
          stroke: none;
          fill: var(--contrast-high);
        }
      }

      .radioOff {
        :global(.dot) {
          stroke: var(--contrast-high);
          fill: none;
        }
      }

      &.disabled {
        cursor: default;

        .radioOn {
          :global(.border) {
            stroke: var(--disabled);
            fill: none;
          }

          :global(.dot) {
            stroke: none;
            fill: var(--disabled);
          }
        }

        .radioOff {
          :global(.dot) {
            stroke: var(--disabled);
            fill: none;
          }
        }
      }
    }
  }

  .contentWrapper {
    margin-inline-start: 1rem;

    .label {
      display: block;
    }

    .hint {
      display: block;
      color: var(--text-secondary);
    }

    &.disabled {
      cursor: default;
      color: var(--text-secondary);
    }
  }
}

.confirmWrapper {
  display: flex;
  padding: 1rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
  background-color: var(--surface-secondary) !important;
  border-radius: variables.$radius-medium;

  &#{&} {
    border: 1px solid transparent;
  }

  &.selected {
    &#{&} {
      border: 1px solid var(--primary);
      box-shadow: 0px 0.313rem 0.625rem 0px rgba(23, 31, 39, 0.05),
        0px 2px 2.5rem 0px rgba(23, 31, 39, 0.04);
    }
  }

  &:hover {
    &#{&} {
      color: var(--text);
    }
  }

  .logo {
    padding: 1rem;

    @include variables.mobile() {
      padding: 0;
      align-self: flex-start;
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 100%;
    gap: 0.25rem;

    .textInteractive {
      color: var(--text-interactive);
    }

    .titleWrapper {
      display: flex;
      justify-content: space-between;
      align-self: stretch;
      width: 100%;

      .title {
        display: flex;
        align-items: center;

        svg {
          margin-inline-end: 0.25rem;
        }
      }
      .radioWrapper {
        margin-inline-start: 1rem;

        .label {
          display: block;
        }

        .hint {
          display: block;
          color: var(--text-secondary);
        }

        &.disabled {
          cursor: default;
          color: var(--text-secondary);
        }
      }
    }
  }

  .textSecondary {
    color: var(--text-secondary);
  }

  .radioButton {
    position: relative;
    height: 1.5rem;

    &.radioCardButton {
      top: -0.5rem;
      right: -0.5rem;

      &[dir='rtl'] {
        right: 0.5rem;
      }

      @include variables.mobile() {
        top: -0.1875rem;
        right: -0.75rem;

        &[dir='rtl'] {
          right: 0.75rem;
        }
      }
    }

    &.disabled {
      cursor: default;
    }

    .input {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    .icon {
      display: flex;
      cursor: pointer;

      svg {
        margin-inline-end: 0;
      }

      .radioOn {
        display: none;
      }

      .radioOff {
        display: block;
      }
    }

    .input:checked + .icon {
      .radioOn {
        display: block;
      }

      .radioOff {
        display: none;
      }
    }

    .icon.disabled {
      cursor: default;

      .radioOn {
        :global(.border) {
          stroke: var(--disabled);
          fill: none;
        }

        :global(.dot) {
          stroke: none;
          fill: var(--disabled);
        }
      }

      .radioOff {
        :global(.dot) {
          stroke: var(--disabled);
          fill: none;
        }
      }
    }
  }
}
