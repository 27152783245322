@use '../ui/styles/typography';
@use '../layout/variables';

.adminRoutes {
  margin-top: 3rem;
  padding: 0;

  :global(.button) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--text-secondary);
    border: 2px solid transparent;
    background-color: transparent;
    text-transform: uppercase;
    @include typography.textLargeStrong;
  }

  :global(.tag) {
    font-size: inherit;
  }

  @include variables.mobile() {
    display: none;
  }
}
