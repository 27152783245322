@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  background: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include variables.mobile() {
    margin-top: 1rem;
  }

  .infoWrapper {
    display: flex;
    align-items: center;
  }
}

.column {
  table-layout: fixed;
  width: 20%;
}

@include variables.mobile() {
  .wrapper {
    flex-direction: column-reverse;
    align-items: flex-start;

    .infoWrapper {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
  }
}

.searchWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline-end: 1.5rem;
}

.result {
  background-color: var(--background-secondary);
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.08);
  border-radius: variables.$radius-large;
  padding: 1rem 1.5rem;
  margin-bottom: 0.5rem;

  &.selected {
    background-color: var(--primary);
    background-image: linear-gradient(90deg, var(--primaryFocused), var(--primary));
    color: var(--text-inverse);
  }

  .label {
    color: var(--contrast-medium-low);
  }

  .strong {
    @include typography.textStrong;
  }
}

.noResult {
  @include typography.text;
  text-align: center;
  padding-top: 1rem;
}

.textSmall {
  @include typography.textSmall;
}

.text {
  @include typography.text;
}

.resultCurrentRanking {
  background: linear-gradient(127.56deg, var(--primary-focused) 0%, var(--primary) 95.22%);

  td {
    color: var(--text-on-primary-color) !important;
  }
}
