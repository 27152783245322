@use '../../layout/variables';

.wrapper {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--contrast-low);
  border-radius: 12px;
  flex-grow: 1;
  height: 1px;
  overflow-y: scroll;

  :global(.textLayer) span {
    letter-spacing: 0 !important;
  }

  :global(.react-pdf__message--no-data) {
    display: none;
  }
}
