@use '../../ui/styles/typography';
.wrapper {
  display: block;

  .fileUploads {
    .fileUploadItem {
      margin-bottom: 1.5rem;
    }
  }

  .addMore {
    @include typography.text;
    background-color: transparent;
    color: var(--text-interactive);
  }
}
