@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.dateFilter {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .chipsWrapper {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .datePicker {
    height: 2.5rem;
    border-radius: 0.5rem;
    resize: none;
    border: 1px solid var(--contrast-medium-low);
  }

  .buttonItem {
    display: inline-block;
    padding: 0;
  }

  .error {
    color: var(--error);
  }

  .columns {
    display: flex;
    padding-top: 1rem;
    gap: 0.5rem;

    @include variables.mobile() {
      flex-direction: column;
    }

    .column {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }
}
