@use '../../layout/variables';
@use '../../ui/styles/typography';

$padding-s: 3px;
$padding-m: 4px;
$selector-size-s: 18px;
$selector-size-m: 24px;
$transition-properties: 0.3s ease-in-out;

.checkbox {
  display: inline-block;
  position: relative;
  border-radius: variables.$radius-full;
  background-color: var(--contrast-low);
  transition: background-color $transition-properties;

  input {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .selector {
    position: absolute;
    background: var(--always-white);
    filter: 0px 1px 2px rgba(0, 0, 0, 0.25);
    border-radius: variables.$radius-full;
    top: 50%;
    transform: translateY(-50%);
    transition: left $transition-properties, right $transition-properties;
    pointer-events: none;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;

  input {
    pointer-events: none;
  }
}

.active {
  background-color: var(--success-new);
}

.size-S {
  width: 40px;
  height: 24px;

  .selector {
    height: $selector-size-s;
    width: $selector-size-s;
    left: $padding-s;
  }
}

.size-S.active {
  .selector {
    left: calc(100% - #{$selector-size-s} - #{$padding-s});
  }
}

.size-M {
  width: 54px;
  height: 32px;

  .selector {
    height: $selector-size-m;
    width: $selector-size-m;
    left: $padding-m;
  }
}

.size-M.active {
  .selector {
    left: calc(100% - #{$selector-size-m} - #{$padding-m});
  }
}
