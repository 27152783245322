@use '../../layout/variables';
@use '../../ui/styles/typography';

.title {
  margin-top: 1.75rem;
  display: block;
}

.landingPageCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-top: 1.5rem;

  @include variables.mobile() {
    grid-template-columns: repeat(2, 1fr);
  }
}

.landingPageCardWrapper {
  position: relative;
  width: 100%;
  padding-bottom: 110.81%;

  .landingPageCard {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-secondary);
    border-radius: variables.$radius-xlarge;
    padding: 1.5rem;
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    border: 2px solid transparent;
    transition: border 200ms ease-in-out;
    position: absolute;
    top: 0;
    left: 0;

    @include variables.mobile() {
      padding: 0.75rem;
    }

    img {
      width: 100%;
      height: 100%;
      min-height: 0;
      border-radius: 0.5rem;
      flex: 1;
      object-fit: cover;
    }

    p {
      margin-top: 1rem;
    }

    .checkIconWrapper {
      position: absolute;
      top: 2rem;
      right: 2rem;
      padding: 2px;
      border: 2px solid var(--always-white);
      border-radius: 50%;
      display: flex;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: variables.$radius-medium;

    @include variables.mobile() {
      max-height: 10rem;
    }
  }

  .selected {
    border: 2px solid var(--text);
  }
}

@include variables.mobile() {
  .landingPageWrapper {
    width: calc(50% - 0.5rem);
    padding: 0;
    margin: 0.25rem;

    .landingPage {
      width: 100%;
      padding: 0.75rem;
    }
  }
}
