@use '../../../../../layout/variables';
@use '../../../../../ui/styles/typography';

.box {
  background-color: var(--background-secondary);
  margin-top: 2rem;
  padding: 3rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  flex-direction: column;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  min-height: 400px;

  @include variables.mobile() {
    margin-top: 1rem;
    min-height: auto;
  }
}
