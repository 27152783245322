@use '../../ui/styles/typography';
@use '../../layout/variables';

$space: 0.5rem;

.wrapper {
  @include variables.mobile() {
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tabs {
    padding: 0;
    position: relative;
    justify-content: flex-start;

    @include variables.mobile() {
      display: flex;
    }

    .label {
      padding: 0;
      margin-inline-end: 32px;
      height: 2rem;
      gap: 0.5rem;
      @include typography.textSmall;

      &:last-child {
        margin-inline-end: 0;
      }

      &.disabled {
        color: var(--error);
        cursor: initial;

        * {
          cursor: initial;
        }
      }
    }

    input[type='radio'] {
      display: none;
    }

    &.isDefault,
    &.isLarge {
      .label {
        margin-top: 0;
        margin-bottom: 0;
        border: 2px solid transparent;
        background-color: transparent;
        text-transform: uppercase;
        color: var(--text-secondary);
        @include typography.textLargeStrong;

        a {
          color: var(--text-secondary);
        }

        &.selected {
          border-bottom: 2px solid var(--primary);
          color: var(--text);
          border-radius: 0;
        }
      }
    }

    &.isLight,
    &.isInverse {
      @include variables.mobile() {
        margin-bottom: -$space;
      }

      .label {
        background-color: transparent;
        color: var(--text-secondary);
        border: none;
        @include typography.text;

        @include variables.mobile() {
          margin-bottom: $space;
        }

        &.selected {
          color: var(--text);
          border: none !important;
        }

        &.disabled {
          color: var(--contrast-low);
          cursor: not-allowed !important;

          p {
            color: var(--contrast-low);
          }
        }
      }
    }

    &.isLight {
      .label.selected {
        border: 2px solid var(--text);
      }
    }

    &.isInverse {
      .label {
        border-color: var(--text-on-primary-color);
        color: var(--text-on-primary-color);

        &.selected {
          background-color: var(--background-secondary);
        }
      }
    }

    &.isLarge {
      @include variables.mobile() {
        padding: 0;
      }

      .label {
        height: unset;
        min-width: 9rem;
        padding: 0.5rem 1rem;

        &:first-child {
          margin-inline-start: 0;
        }

        &:last-child {
          margin-inline-end: 0;
        }
      }
    }

    &.isLabel {
      .label {
        display: inline-flex;
        border: 0;
        padding: 0.625rem 0.75rem;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        background-color: transparent;
        height: unset;
        color: var(--text);

        &:hover {
          background-color: var(--state-background-hover);
        }

        &.selected {
          background-color: var(--state-active);
        }
      }
    }

    &.isLargeLabel {
      .label {
        padding: 0.5rem 1rem;
        display: inline-flex;
        border: 0;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        background-color: transparent;
        height: unset;
        line-height: 1.5rem;
        color: var(--text);

        &:hover {
          background-color: var(--state-background-hover);
        }

        &.selected {
          background-color: var(--state-active);
        }
      }
    }

    .tabItem {
      display: flex;
    }

    .isRequired:after {
      content: '';
      display: block;
      height: 0.625rem;
      width: 0.625rem;
      margin-inline-start: 0.25rem;
      border-radius: variables.$radius-full;
      background-color: var(--error);
    }
  }
}
