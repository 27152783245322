@use '../ui/styles/typography';
@use '../layout/variables';

.statement {
  background-color: var(--background-secondary);
  margin: 1rem 0;
  border-radius: variables.$radius-xlarge;
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .selectorsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include variables.mobile() {
      flex-direction: column;
    }

    .selectorField {
      margin-bottom: 1.5rem;
      width: 25%;

      &:not(:last-child) {
        margin-inline-end: 1.5rem;

        @include variables.mobile() {
          margin-inline-end: 1rem;
        }
      }

      @include variables.mobile() {
        width: 100%;
        margin-inline-end: 1rem;
        margin-inline-start: 1rem;
      }
    }
  }

  .reportIconWrapper {
    padding: 2rem;
    background-color: var(--contrast-low);
    margin-bottom: 2rem;
    display: flex;
    border-radius: variables.$radius-full;
  }

  .title {
    margin-bottom: 1.5rem;

    @include variables.mobile() {
      text-align: center;
    }
  }
}
