@use '../../ui/styles/typography';

.platformButton {
  border: transparent;
  background-color: transparent;
  cursor: pointer;
  padding: 0;

  &.disabled {
    cursor: no-drop;
  }
}

.platformIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 7.5rem;
  height: 7.5rem;
  padding: 0 0.5rem;
  background: var(--background-secondary);
  border: 0.125rem solid var(--primary);
  border-radius: 0.5rem;

  .title {
    text-align: center;
    color: var(--text);
  }

  .icon {
    text-align: center;
  }

  &.disabled {
    background: var(--contrast-low);
    border-color: transparent;

    .title {
      opacity: 0.35;
    }

    .icon {
      opacity: 0.5;
    }
  }
}
