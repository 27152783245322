@use '../../ui/styles/typography';
@use '../../layout/variables';

.info {
  margin: 1.5rem 0;
}
.topInfo {
  @include typography.text;
  border-radius: variables.$radius-xlarge;
  background-color: var(--background-secondary);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text);
}

.reset {
  :global(.control) {
    @include variables.desktop() {
      margin-inline-end: 0.5rem;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  color: var(--text);

  .section {
    background-color: var(--background-secondary);
    padding: 1rem 1.5rem;
    margin-bottom: 2rem;
    border-radius: variables.$radius-xlarge;
    box-shadow: 0px 0.5rem 3.5rem rgba(0, 0, 0, 0.04);

    .formContainer {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      margin-bottom: 0.75rem;

      &.isRowMobile {
        @include variables.mobile() {
          flex-direction: row;
        }
      }

      &.isColumnMobile {
        @include variables.mobile() {
          flex-direction: column;
          gap: 0.75rem;
        }
      }

      .formFieldHalf {
        width: 50%;
        @include variables.mobile() {
          width: 100%;
        }
      }

      .formFieldWide {
        width: 55%;
        @include variables.mobile() {
          width: 50%;
        }
      }

      .formFieldFull {
        width: 100%;
      }
    }

    &:first-child {
      margin-inline-end: 0.5rem;

      @include variables.mobile() {
        margin-inline-end: 0;
      }
    }

    &:last-child {
      margin-inline-start: 0.5rem;

      @include variables.mobile() {
        margin-inline-start: 0;
      }
    }

    h3 {
      padding: 0 0 1rem 0;
    }

    @include variables.mobile() {
      padding: 1rem;

      .extraInput {
        height: 6rem;
        padding-top: 3rem !important;
      }
    }
  }

  :global(button) {
    width: 192px;
    margin: auto;
  }

  :global(.column) {
    padding: 0;
    margin: 0;
    margin-bottom: 0.75rem;

    &:first-child {
      margin-inline-end: 0.5rem;

      @include variables.mobile() {
        margin-inline-end: 0;
      }
    }
  }

  li {
    @include typography.text;
    padding-inline-start: 1rem;
    margin-bottom: 1rem;
  }
}
