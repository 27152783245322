@import '../../layout/variables';

.body {
  padding: 0;

  ul {
    li {
      border-bottom: 1px solid var(--contrast-low);
      padding: 1.25rem 1rem;
    }
    :last-child {
      border: none;
    }
  }
}
