@use '../ui/styles/typography';
@use '../layout/variables';

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;

  @include variables.mobile() {
    display: none;
  }
}

.subscriptionsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cartStatus {
  white-space: nowrap;
}

.expandedContent {
  display: block;

  @include variables.mobile {
    display: none;

    &.expanded {
      display: block;
    }
  }
}
