@use '../../ui/styles/typography';
@use '../../layout/variables';

.body {
  @include typography.text;
  color: var(--text);
  padding: 7.5rem;
  padding-bottom: 8rem;
  background: var(--background);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  display: flex;
  justify-content: center;

  @include variables.mobile {
    padding: 1.5rem;
    padding-bottom: 3rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
    max-width: 300px;
  }
}
