@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.documentNote {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid var(--warning);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;

  span {
    @include typography.text();
    color: var(--contrast-medium);
  }
}

.agreeButtons {
  display: flex;
  padding: 0 1.25rem;
  gap: 0.5rem;
  width: 100%;

  button {
    width: 100%;
  }
}
