@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    color: var(--text-secondary);
  }

  .button {
    width: 100%;
  }

  .inactve {
    box-shadow: none;
    opacity: 0.5;
  }

  .icon {
    span {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .title,
  .subtitle {
    .label {
      color: var(--text-secondary);
    }

    .value {
      color: var(--text);
    }
  }

  .subtitle {
    @include typography.textSmall;
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    padding-top: 0.5rem;
  }
}
