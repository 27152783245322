@use '../../layout/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: auto;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-medium;
  padding: 1.5rem;

  @include variables.mobile {
    padding: 1rem;
  }

  :global(.button) {
    width: 100%;
  }

  .action {
    display: flex;
    margin-top: 2rem;

    button:first-child {
      margin-inline-end: 0.5rem;
    }
  }

  .info {
    margin-bottom: 2rem;

    span {
      color: var(--primary);
    }
  }
  .primaryText {
    color: var(--primary);
  }
  ul {
    li {
      padding: 0.5rem 0 0 0.5rem;
      margin: 0.25rem;
    }

    .iconWrapper {
      display: flex;
      :first-child {
        margin-inline-end: 0.5rem;
      }
    }
  }
}
