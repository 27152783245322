@use '../../ui/styles/typography';
@use '../../ui/styles/mixins';
@use '../../layout/variables';

.primary.static,
.secondary.static,
.tertiary.static,
.btn.static {
  cursor: default;
  @include mixins.button-bg(
    var(--primary),
    var(--primary),
    var(--text-on-primary-color),
    var(--contrast-low),
    var(--text-on-primary-color)
  );
}

.btn.focus,
.btn.focus:hover {
  color: var(--contrast-low) !important;
  border: none;

  @include mixins.button-bg(
    var(--contrast-high),
    var(--contrast-high),
    var(--text-on-primary-color),
    var(--contrast-low),
    var(--text-on-primary-color)
  );
}

.btn.disabled,
.btn.disabled:hover {
  color: var(--disabled) !important;
  border-color: transparent;
  opacity: 0.5;

  @include mixins.button-bg(
    var(--contrast-low),
    var(--contrast-low),
    var(--contrast-low),
    var(--contrast-low),
    var(--contrast-low)
  );
}

.btn {
  border: 0;
  padding: 0 20px;
  border-radius: variables.$radius-full;
  position: relative;
  height: auto;

  .contentWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: inherit;
    border-radius: inherit;

    .sideIcon {
      display: flex;
      align-items: center;
    }
  }

  .loadingWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    border-radius: inherit;

    .loadingSpinner {
      border: 3px solid var(--disabled);
      border-top: 3px solid var(--primary);
      border-radius: 50%;
      width: auto;
      aspect-ratio: 1;
      height: 50%;
      animation: spin 1250ms linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &.link {
    @include typography.inherit;
    color: var(--text-interactive);
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    display: inline;

    &:hover {
      color: var(--text-interactive);
    }

    &:not(:active) {
      box-shadow: none;
    }

    &.disabled {
      background-color: transparent !important;
    }
  }

  &.isPlain {
    border: none;
    outline: none;
    cursor: pointer;
    color: var(--text-interactive);
    padding: 0.25rem 0.5rem;

    @include mixins.button-bg(
      transparent,
      transparent,
      inherit,
      transparent,
      var(--text-interactive)
    );

    &:focus {
      box-shadow: none;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}

.primary {
  color: var(--text-on-primary-color);
  border: 1px solid var(--primary);
  border-color: var(--primary) !important;

  &.disabled {
    border-color: transparent !important;
    color: var(--always-white) !important;
    background-color: var(--primary-disabled) !important;

    &:hover {
      color: var(--always-white) !important;
    }
  }

  @include mixins.button-bg(
    var(--primary),
    var(--primary-focused),
    var(--text-on-primary-color),
    var(--contrast-low),
    var(--text-on-primary-color)
  );
}

.secondary {
  color: var(--text);
  border: 1px solid var(--contrast-medium-low);
  border-color: var(--contrast-medium-low) !important;

  @include mixins.button-bg(
    var(--background-secondary),
    var(--contrast-high),
    var(--contrast-low),
    var(--surface-disabled),
    var(--text)
  );
}

.tertiary {
  color: var(--text-on-primary-color);
  border: 1px solid var(--text-interactive);
  border-color: var(--text-interactive) !important;

  @include mixins.button-bg(
    var(--text-interactive),
    var(--interactive-hover),
    var(--text),
    var(--contrast-low),
    var(--text)
  );
}

.selectable {
  color: var(--text);
  border: none;
  border-radius: 0.75rem;
  padding: 0.5rem 0.75rem;

  @include mixins.button-bg(
    transparent,
    var(--contrast-low),
    var(--text),
    transparent,
    var(--text),
    var(--press)
  );
}

.ghost {
  color: var(--text);
  border: none;

  &.disabled,
  &.disabled:hover {
    opacity: 0.8;
  }

  @include mixins.button-bg(
    transparent,
    var(--state-background-hover),
    var(--text),
    var(--background-content) !important,
    var(--text),
    var(--state-background-press)
  );
}

.size-L {
  @include typography.textStrong;
  height: 56px;
}

.size-M {
  @include typography.textSmallStrong;
  height: 48px;
}

.size-S {
  @include typography.textSmallStrong;
  height: 40px;
}

.size-XS {
  @include typography.textTiny;
  padding: 4px 12px;
}

.primaryNew {
  color: var(--text-on-primary-color);
  border: 1px solid var(--primary);
  border-color: var(--primary) !important;
  font-weight: normal;

  &.disabled {
    border-color: transparent !important;
    color: var(--always-white) !important;
    background-color: var(--primary-disabled) !important;

    &:hover {
      color: var(--always-white) !important;
    }
  }

  @include mixins.button-bg(
    var(--primary),
    var(--primary-focused),
    var(--text-on-primary-color),
    var(--contrast-low),
    var(--text-on-primary-color)
  );
}

.secondaryNew {
  @include typography.textSmall;

  color: var(--text);
  border: none;

  .contentWrapper {
    gap: 0.25rem;
  }

  &.disabled {
    opacity: 1;
  }

  @include mixins.button-bg(
    var(--background-bg2),
    var(--state-background-hover),
    var(--foreground-primary),
    var(--background-content) !important,
    var(--foreground-primary),
    var(--state-background-press)
  );
}

.ghost {
  border: none;

  @include mixins.button-bg(
    transparent,
    var(--state-hover),
    var(--foreground-primary),
    var(--foreground-disabled) !important,
    var(--foreground-primary),
    var(--state-press)
  );
}
