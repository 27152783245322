@use '../../layout/variables';

.sumsubProfilePage {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: calc(
    100% - var(--page-header-height, 0px) - var(--nav-bar-height, 0px) -
      var(--status-banner-height, 0px)
  );
}
