@use '../../layout/variables';
@use '../../ui/styles/typography';

.appropriatenessTestView {
  display: flex;

  @include variables.mobile() {
    display: block;
  }
}

.wrapper {
  display: flex;
}

.box {
  background-color: var(--background-secondary);
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  flex-wrap: wrap;

  .warning {
    @include typography.textStrong;
  }

  .infoStrong {
    text-align: center;
  }

  .info {
    @include typography.text;
    text-align: center;
  }

  .textSecondary {
    @include typography.text;
    text-align: center;
    color: var(--text-secondary);
  }
}

.boxFill {
  flex: 1;
}

.boxBanner {
  width: 25rem;
  margin-left: 1rem;

  @include variables.mobile() {
    margin-left: 0;
    margin-top: 1rem;
    width: 100%;
  }
}

.boxError {
  border: 1px solid var(--primary);
}
.boxPadding {
  padding-top: 5rem;
  @include variables.mobile() {
    padding-top: 1.5rem;
  }
}

.bannerIconWrapper {
  svg {
    width: 5rem;
    height: 5rem;
  }
}

.header {
  background-color: var(--contrast-low);
  border-radius: variables.$radius-xlarge;

  .title {
    text-transform: capitalize;
  }

  @include variables.mobile() {
    margin-top: 1rem;
  }
}

.boxRight {
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  padding: 2rem;
  border: 1px solid var(--contrast-low);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include variables.mobile() {
    margin-inline-start: 0;
    padding: 0;
  }
}

.title {
  background: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1rem 1.5rem;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.successWrapper {
  display: flex;
}

.closeButton {
  padding-inline: 6rem;
}
