@use '../../ui/styles/typography';
@use '../../layout/variables';

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--background);
  border-radius: variables.$radius-medium;
}

.expandableParent {
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0 !important;

  td {
    &:first-child {
      border-radius: variables.$radius-xlarge 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 variables.$radius-xlarge 0 0 !important;
    }
  }
}

.expandable {
  background-color: var(--background-secondary);

  td {
    border-radius: 0 0 variables.$radius-xlarge variables.$radius-xlarge !important;
  }

  td:hover {
    background-color: var(--background-secondary);
  }
}

.convertedFrom {
  text-align: start !important;
  width: 335px;
}

.exchangeRate {
  text-align: start !important;
  width: 260px;
}

.errorBox {
  @include typography.text;

  .error {
    @include typography.textSmall;
    color: var(--error);
    display: flex;
    align-items: center;
  }
}

.infoIcon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin-inline-start: 0.25rem;
}

.hasNoPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.trimShort {
  @include typography.textTrimShort;
}

.trimMid {
  @include typography.textTrimMid;
}

.trimLong {
  @include typography.textTrimLong;
}

.customRow {
  width: 180px;
}

.nameRow {
  width: 220px;
}

.dateRow {
  width: 130px;
}
.amountRow {
  text-align: end !important;
}

.table th:last-child,
.table td:last-child {
  width: 70px;
  text-align: end !important;
}
