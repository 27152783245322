@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  .warning {
    color: var(--text-secondary);
  }

  .currency {
    margin-bottom: 2rem;
    color: var(--text);
  }

  .title {
    @include typography.textSmall;
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    padding-top: 0.5rem;

    .label {
      color: var(--text-secondary);
      padding-inline-end: 0.5rem;
    }

    .value {
      color: var(--text);
    }

    .view {
      justify-content: center;
    }
  }

  .button {
    width: 100%;
  }

  .history {
    @include typography.textSmall;
    color: var(--text-interactive);
  }
}
