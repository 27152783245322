@use '../ui/styles/typography';
@use '../layout/variables';

.agentInformation {
  .checkIcon svg,
  .checkIcon circle {
    fill: var(--success) !important;
  }
  @include variables.mobile() {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.contacts {
  display: flex;
  align-items: center;

  :not(:last-child) {
    margin-right: 0.5rem;
  }
}
