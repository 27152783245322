@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.calendarFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 1.5rem;
  border-top: 1px solid var(--background-divider);

  .buttons {
    display: flex;
    gap: 0.75rem;
  }
}
