@use '../../ui/styles/typography';

.body {
  @include typography.text;
  background-color: var(--background-secondary);
  color: var(--text);
  padding: 0;

  li {
    cursor: pointer;

    .wrapper {
      display: flex;

      :first-child {
        margin-inline-end: 0.5rem;
      }
    }

    &:first-child {
      border-bottom: 1px solid var(--contrast-low);
    }

    padding: 1.5rem;
  }
}
