@use '../../layout/variables';
@use '../../ui/styles/typography';

.symbolTag {
  width: fit-content;
  background-color: var(--contrast-low);
  border-radius: 0.375rem;
  padding: 0.25rem 0.5rem;
}

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--text);
    font-size: variables.$size-7;
    line-height: variables.$size-3;
    gap: 1rem;

    &:first-child {
      font-weight: variables.$weight-bold;
    }

    .firstItem {
      text-align: start;
      flex: 1 1 70%;
    }

    .secondItem {
      text-align: end;
      flex: 1 1 30%;
    }
  }
}
