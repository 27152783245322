@use '../../../ui/styles/typography';
@use '../../../layout/variables';

$calendar-btn-hover: #f3f6fb;
$calendar-btn-hover-dark: #404a59;
$calendar-btn-press: #ebeff6;
$calendar-btn-press-dark: #5c6676;
$calendar-period: #0b0f19;
$calendar-period-dark: #404a59;
$calendar-today: #0b0f19;
$calendar-today-dark: #ffffff;
$calendar-month-default: #0b0f19;
$calendar-month-default-dark: #404a59;
$calendar-month-hover: #1f2837;
$calendar-month-hover-dark: #5c6676;
$calendar-month-press: #404a59;
$calendar-month-press-dark: #8d97a6;

:export {
  calendarBtnHover: $calendar-btn-hover;
  calendarBtnHoverDark: $calendar-btn-hover-dark;
  calendarBtnPress: $calendar-btn-press;
  calendarBtnPressDark: $calendar-btn-press-dark;
  calendarPeriod: $calendar-period;
  calendarPeriodDark: $calendar-period-dark;
  calendarToday: $calendar-today;
  calendarTodayDark: $calendar-today-dark;
  calendarMonthDefault: $calendar-month-default;
  calendarMonthDefaultDark: $calendar-month-default-dark;
  calendarMonthHover: $calendar-month-hover;
  calendarMonthHoverDark: $calendar-month-hover-dark;
  calendarMonthPress: $calendar-month-press;
  calendarMonthPressDark: $calendar-month-press-dark;
}

:root {
  --calendar-btn-hover: #{$calendar-btn-hover};
  --calendar-btn-press: #{$calendar-btn-press};
  --calendar-period: #{$calendar-period};
  --calendar-today: #{$calendar-today};
  --calendar-month-default: #{$calendar-month-default};
  --calendar-month-hover: #{$calendar-month-hover};
  --calendar-month-press: #{$calendar-month-press};
}

[data-theme='dark'] {
  --calendar-btn-hover: #{$calendar-btn-hover-dark};
  --calendar-btn-press: #{$calendar-btn-press-dark};
  --calendar-period: #{$calendar-period-dark};
  --calendar-today: #{$calendar-today-dark};
  --calendar-month-default: #{$calendar-month-default-dark};
  --calendar-month-hover: #{$calendar-month-hover-dark};
  --calendar-month-press: #{$calendar-month-press-dark};
}

.calendarContainer {
  display: flex;
  justify-content: center;
  border: 0;
  width: 100%;

  :global(.react-datepicker__month-container) {
    padding: 1rem;
    width: 100%;

    :global(.react-datepicker__header) {
      background-color: transparent;
      padding: 0;
      border: 0;
      width: 100%;
    }

    :global(.react-datepicker__day-names) {
      margin: 0.5rem 0 0;
      display: flex;

      .weekDay {
        @include typography.text;

        margin: 0;
        padding: 0rem;
        width: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--text);
        flex: 1;
      }
    }

    :global(.react-datepicker__month) {
      margin: 0;
      width: 100%;

      :global(.react-datepicker__week) {
        margin-top: 0.5rem;
        display: flex;

        .day {
          @include typography.text;

          margin: 0;
          padding: 0;
          height: 3rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          cursor: pointer;
          border-radius: 1.5rem;
          border-color: transparent;
          border-width: 2px;
          border-style: solid;
          color: var(--text);
          outline: none;
          vertical-align: middle;
          flex: 1;

          &:global(.react-datepicker__day--keyboard-selected) {
            background-color: var(--calendar-period);
            color: var(--always-white);
          }

          &:global(.react-datepicker__day--outside-month) {
            color: var(--text-disabled);
            background-color: transparent;
          }

          &:global(.react-datepicker__day--today) {
            border-color: var(--calendar-today);
            font-weight: 400;
          }

          &:global(.react-datepicker__day--disabled) {
            color: var(--text-disabled);
            pointer-events: none;
          }
        }

        .dayRange {
          &:global(.react-datepicker__day--keyboard-selected) {
            background-color: transparent;
            border-color: transparent;
            color: var(--text);
          }

          &:global(.react-datepicker__day--in-selecting-range),
          &:global(.react-datepicker__day--in-range) {
            background-color: var(--calendar-period);
            color: var(--always-white);
            border-radius: 0;
          }

          &:global(.react-datepicker__day--selecting-range-end),
          &:global(.react-datepicker__day--range-end) {
            background-color: var(--calendar-period);
            color: var(--always-white);
            border-top-right-radius: 1.5rem;
            border-bottom-right-radius: 1.5rem;
          }

          &:global(.react-datepicker__day--selecting-range-start),
          &:global(.react-datepicker__day--range-start) {
            background-color: var(--calendar-period);
            color: var(--always-white);
            border-top-left-radius: 1.5rem;
            border-bottom-left-radius: 1.5rem;
          }

          &:global(.react-datepicker__day--range-end.react-datepicker__day--range-start) {
            background-color: var(--calendar-period);
            color: var(--always-white);
          }

          &:global(.react-datepicker__day--outside-month) {
            background-color: transparent;
            border-color: transparent;
          }

          &:global(.react-datepicker__day--today):not(:empty) {
            border-width: 2px;
            border-style: solid;
            border-color: var(--calendar-today);
            font-weight: 400;
          }
        }
      }
    }

    :global(.react-datepicker__month-wrapper) {
      margin-top: 0.5rem;
      display: flex;

      .month {
        @include typography.text;

        margin: 0;
        padding: 0;
        width: 7rem;
        height: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 2rem;
        color: var(--text);
        flex: 1;

        &:hover {
          background-color: transparent;
          border-radius: 2rem;
        }

        &:global(.react-datepicker__month-text--today) {
          background-color: transparent;
          color: var(--text);
        }

        &:global(.react-datepicker__month-text--keyboard-selected) {
          background-color: var(--calendar-month-default);
          color: var(--always-white);
        }
      }
    }
  }

  :global(.react-datepicker__year--container) {
    padding: 1rem;
    width: 100%;

    :global(.react-datepicker__year) {
      margin: 0.5rem 0 0;
      width: 100%;

      :global(.react-datepicker__year-wrapper) {
        .year {
          @include typography.text;

          margin: 0;
          padding: 0;
          width: 30%;
          height: 3rem;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          border-radius: 2rem;
          color: var(--text);

          &:hover {
            background-color: transparent;
            border-radius: 2rem;
          }

          &:global(.react-datepicker__year-text--today) {
            background-color: transparent;
            color: var(--text);
          }

          &:global(.react-datepicker__year-text--selected) {
            background-color: var(--calendar-month-default);
            color: var(--always-white);
          }
        }
      }
    }
  }

  :global(.react-datepicker__aria-live) {
    display: none !important;
  }
}
