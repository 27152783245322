@use '../../../../../ui/styles/typography';
@use '../../../../../layout/variables';

.updatePhone {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .modal {
    margin-top: 2rem !important;
  }

  .phoneWrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .countryCode {
      width: 155px;
      height: 56px;
    }

    .phoneNumberWrapper {
      width: 100%;

      .phoneNumber {
        flex: 1;
        height: 56px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-around;

    button {
      width: 240px;

      @include variables.mobile() {
        width: 100%;
      }
    }
  }
}
