@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.header {
  display: flex;
  align-items: center;
  background-color: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem 1rem;
  margin-bottom: 2rem;
}

.grayText {
  color: var(--text-secondary);
}

.language {
  padding: 0;
  margin-bottom: 1.5rem;
}

.codeWrapper {
  margin-top: 1rem;
  display: flex;
  word-wrap: break-word;

  .inheritTextColor {
    color: inherit;
  }

  div {
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary);
    border-radius: variables.$radius-xlarge;
    padding: 1.5rem;
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  }

  div:first-child {
    margin-inline-end: 1rem;
  }

  .code {
    margin: 1rem 0;
    border: 1px solid var(--contrast-medium-low);
    border-radius: variables.$radius-medium;
    padding: 0.5rem 0.5rem 0.25rem 0.75rem;
    width: 100%;
  }
}

@include variables.mobile() {
  .codeWrapper {
    flex-direction: column;

    div {
      width: 100%;
    }
  }
}

.info {
  color: var(--text-secondary);
  margin-top: 1rem;
}

.selectorWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .selector {
    margin-bottom: 0.75rem;
    min-width: 220px;
    width: calc(100% / 3);

    @include variables.mobile() {
      width: 100%;
    }
  }
}

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
}

.hasVerticalMargin {
  @include variables.mobile() {
    margin: 2rem 0 1.5rem 0;
  }
}
