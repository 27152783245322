@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  @include variables.mobile() {
    margin-top: 1rem;
  }
}

.cards {
  margin: 1.5rem 0;

  .card {
    display: flex;
    align-items: center;
    background-color: var(--background-content);
    color: inherit;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      background-color: var(--state-press);
    }

    &:active {
      background-color: var(--state-background-press);
    }

    border-radius: variables.$radius-xlarge;

    &:not(:last-child) {
      margin: 1rem 0;
    }

    .cardTitle {
      padding: 1rem 1.5rem;
    }

    .cardIconWrapper {
      display: flex;
      justify-content: flex-end;
      padding: 0.75rem 1.5rem;
      flex: 1 1 0;
    }
  }
}
