@use '../../../../../ui/styles/typography';
@use '../../../../../layout/variables';

.read.mb {
  margin-bottom: 2.375rem;

  @include variables.mobile() {
    margin-bottom: 3.125rem;
  }
}
