.button {
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;

  &:hover {
    background: var(--press);
  }

  &:focus,
  &:active {
    background: transparent;
  }
}

.bordered {
  border: 1px solid var(--contrast-medium-low);

  &:hover,
  &:focus,
  &:active {
    border: 1px solid var(--contrast-medium-low);
  }
}

.borderless {
  border: none;

  &:hover,
  &:focus,
  &:active {
    border: none;
  }
}
