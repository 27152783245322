@use '../../layout/variables';

.wrapper {
  position: relative;
  width: 100%;
  margin-top: 2.75rem;
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 1.5rem;
  align-items: center;

  &.wrapped {
    border-radius: 16px;
    background: var(--background-secondary);
    padding: 3rem;
  }

  @include variables.mobile() {
    gap: 1rem;
    margin-top: 0;
    padding: 0.5rem;

    &.wrapped {
      padding: 1rem;
    }
  }

  .title {
    justify-content: space-between;
    text-align: center;
    align-items: center;
    width: 100%;
    gap: 1.5rem;

    &.grid {
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr;
    }

    &.flex {
      display: flex;
      align-items: baseline;
    }

    @include variables.mobile() {
      gap: 0.7rem;
    }

    .closeButtonWrapper {
      display: flex;
    }

    .iconWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .subtitle {
    text-align: center;
    max-width: 43.25rem;
  }

  .content {
    margin: auto;
    width: 100%;
    max-width: 38rem;
    padding: 1.5rem;
    box-sizing: border-box;
    background: var(--background-secondary);
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;

    @include variables.mobile() {
      padding: 1rem;
    }

    &.noWrapper {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
      max-width: 43.25rem;
    }

    &.fullWidth {
      max-width: 100%;
    }
  }

  form {
    width: 100%;
    margin: auto;
  }
}
