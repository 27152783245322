@use '../../../../../ui/styles/typography';
@use '../../../../../layout/variables';

.form {
  height: 100%;

  .personalId {
    direction: ltr;
    unicode-bidi: plaintext;
    text-align: end;

    &::placeholder {
      text-align: end;
    }
  }

  h3 {
    padding-top: 1.5rem;
  }

  .row {
    display: flex;

    &.itCountryCodeLabel {
      div:first-child {
        min-width: 164px;
      }
    }

    div:first-child {
      flex: 0 1 5%;
      min-width: 120px;
      margin-inline-end: 0.25rem;
    }

    div:last-child {
      flex: 3;
      min-width: unset;
      margin-inline-end: unset;
    }
  }

  input[type='checkbox'] {
    &.hasIncreasedSize {
      margin-inline-end: 1rem !important;
      transform: scale(1.5);
    }
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 0 auto;
  padding: 0 120px;

  @include variables.mobile() {
    padding: 0;
    width: 100%;
  }
}

.additionalText {
  color: var(--text-secondary);
}

.modal {
  margin-top: 2rem;
}

.pepOrderedList {
  margin-inline-start: 1rem;
  margin-top: 0.5rem;

  .pepOrderedListItem {
    @include typography.text;
    margin-bottom: 0.25rem;

    &:last-child {
      margin-bottom: 0.5rem;
    }
  }
}

.pepUnOrderedList {
  margin-top: 0.25rem;

  .pepUnOrderedListItem {
    @include typography.text;
    list-style: initial;
    margin-inline-start: 2.5rem;
  }
}
