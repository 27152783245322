@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.landingPages {
  display: flex;
  flex-wrap: wrap;

  @include variables.mobile() {
    gap: 0.5rem;
  }

  .landingPageWrapper {
    margin-top: 1rem;
    width: 25%;
    padding-inline-end: 1rem;
    cursor: pointer;

    @include variables.mobile() {
      margin-top: 0;
      padding: 0.75rem;
    }

    .page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--background-secondary);
      border-radius: variables.$radius-xlarge;
      padding: 1.5rem;
      height: 15rem;
      box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);

      @include variables.mobile() {
        justify-content: start;
        padding: 1rem;
      }

      p {
        margin-top: 1rem;
      }
    }
  }

  .pageWrapper:nth-child(4n) {
    padding: 0;
  }

  @include variables.mobile() {
    .landingPages {
      flex-direction: column;
    }
    .landingPageWrapper {
      width: calc(50% - 0.25rem);
      padding: 0;
    }
  }

  .selected {
    border: 2px solid black;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: variables.$radius-medium;

    @include variables.mobile() {
      max-height: 10rem;
    }
  }
}

.filterWrapper {
  margin-top: 3rem;

  .filter {
    display: flex;
    margin-top: 0.5rem;

    :global(.column) {
      padding-inline-start: 0 !important;
    }
  }

  @include variables.mobile() {
    .filter {
      flex-direction: column;
    }
  }
}

.promoMaterials {
  display: flex;
  flex-wrap: wrap;

  .promoMaterialWrapper {
    display: flex;
    padding-inline-end: 1rem;

    .promoMaterial {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      background-color: var(--background-secondary);
      box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
      border-radius: variables.$radius-xlarge;
      padding: 1.5rem;
      justify-content: center;
      min-height: 12.5rem;

      :global(.button) {
        margin-top: 2rem;
        width: 100%;
      }
    }

    .promoMaterialWrapper:last-child {
      padding: 0;
    }

    .grayLogo {
      background-color: var(--contrast-medium);
    }
  }

  @include variables.mobile() {
    .promoMaterialWrapper {
      width: calc(50% - 0.25rem);
      padding: 0;
    }
    .promoMaterial {
      width: 100%;
      padding: 0.75rem;
    }
  }
}

.selectorWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include variables.mobile() {
    flex-direction: column;
  }
}

.selector {
  margin-bottom: 0.75rem;
  width: calc(100% / 3);

  @include variables.mobile() {
    width: 100%;
  }
}

.hasVerticalMargin {
  @include variables.mobile() {
    margin: 2rem 0 1.5rem 0;
  }
}
