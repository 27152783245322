@use '../../ui/styles/typography';
@use '../../layout/variables';

.pagingSpace {
  position: relative;
  margin-bottom: 3rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text);

  @include variables.mobile() {
    margin-top: 1.5rem;
  }

  &:first-child {
    display: none;
  }

  .innerWrapperCount {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 0.5rem;

    .show {
      @include variables.mobile() {
        display: none;
      }
    }
  }

  .innerWrapper {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;

    @include variables.mobile() {
      gap: 0;
    }

    .of {
      padding: 0.5rem;
      display: flex;
      gap: 0.25rem;

      @include variables.mobile() {
        margin-inline-end: 0.125rem;
      }
    }

    .clickersWrapper {
      display: flex;
      align-items: center;
      gap: 0.125rem;
    }

    .navigationIcon {
      padding: 0.5rem;
      box-sizing: content-box;
      border-radius: variables.$radius-full;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: var(--contrast-low);
      }

      &:active {
        background-color: var(--press);
      }
    }

    .show,
    .entries {
      @include variables.mobile() {
        display: none;
      }
    }

    .pages {
      display: flex;
      align-items: center;
      gap: 0.125rem;

      .page {
        padding: 0.5rem;
        border-radius: variables.$radius-full;
        box-sizing: content-box;
        width: 24px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: var(--contrast-low);
        }

        &.active,
        &:active {
          background-color: var(--press);
        }
      }
    }

    .input {
      width: 100px;
      text-align: start;
      padding: 0.5rem 0.75rem;
      background-color: transparent;
      border: 1px solid var(--contrast-medium-low);
      border-radius: variables.$radius-medium;
      color: var(--text);
      outline: none;
      transition: border-color 0.2s ease-in-out;

      @include typography.textStrong;

      &::placeholder {
        color: var(--text-secondary);
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: var(--text-secondary);
      }

      &:hover,
      &:focus,
      &:active {
        border: 1px solid var(--contrast-high);
        box-shadow: none;
      }

      @include variables.mobile() {
        margin-inline-start: 0.125rem;
        margin-inline-end: 1rem;
        width: 80px;
      }
    }
  }

  span {
    @include typography.textSmall;
    color: var(--text);
  }

  .select {
    .selectInput {
      border-radius: variables.$radius-xxxlarge;
      background-color: var(--contrast-low);
      color: var(--text-primary);
      border: 0;
      padding-inline: 1rem 0.5rem;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: var(--press);
      }
    }
  }

  .button {
    background: transparent;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    &:disabled {
      svg > path {
        fill: var(--disabled);
      }
      cursor: not-allowed;
    }
  }

  .page {
    border: 0;
    white-space: nowrap;
    font-family: inherit;
    cursor: pointer;
    background-color: transparent;
    color: var(--text);
    padding: 0 1rem;

    &:disabled {
      color: var(--text-interactive);
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@include variables.mobile() {
  .pagingSpace > div {
    flex-wrap: wrap-reverse;
    justify-content: center;

    :global(.level-left) {
      width: 100%;
      justify-content: center;
      margin-top: 0.5rem;
    }
  }
}
