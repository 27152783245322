@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .container {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 5rem;
    flex: 1;

    @include variables.mobile() {
      flex-direction: column;
      gap: 1.875rem;
    }

    &.activatedAccount {
      margin-top: 3rem;

      @include variables.mobile() {
        margin-top: 0;
      }
    }

    &.isAccountDocumentAllowedUpload {
      @include variables.mobile() {
        margin-top: 0;
        background-image: url('../../icons/BackgroundMobileIcon.svg');
        background-position: top;
        background-repeat: no-repeat;
      }
    }
  }

  :global(.modal) {
    @include variables.mobile() {
      justify-content: center; // TODO there is a task to make modal appear at the bottom, then it's just needs to be change here
    }
  }
}

.additional {
  box-sizing: border-box;
  max-width: 27.5rem;
  min-width: 6.25rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 55%;

  @include variables.mobile() {
    margin-top: 1.375rem;
    flex-basis: 100%;
    max-width: 100%;
  }
}

.documents {
  flex-basis: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.additional + .documents {
  box-sizing: border-box;
  max-width: 21.25rem;
  min-width: 6.25rem;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 45%;

  @include variables.mobile() {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.additionalCard {
  background-color: var(--background);
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  border: 1px solid var(--primary);
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 1rem 1.5rem;

  @include variables.mobile() {
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    gap: 1.5rem;
  }

  .textBox {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .description {
      color: var(--text);
    }

    .descriptionBox {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .instructions {
      color: var(--text-secondary);
    }

    ul {
      list-style: disc;
      list-style-position: inside;
    }
  }
}

.error {
  color: var(--error);
}

.table {
  thead th {
    @include typography.textTinyStrong;
    padding: 0.5rem 1rem !important;
  }

  tbody tr {
    height: 4rem !important;
  }

  tbody td {
    padding: 1rem !important;
  }
}

.documentName {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5rem;

  @include variables.mobile() {
    gap: 0.5rem;
    padding: 0;
  }
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  &:not(.inactive) {
    &:hover {
      background-color: var(--contrast-low);
    }
  }
}

.notifyCard {
  flex-basis: 45%;
  flex-direction: column;
  padding: 1.25rem;
  border-radius: variables.$radius-xlarge;
  margin-top: 149px;
  margin-bottom: 289px;
  background-color: var(--background-secondary);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  @include variables.mobile() {
    flex-basis: auto;
    margin-top: 264px;
    margin-bottom: 272px;
  }

  .notifyCardTitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.625rem;

    .text {
      color: var(--text-secondary);

      @include variables.mobile() {
        text-align: justify;
      }
    }
  }
}

.backgroundIcon {
  pointer-events: none;
  position: fixed;

  &.activatedBackgroundIconLeft {
    top: 190px;
    left: -24px;

    @media screen and (min-width: 1376px) {
      top: 240px;
      left: 6px;
    }

    @media screen and (min-width: 1440px) {
      top: 192px;
      left: 56px;
    }
  }

  &.backgroundIconLeft {
    top: 75px;
    left: -24px;
  }

  &.activatedBackgroundIconRight {
    top: 328px;
    right: -354px;

    @media screen and (min-width: 1376px) {
      top: 376px;
      right: -354px;
    }

    @media screen and (min-width: 1440px) {
      top: 312px;
      right: -284px;
    }
  }

  &.backgroundIconRight {
    top: 195px;
    right: -354px;
  }
}
