@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.currencyFieldWrapper {
  width: 8.75rem;
}

.textSmall {
  @include typography.textSmall;
}

.additionalInfo {
  @include typography.textSmall;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: variables.$radius-xlarge;
  background-color: variables.$contrast;
}

.warningInfo {
  font-size: variables.$size-7;
}
