@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.5rem;
}

@mixin header($bg) {
  display: flex;
  align-items: center;
  background-color: $bg;
  padding: 1.5rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;
}

.headerGray {
  @include header(var(--contrast-low));

  .title {
    display: flex;
    align-items: center;
    :first-child {
      margin-inline-end: 0.5rem;
    }
  }
}

.headerWhite {
  @include header(var(--background-secondary));
}

.textSuccess {
  color: var(--success);
}

.textGray {
  color: var(--text-secondary);
}

.paddedText {
  @include typography.text;
  padding-inline-start: 1rem;
}

.textSmall {
  @include typography.textSmall;
}

.questionName {
  @include typography.textSmall;
  padding-inline-start: 0.5rem;
}

.questionError {
  color: var(--error);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.accordion {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  padding: 1rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;

  .title {
    cursor: pointer;
    display: flex;

    &.justify {
      justify-content: space-between;
    }
  }

  .chevron {
    padding-inline-start: 0.75rem;
    padding-top: 0.5rem;
  }

  .chevronUp {
    margin-top: 0.5rem;
    transition: all 0.5s ease;
    transform: rotateX(180deg);
  }

  .chevronDown {
    transition: all 0.5s ease;
  }

  h3 {
    margin: 0.75rem 0;
  }

  p {
    margin: 0.75rem 0;
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 1s ease-in-out;

    .text {
      padding-inline-start: 1rem;
    }
  }
  .open {
    max-height: 150rem;
  }
}
.overviewSection {
  display: flex;
  .cardWrapper {
    width: 33%;
    margin-inline-end: 1rem;
  }
  :last-child {
    margin: 0;
  }
  .hasIncreasedHeight {
    min-height: 8rem;
  }
}
@include variables.mobile() {
  .overviewSection {
    flex-direction: column;
    .cardWrapper {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: var(--background-secondary);
  padding: 1rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;

  h3 {
    margin: 0.75rem 0;
  }

  p {
    margin: 0.75rem 0;
  }

  .link {
    color: var(--text-interactive);
  }

  .overview {
    background-color: var(--background);
    border: 1px solid var(--contrast-low);
    border-radius: variables.$radius-medium;
    padding: 1rem;
    min-height: 16.25rem;

    .icon {
      padding: 0.4rem;
    }

    .overviewWrap {
      display: flex;
    }
  }

  :global(.column) {
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
  }
}

.confirm {
  margin-top: 1rem;
  .checkbox {
    padding-inline-end: 1rem;
  }
}

.buttonWrapper {
  margin-top: 1rem;

  :global(.button) {
    padding: 0 8rem;
  }
}
