@use '../../ui/styles/typography';
@use '../../layout/variables';

.carouselWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  .carouseInnerlWrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }

  &.spaceLeft {
    padding-inline-start: 1.5rem;
  }

  &.spaceRight {
    padding-inline-end: 1.5rem;
  }

  .arrow {
    cursor: pointer;

    &:first-child {
      margin-inline-end: 1rem;
    }

    &:last-child {
      margin-inline-start: 1rem;
    }
  }

  .carousel {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    .carouselContent {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: hidden;
      position: relative;
    }
  }
}

[dir='rtl'] {
  .carouselWrapper {
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.slide {
  height: 500px;
  max-height: 70vh;
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.carouselIndicators {
  position: relative;
  display: flex;
  justify-content: center;
  padding-inline-start: 0;
  list-style: none;
  margin: 0 auto;
}

.carouselNumberIndicators {
  position: relative;
  display: flex;
  padding-inline-start: 0;
  list-style: none;
  gap: 1rem;
}

.carouselNumberIndicator {
  color: var(--text-secondary);
  border: 1px solid var(--contrast-medium-low);
  text-align: center;
  width: 1.625rem;
  height: 1.625rem;
  cursor: pointer;

  @include typography.textSmall;

  font-weight: variables.$font-weight-semi-bold;
  border-radius: variables.$radius-full;

  &.active {
    border: 1px solid var(--contrast-high);
    color: var(--text);
  }

  &.active {
    cursor: default;
  }
}

.carouselIndicator {
  position: relative;
  flex: 0 1 auto;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 0.25rem;
  background-color: var(--contrast-medium-low);
  border-radius: variables.$radius-full;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--primary);
  }

  &.active {
    background-color: var(--primary);
    cursor: default;
  }
}
