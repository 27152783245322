@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  padding: 1.25rem;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  .textSmall {
    @include typography.textSmall;
  }

  .textSecondary {
    color: var(--text-secondary);
  }

  .secondaryCard {
    padding: 10px 16px 10px 8px;
    background-color: var(--background);
    margin: 1rem 0;
    border-radius: variables.$radius-large;

    > div {
      height: 40px;
    }
  }
}

.additionalMessage {
  color: var(--text-secondary);
  margin-top: 1rem;
}

.button {
  padding: 8px 24px;
}

.buttonText {
  margin-left: 6px;
}

.headerText {
  margin-left: 10px;
}
