@use '../../layout/variables';
@use '../../ui/styles/typography';

$label-position: 1.3rem;

.productSwitch {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @include variables.mobile() {
    gap: 0.25rem;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: block;
      font-size: 0.6875rem;
      font-weight: bold;
    }

    .checkbox {
      position: relative;
      border-radius: variables.$radius-full;
      width: 153px;
      height: 32px;
      background: var(--background);

      .labelLeft {
        top: 4px;
        inset-inline-start: $label-position;
        position: absolute;
        text-align: start;
        @include typography.textSmall;
        font-weight: variables.$font-weight-semi-bold;
        color: var(--text);
        z-index: 41;
        transition: color 0.3s ease-in-out;

        &.isEnabled {
          color: var(--text-on-primary-color);
        }

        &.alwaysWhite {
          color: var(--always-white);
        }
      }

      .labelRight {
        top: 4px;
        position: absolute;
        text-align: end;
        inset-inline-end: $label-position;
        color: var(--text);
        @include typography.textSmall;
        font-weight: variables.$font-weight-semi-bold;
        z-index: 41;
        transition: color 0.3s ease-in-out;

        &.isEnabled {
          color: var(--text-on-primary-color);
        }

        &.alwaysWhite {
          color: var(--always-white);
        }
      }

      label {
        cursor: pointer;
      }

      input,
      label,
      label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: opacity 0.3s ease-in-out;
      }

      input {
        pointer-events: all;
        opacity: 0;
      }

      .selector {
        position: absolute;
        background: var(--primary);
        padding: 0 1rem;
        width: 60px;
        margin: 4px;
        height: 1.5rem;
        gap: 0.5rem;
        border-radius: variables.$radius-full;
        pointer-events: none;
        inset-inline-start: 0;
        transition: left 0.3s ease-in-out, width 0.3s ease-in-out, right 0.3s ease-in-out;

        &.ETD {
          width: 85px;
          inset-inline-start: 60px;
        }
      }
    }
  }
}
