@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.quickPerformance {
  width: 100%;

  .filters {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 1rem;
    column-gap: 0.75rem;
    row-gap: 0.25rem;
  }

  .chart {
    height: 13.25rem;
    margin-top: 1rem;

    @include variables.mobile {
      padding-right: 1rem;
    }
  }

  .mobileDatesContainer {
    padding-top: 0.62rem;
  }
}
