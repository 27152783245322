@use '../../ui/styles/typography';

.body {
  @include typography.text;
  color: var(--text);
  padding: 0;

  li {
    cursor: pointer;

    .errorIcon {
      text-align: end;
      flex-grow: 1;
    }

    .restricted {
      color: var(--primary);
      margin-inline-start: 0.5rem;
    }

    .wrapper {
      display: flex;

      div:first-child {
        margin-inline-end: 1rem;
      }
    }

    border-bottom: 1px solid var(--contrast-low);

    &:last-child {
      border: none;
    }

    padding: 1.5rem;
  }
}
