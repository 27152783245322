@use '../../ui/styles/typography';
@use '../../layout/variables';

.radio {
  margin-inline-end: 1rem;
}

.switch {
  text-align: center;
}

.text {
  @include typography.text;
  a {
    color: var(--text-interactive) !important;
    text-decoration: none !important;
  }
}

.vipContent {
  padding: 2rem;
}

.radioGroup {
  align-items: center;
  padding-bottom: 1rem;

  div {
    margin-bottom: 0.5rem;
  }

  label {
    color: var(--text);
  }

  :global(.field-label) {
    padding-inline-start: 0.5rem;
  }

  :global(svg) {
    margin-inline-start: 0.5rem;
  }
}

.w8FormNote {
  padding-top: 1.5rem;
  margin-bottom: 2.5rem;
  color: var(--text-secondary);

  @include variables.mobile() {
    padding: 0;
  }
}

.error {
  margin: 0.25rem;
  color: var(--error);
}

.platformInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--text-secondary, #868686);
  font-size: 12px;
  font-weight: variables.$font-weight-regular;
  line-height: 16px;
  letter-spacing: 0.15px;
  @include variables.mobile() {
    align-items: start;
  }

  .platformInfoStrong {
    font-weight: variables.$font-weight-bold;
  }
}

.referralTitleWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  .infoButton {
    padding-inline-start: 0.75rem;
    padding-top: 0.15rem;
  }

  .toggleButton {
    padding-inline-end: 0.75rem;
  }
}

.textColor {
  color: var(--text);
}
