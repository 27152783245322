@use '../ui/styles/typography';
@use '../layout/variables';
@import '~bulma/sass/utilities/_all';
@import '~bulma/sass/utilities/mixins';
@import '~bulma/sass/elements/table';

.notification {
  background-color: var(--background-secondary);
  border: 1px solid var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  width: 100%;
  display: flex;
  padding: 1.5rem;
  margin: 0.5rem 0;
  max-height: 300px;
  transition: all 0.5s ease;
  overflow: hidden;

  @include variables.mobile() {
    max-height: initial;
  }

  &.external {
    margin-top: 2.5rem;
    min-height: fit-content;

    @include variables.mobile() {
      margin-top: 1rem;
    }
  }

  &.expanded {
    max-height: initial;
  }

  &.warning {
    border: 1px solid var(--warning);
  }

  &.error {
    border: 1px solid var(--error);
  }

  @include variables.mobile() {
    padding: 1rem 0.5rem;
  }

  .promoImage {
    width: 280px;
    height: 160px;
    object-fit: cover;
    object-position: center;
    border-radius: variables.$radius-large;

    @include variables.mobile() {
      display: none;
    }

    &.expanded {
      max-height: initial;
    }
  }

  .textBox {
    color: var(--text);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 1rem;
    width: 100%;

    @include variables.mobile() {
      margin: 0 0.5rem;
    }

    .titleBox {
      @include typography.textStrong;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      &.promo {
        @include typography.h3;
        padding-bottom: 0.5rem;

        @include variables.mobile() {
          padding-bottom: 1.5rem;
        }
      }

      .active {
        cursor: pointer;
      }

      .infoWrap {
        display: flex;
        flex-direction: row;
        align-items: center;

        .datePin {
          margin-inline-start: 0.75rem;
        }
        .closeIcon {
          cursor: pointer;
        }
      }
    }

    .mobileImage {
      display: none;

      @include variables.mobile() {
        display: block;
        width: 100%;
        max-height: 160px;
        object-fit: cover;
        object-position: center;
        border-radius: variables.$radius-large;
        margin-bottom: 1.5rem;
      }

      &.expanded {
        max-height: initial;
      }
    }

    .text {
      .readMore {
        cursor: pointer;
        color: var(--text-interactive);
      }

      ul {
        list-style-type: disc;
      }

      a {
        color: var(--text-interactive);
      }
    }

    .buttonBox {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      @include variables.mobile() {
        width: 100%;
      }

      .externalLink {
        @include variables.mobile() {
          width: 100%;
        }
      }

      .close {
        @include variables.mobile() {
          width: 100%;
          margin-top: 1.5rem;
        }
      }
    }
  }

  .date {
    color: var(--text-secondary);
  }

  table {
    @extend .table;
  }
}
