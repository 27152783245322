@use '../../ui/styles/typography';
@use '../../layout/variables';

.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
}

.expandableParent {
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0 !important;

  td {
    &:first-child {
      border-radius: variables.$radius-xlarge 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 variables.$radius-xlarge 0 0 !important;
    }
  }
}

.expandable {
  background-color: var(--background-secondary);

  td {
    border-radius: 0 0 variables.$radius-xlarge variables.$radius-xlarge !important;
  }

  td:hover {
    background-color: var(--background-secondary);
  }
}

.container {
  display: flex;
  flex-direction: row;
  background-color: var(--background);
  border-radius: variables.$radius-medium;
}

.leftContainer {
  width: 50%;
}

.text {
  @include typography.text;
}

.textSmall {
  @include typography.textSmall;
}

.secondaryTextSmall {
  color: var(--text-secondary);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 40px;
}
