@use '../../layout/variables';

.alertIconWrapper {
  position: relative;
  display: flex;
  width: fit-content;

  &.active {
    &:before {
      position: absolute;
      content: '';
      background-color: var(--error);
      border-radius: variables.$radius-full;
      width: 0.375rem;
      height: 0.375rem;
      pointer-events: none;
      top: 0;
      right: 0;

      @include variables.mobile() {
        width: 0.25rem;
        height: 0.25rem;
      }
    }
  }

  &.spaced {
    &:before {
      right: -5px;
    }
  }

  &.fullWidth {
    width: 100%;
  }
}
