@use '../../ui/styles/typography';
@use '../../layout/variables';

.body {
  position: relative;
  background-color: var(--contrast-min);
  padding: 1.5rem 0.75rem;
}

.footer {
  padding: 0.75rem;
}

.modal {
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0;
}
