@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .title,
  .subtitle {
    .label {
      color: var(--text-secondary);
    }

    .value {
      color: var(--text);
    }
  }

  .subtitle {
    @include typography.textSmall;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    padding-top: 0.5rem;
  }

  .address {
    text-align: end;
  }

  .remove {
    @include typography.textSmall;
    display: flex;
    justify-content: flex-end;
    color: var(--text-danger);
    width: 100%;
  }

  .more {
    @include typography.textSmall;
    display: flex;
    justify-content: flex-end;
    color: var(--text-interactive);
    width: 100%;
  }

  .textSmall {
    @include typography.textSmall;
  }
}
