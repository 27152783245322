@use '../../../layout/variables';

.wrapper {
  width: 100%;
  background-color: var(--background);
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 95%;
  align-items: center;
  padding-bottom: 0.5rem;

  @include variables.mobile() {
    margin-top: 0;
    height: 100%;
  }

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    text-transform: capitalize;
    width: 100%;
  }

  .emptyBlock {
    width: 52px;
  }

  .content {
    width: 100%;
    max-width: 56.25rem;
    padding: 1.5rem;
    background: var(--background-secondary);
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    flex-grow: 1;

    @include variables.mobile() {
      padding: 0.75rem 0;
    }
  }
}
