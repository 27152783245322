@use '../../ui/styles/typography';

@mixin truncate-text($max-width: 130px) {
  max-width: $max-width;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text {
  @include typography.text;
}

.twoLineCell {
  display: flex;
  flex-direction: column;

  .truncated {
    @include truncate-text;
  }

  .secondary {
    @include truncate-text;
    color: var(--text-secondary);
  }
}
