@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  display: flex;
  padding: 6.25rem;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;

  .backButton {
    margin-top: 0.5rem;
  }

  @include variables.mobile() {
    flex-direction: column;
    padding: 2rem;
    margin-top: 0;

    div:first-child {
      margin-bottom: 2rem;
    }
  }

  .title {
    @include typography.textHero;
    color: var(--text-secondary);
  }
}
