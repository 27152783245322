@use '../layout/variables';
@use '../ui/styles/typography';

.wrapper {
  margin-top: 2.25rem;

  .title {
    padding-bottom: 0.5rem;
    border-bottom: 3px solid var(--primary);
    color: var(--text);

    @include variables.mobile() {
      display: none;
    }
  }

  .box {
    background-color: var(--background-secondary);
    padding: 3rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;

    .content {
      margin: 0 auto;
      max-width: 37rem;
    }
  }
  .reverseColumns {
    @include variables.mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
