@use '../../layout/variables';
@use '../../ui/styles/typography';

.titleSection {
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 3.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  margin-top: 1rem;

  h3 {
    margin-bottom: 1rem;
  }
}

.accordion {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  padding: 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;
  box-shadow: 0 0.5rem 3.5rem rgba(0, 0, 0, 0.04);
  @include variables.mobile() {
    padding: 1rem;
  }

  .titleBox {
    display: flex;
    cursor: pointer;

    .title {
      @include typography.textLargeStrong;
      transition-delay: 1s;
      @include variables.mobile() {
        transition-delay: 0.8s;
      }
    }

    .titleOpen {
      @include typography.textLargeStrong;
      transition-delay: 0.5s;
      color: var(--disabled);
      @include variables.mobile() {
        transition-delay: 0.1s;
      }
    }
  }

  :global(button) {
    margin-top: 1.5rem;
    width: 15rem;
    @include variables.mobile() {
      width: 100%;
    }
  }

  .chevron {
    padding-inline-start: 0.75rem;
    padding-top: 0.5rem;
    margin-inline-start: auto;
  }

  .chevronUp {
    margin-top: 0.5rem;
    transition: all 0.5s ease;
    transform: rotateX(180deg);
  }

  .chevronDown {
    transition: all 0.5s ease;
  }

  a {
    @include variables.mobile() {
      width: 100%;
    }
  }

  p {
    margin: 0.5rem 0;
  }

  .content {
    display: flex;
    overflow: hidden;
    max-height: 0;
    transition: max-height 1s ease-in-out;

    .leftSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .subtitle {
        @include typography.textStrong;
        text-align: center;
      }

      .text {
        @include typography.text;
        @include variables.mobile() {
          text-align: center;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .open {
    max-height: 62rem;
  }

  .wrapper {
    padding: 3rem;
    background-color: var(--background);
    border-radius: variables.$radius-large;
    text-align: center;

    @include variables.desktop() {
      display: none;
    }
  }

  .contentWrapper {
    display: flex;
    width: 100%;

    @include variables.mobile() {
      flex-direction: column;
    }
  }
}
