@use '../../ui/styles/typography';
@use '../../layout/variables';

.topWrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .wrapper {
    display: flex;
    justify-content: center;
    max-width: 448px;
    padding: 1.5rem 1.5rem 3.5rem 1.5rem;
    background: var(--background-secondary);
    border-radius: variables.$radius-xlarge;

    @include variables.mobile() {
      display: initial;
      padding: 0;
    }

    .inner {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;

      @include variables.mobile() {
        padding: 1rem 0.875rem 3rem 0.875rem;
      }

      .backToLogin {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin-bottom: 1rem;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.5rem;
      }

      h2 {
        padding: 1rem 0 2.5rem 0;
      }
    }
  }
}
