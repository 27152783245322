@use '../../ui/styles/typography';
@use '../../layout/variables';
@import './FormField.module';

.input {
  cursor: pointer;
}
.label {
  color: var(--text-secondary);
  @include typography.textTiny;
}

.hint {
  color: var(--text-secondary);
  margin-top: 0.25rem;
  margin-inline-start: 0.75rem;
  display: block;
}

.errorMessage {
  margin-top: 8px;
  text-indent: 12px;
  color: var(--error);
}

.isSelect {
  &::after {
    border: 3px solid var(--text-secondary);
    border-radius: variables.$radius-small;
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 0.625em;
    margin-top: -0.4375em;
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
  }
  &[dir='rtl']::after {
    right: auto;
    left: 1rem;
  }
}
