@use '../../ui/styles/typography';
@use '../../layout/variables';

.itemWrapper {
  margin-bottom: 1.5rem;

  .wrapper {
    display: flex;
    margin: 0.5rem 0;

    .label {
      margin-inline-end: 2rem;
      min-width: 6rem;
    }

    .borderLess {
      @include typography.text;
      border: none;
      padding: 0;
    }
  }
}

.warning {
  color: variables.$warning;
}

.failed {
  color: variables.$error;
}

.active {
  color: variables.$success;
}

.disabled {
  color: variables.$disabled;
}
