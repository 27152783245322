@use '../../ui/styles/typography';
@use '../../layout/variables';

.content {
  * {
    @include typography.text;
  }
}

.agreeButtons {
  display: flex;
  padding: 0 1.25rem;
  gap: 0.5rem;
  width: 100%;
}
