@use '../../layout/variables';

.statusBanner {
  margin-top: 0.875rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  @include variables.mobile() {
    margin-top: 0;
  }
}
