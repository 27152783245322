@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.typeFilters {
  flex: 1;

  .tabs {
    display: flex;

    .tab {
      margin: 0 !important;
      flex: 1;
      justify-content: flex-start !important;

      .ball {
        width: 0.75rem;
        height: 0.75rem;
        border-radius: 50%;
        margin: 0 0.87rem 0 0.37rem;
        flex-shrink: 0;

        &.yellow {
          background-color: var(--background-chart-yellow);
        }

        &.green {
          background-color: var(--background-chart-green);
        }

        &.blue {
          background-color: var(--background-chart-blue);
        }
      }
    }
  }
}
