@use '../ui/styles/typography';
@use '../layout/variables';

.wrapper {
  display: flex;
  justify-content: center;

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    background: var(--background-secondary);
    box-shadow: 0 0 64px var(--background-secondary);
    border-radius: variables.$radius-xlarge;
    margin: 2rem 0;

    span {
      padding-top: 2.5rem;
    }

    button {
      width: 100%;
      margin-top: 2.5rem;
    }
  }
}
