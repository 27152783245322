@use '../../ui/styles/typography';
@use '../../layout/variables';

.numberCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 0;
  border-radius: variables.$radius-full;
  margin-inline-end: 0.5rem;
  background: var(--background-secondary);
  color: var(--text);
  border: 1px solid var(--contrast-low);
  height: 32px;
  width: 32px;
  flex-shrink: 0;

  &.extended {
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 4px 10px;
  }

  .subStep {
    padding-inline-start: 8px;
    color: var(--disabled);
  }
}

.completedWrapper {
  display: flex;
  align-items: center;

  svg {
    height: 32px;
    width: 32px;
    margin-inline-end: 0.5rem;
  }
}

.failedWrapper {
  display: flex;
  align-items: center;

  svg {
    height: 32px;
    width: 32px;
    margin-inline-end: 0.5rem;
  }
}

.isActive {
  background: var(--text);
  color: var(--background-secondary);
}

.isRed {
  background: var(--primary);
  color: var(--always-white);
}

.isSmall {
  height: 24px;
  width: 24px;
}
