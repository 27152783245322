@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  background: var(--contrast-low);
  border-radius: variables.$radius-large;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
}

.container,
.btn {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.card {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: variables.$radius-large;
  width: 100%;
  margin: 0.5rem 0;
  background-color: var(--background-secondary);

  td {
    @include typography.textSmall;
    color: var(--text-secondary);
    padding: 1rem;
    vertical-align: inherit;
    border: none;
    justify-content: center;
  }

  .reserved {
    color: var(--text-secondary);
    margin-inline-end: 0.25rem;
  }

  .link {
    @include typography.textSmall;
  }
}

.button {
  display: flex;
  width: 90%;
  margin-inline-start: auto;
  margin-inline-end: auto;
}
