@use '../../layout/variables';
@use '../../ui/styles/typography';

.gettingStarted {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .titleSection {
    background: var(--background-secondary);
    box-shadow: 0 0.5rem 3.5rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .accordionsBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .accordion {
      display: flex;
      flex-direction: column;
      background-color: var(--background-secondary);
      padding: 1.5rem;
      border-radius: variables.$radius-xlarge;
      box-shadow: 0px 0.5rem 3.5rem rgba(0, 0, 0, 0.04);

      .titleBox {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;

        .chevron {
          transition: all 0.5s ease-in-out;

          &.chevronUp {
            transform: rotateX(180deg);
          }
        }
      }

      .content {
        overflow: hidden;
        max-height: 0;
        transition: max-height 0.5s;

        &.open {
          max-height: 40rem;
        }

        .innerContent {
          gap: 1rem;
          display: grid;
          grid-template-columns: 1fr 1fr;
          padding-top: 2rem;

          @include variables.mobile() {
            grid-template-columns: 1fr;
          }

          .leftSection {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            gap: 1.5rem;
            order: 1;

            .button {
              min-width: 15rem;
            }
          }

          .rigthSection {
            order: 2;

            @include variables.mobile() {
              order: 0;
            }

            > svg {
              width: 100%;
              height: auto;
            }

            .appLinkBlock {
              display: block;
              border-radius: variables.$radius-xlarge;
              background: var(--background);
              gap: 0.5rem;
            }

            .QRCodeBlock {
              background-color: var(--background);
              border-radius: variables.$radius-xlarge;
              padding: 2.5rem 2rem;

              .warning {
                @include typography.textSmallStrong;
              }
            }
          }
        }
      }
    }
  }
}
