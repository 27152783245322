@use '../../../ui/styles/typography';

.totalBalance {
  .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .title {
      color: var(--text-secondary);
      display: flex;
      align-items: center;
    }

    .currencyText {
      color: var(--text);
    }

    .infoIcon {
      height: 14px;
    }
  }

  .textRight {
    text-align: end;
  }
}
