@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  display: flex;
  align-items: center;
  :global(.button) {
    border: none;
    padding-inline-end: 2.25rem;
    padding-inline-start: 0;
    background-color: transparent !important;
  }
  :global(span) {
    padding-bottom: 8px;
    border-bottom: 3px solid var(--primary);
  }

  .backLabel {
    color: var(--text);
  }
}

.wrapper {
  :global(.button) {
    display: inline-block;
    background-color: transparent !important;
    &:focus {
      box-shadow: none;
    }
  }
}
