@use '../layout/variables';
@use '../ui/styles/typography';

.topWrapper {
  margin-top: 2rem;

  .wrapper {
    display: flex;
    justify-content: center;
    padding: 2.5rem;
    background: var(--background-secondary);
    border-radius: variables.$radius-xlarge;
    gap: 2rem;

    .qrCode {
      padding: 2.5rem 2rem;
      background-color: var(--background);
      border-radius: variables.$radius-xlarge;

      @include variables.mobile() {
        display: none;
      }
    }

    .title {
      color: var(--text);
      margin-top: 3rem;
      text-align: center;
    }

    @include variables.mobile() {
      display: initial;
      padding: 0;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      background: var(--background-secondary);
      border-radius: variables.$radius-xlarge;
      width: 45%;
      max-width: 400px;

      @include variables.mobile {
        width: 100%;
        max-width: initial;
      }

      .signUpBox {
        @include typography.textSmall;
        display: flex;
        margin-top: 3rem;
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;
        color: var(--text-secondary);
      }

      button {
        width: 100%;
        margin-top: 2.5rem;
      }
    }

    .grayText {
      color: var(--text-secondary);
    }
  }
}
