@use '../ui/styles/typography';
@use '../layout/variables';

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;

  .tabs {
    @include variables.mobile() {
      justify-content: center;
    }
  }
}
