@use '../ui/styles/typography';
@use '../layout/variables';

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;

  .tabs {
    :global(.button) {
      margin-top: 0;
      margin-bottom: 0;
      color: var(--text-secondary);
      text-transform: none;
      @include typography.textSmallStrong;

      &.selected {
        border-bottom: none !important;
        color: yellow;
      }
    }
  }

  @include variables.mobile() {
    display: none;
  }
}
