@use '../../layout/variables';
@use '../../ui/styles/typography';

.firstTimeDepositPage {
  max-width: 38rem;
  margin: 0 auto;

  .header {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
    gap: 1.5rem;

    .description {
      color: var(--text-secondary);
    }
  }
}
