@use '../ui/styles/typography';
@use '../layout/variables';

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;

  @include variables.mobile() {
    display: none;
  }
}

.aquitySection {
  display: flex;
  flex-direction: column;
  hr {
    background: var(--background-gray);
    height: 1px;
    margin: 1rem 0;
    opacity: 80%;
  }
  hr:first-of-type {
    @include variables.mobile {
      display: none;
    }
  }
  hr:last-of-type {
    @include variables.desktop {
      display: none;
    }
  }

  .aquityDescription {
    margin-top: 1.5rem;
    margin-bottom: 3rem;
    color: var(--text-secondary);
    width: 50%;
    @include variables.mobile() {
      margin-bottom: 0;
      width: fit-content;
    }
  }
}
