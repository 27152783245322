@use '../styles/typography';
@use '../../layout/variables';

.switcherTabs {
  display: flex;
  align-items: center;
  border-radius: variables.$radius-medium;
  background-color: var(--contrast-low, #ececec);
  padding: 4px;

  &.fullWidth {
    width: 100%;
  }

  .tab {
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    cursor: pointer;
    color: var(--text);

    div,
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      background-color: var(--background-secondary);
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
