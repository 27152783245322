@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.firstTimeDepositPage {
  max-width: 38rem;
  margin: 1.5rem auto 0;
}

.loadingWrapper {
  padding: 80px 0 24px 0;
}
