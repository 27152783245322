.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;

  .setupStatusText {
    text-align: center;
  }

  .nextButton {
    padding: 0 6rem;
  }
}
