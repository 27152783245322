@use 'sass:math';

@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/functions';
@import '../ui/styles/colors';

// global
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

// sizes
$px-in-rem: math.div(1rem, 16);
$size-1: 40 * $px-in-rem;
$size-2: 32 * $px-in-rem;
$size-3: 24 * $px-in-rem;
$size-4: 21.2 * $px-in-rem;
$size-4-1: 22 * $px-in-rem;
$size-4-2: 20 * $px-in-rem;
$size-5: 18 * $px-in-rem;
$size-6: 16 * $px-in-rem;
$size-7: 14 * $px-in-rem;
$size-8: 13 * $px-in-rem;
$size-9: 12 * $px-in-rem;

$radius-small: 4px;
$radius-small-medium: 6px;
$radius-medium: 8px;
$radius-large: 12px;
$radius-xlarge: 16px;
$radius-xxlarge: 24px;
$radius-xxxlarge: 32px;
$radius-full: 100px;

// layout
$block-spacing: 1.125rem;
$block-spacing-mobile: 0.5rem;
$gap: 0;
$navbar-height: 4.75rem;
$modal-offset: 8rem;
$modal-content-width: 698px;
$modal-content-width-desktop: 772px;
$modal-content-width-xsmall: 360px;
$modal-content-width-large: 1232px;
$modal-card-spacing: 3.5rem;
$modal-card-head-padding: $size-3;
$modal-card-body-padding: $size-3;
$modal-card-title-size: $size-6;
$modal-card-head-radius: 1rem;
$modal-card-foot-radius: 1rem;
$modal-card-foot-border-top: none;
$container-max-width: 1232px;

$column-gap: $block-spacing;

$spacing-after-filters: 1.5rem;

// Icons
$icon-size: 12px;

$transition-function: ease-in-out;
$transition-time: 0.3s;

// ZIndex Overlay
$zIndex: (
  toast: 32,
  topRightButton: 33,
  selectFieldDropdown: 34,
  loader: 9996,
  header: 9997,
  chatWidget: 9998,
  modal: 9999,
  menu: 10000,
  popUp: 10000,
);

@import '~bulma/sass/utilities/derived-variables';
@import '~bulma/sass/utilities/mixins';
