@use '../../ui/styles/typography';
@use '../../layout/variables';

.container {
  display: flex;
  justify-content: center;
}

.downloadSection {
  display: flex;
  align-items: center;
  gap: 3.75rem;
  overflow-x: auto;
  margin-bottom: 5rem;

  .textSection {
    flex: 0 0 43%;

    > * {
      margin-bottom: 1.5rem;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .tmtImageContainer {
    margin: auto;
    min-width: 350px;
    height: auto;
  }

  @include variables.mobile() {
    flex-direction: column;
    margin-bottom: 1rem;
    gap: initial;
  }
}

.linksSection {
  display: flex;
  align-items: center;
  margin-top: 2.5rem;

  @include variables.mobile() {
    justify-content: center;
    gap: 1.5rem;
    margin-top: 2rem;
  }

  .hasMarginRight {
    margin-right: 1rem;
    @include variables.mobile() {
      margin-right: 0;
    }
  }

  .qrCode {
    max-width: 3.75rem;
    max-height: 3.75rem;
  }
}

.linksSectionAbout {
  display: flex;
  justify-content: space-around;
}

.sectionCompare {
  margin: 4.375rem -1rem 4rem -1rem;

  .linksSectionCompare {
    display: flex;
    justify-content: center;
  }

  .wtButton {
    width: 16rem;
  }

  @include variables.mobile() {
    margin-top: 0;
    margin-bottom: 1rem;

    .linksSectionCompare {
      margin-bottom: 1.5rem;
    }
  }
}

.tabsWrapper {
  margin-bottom: 2.5rem;

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .tabLink {
      width: 10rem;
      max-width: 100%;
      flex-grow: 1;
    }
  }

  @include variables.mobile {
    width: 100%;
    margin-bottom: 0;

    .buttonWrapper {
      width: 100%;
    }
  }
}

.tableWrapper {
  width: 100%;
}

.table {
  .tableHead {
    margin-bottom: 1.5rem;
    padding: 1rem;
  }

  .tableHeadColumn {
    padding: 1.5rem 0;

    .tableHeader {
      color: var(--text);
    }
  }

  .column {
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 8px 20px var(--contrast-low);
    background-color: var(--background-secondary);
  }

  .column:last-child {
    box-shadow: 0 8px 10px var(--contrast-low);
  }

  @include variables.mobile {
    .tableHead {
      text-align: left;
      margin-bottom: 0;
    }

    .columns {
      text-align: center;
    }

    .column {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 4rem;
      padding: 0.875rem 1.5rem;
      margin: 0 1rem 0.5rem 1rem;
    }

    .column:last-child {
      margin-bottom: 1.5rem;
    }

    .columnName {
      text-align: left;
      width: 100%;
    }
  }
}

.aboutSection {
  margin: 0 auto 2.5rem auto;
  text-align: center;
  width: 50%;

  .title {
    margin-bottom: 1.5rem;
  }

  @include variables.mobile {
    margin: 0 auto 0 auto;
    text-align: start;
    width: 100%;
  }
}

.imageContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
  gap: 1rem;

  @include variables.mobile {
    grid-template-columns: 1fr !important;
  }

  .imageCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 326px;

    img {
      border-top-left-radius: variables.$radius-large;
      border-top-right-radius: variables.$radius-large;
      width: 100%;
    }

    .imageText {
      margin-left: 1rem;

      @include variables.mobile {
        margin-bottom: 1.5rem;
      }
    }
  }
}

.highlight {
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: variables.$font-weight-medium;
}

.divider {
  margin: 1.5rem 0;
  border-bottom: 1px solid var(--contrast-low);
}

.hasMarginBottom {
  margin-bottom: 1rem;
}

.hasMarginTop {
  margin-top: 1rem;
}

.textSecondary {
  color: var(--text-secondary);
}
