.tabsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  //position: sticky;
  top: 0;
  z-index: 1;
  background-color: transparent;
  padding: 1.25rem 0;
  margin-top: 0 !important;
}

.noTabs {
  display: block;
  height: 1rem;
}
