@use '../../ui/styles/typography';
@use '../../layout/variables';

.groupTitle {
  display: flex;
  padding: 1.5rem 0px 0.5rem 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}

.videoBox {
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  cursor: pointer;

  @include variables.mobile() {
    padding: 0.75rem;
  }

  border-radius: variables.$radius-xlarge;
  background: var(--background-secondary);
  box-shadow: 0px 0.5rem 2.5rem rgba(0, 0, 0, 0.04);

  .placeholder {
    display: flex;
    padding: 68px 0;
    width: 100%;
    justify-content: center;
    align-items: center;

    border-radius: variables.$radius-medium;
    background-color: var(--contrast-low);

    .innerBox {
      max-width: 136px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      .logoBox {
        width: 100%;

        svg {
          width: 100%;
          height: auto;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background-color: var(--contrast-medium-low);
        max-width: 140px;
        margin: 0;
      }

      .label {
        color: var(--text-secondary);
      }
    }
  }
}

.innerLearningVideos {
  background: var(--background-secondary);
  box-shadow: 0px 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem;
  margin-top: 1rem;

  display: flex;
  align-items: stretch;
  gap: 1.5rem;
  align-self: stretch;

  @include variables.mobile() {
    flex-direction: column;
  }

  .otherVideosBox {
    display: flex;
    width: 30%;
    min-width: 260px;
    padding-top: 3rem;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    max-height: 500px;
    overflow: scroll;

    .otherVideo {
      cursor: pointer;
      display: flex;
      padding: 0.5rem 0.75rem;
      align-items: flex-start;
      gap: 0.25rem;
      align-self: stretch;
      border-radius: variables.$radius-medium;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: var(--contrast);
      }

      &.selected {
        background-color: var(--contrast-low);
      }
    }
  }

  .mobileOtherVideosBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .selectorBox {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.25rem;
    }
  }

  .videoBox {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: 1 0 0;

    @include variables.mobile() {
      width: 100%;
    }

    .video {
      display: flex;
      height: 100%;
      width: 100%;

      video {
        border-radius: variables.$radius-medium;
        width: 100%;
        height: fit-content;
      }
    }
  }
}
