@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.emptyTransactionHistory {
  width: 100%;
  padding-top: 1rem;
  position: relative;
  overflow: hidden;

  .infoWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
  }
}
