.form {
  width: 100%;
  padding: 1.5rem;
  border-radius: 1rem;
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);

  .formItem {
    margin-bottom: 0.75rem;
  }

  .currencyFieldWrapper {
    width: 8.75rem;
  }
}
