@use '../../../layout/variables';

.hasAdditionalGap {
  gap: 1rem;
}

.leftSection {
  display: flex;
  flex-direction: column;
  background: var(--background);
  border-radius: variables.$radius-large;
  padding: 2rem;
  flex: 1;
  gap: 1rem;

  @include variables.mobile() {
    gap: 1.5rem;
  }

  .title {
    padding-bottom: 1rem;

    @include variables.mobile() {
      padding: 0;
    }
  }

  .qrCode {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .hasIcon {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;

    svg {
      margin-inline-end: 0.5rem;
    }
  }
}
