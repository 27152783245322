@use '../../../layout/variables';
@use '../../../ui/styles/typography';

@mixin header($bg) {
  display: flex;
  align-items: center;
  background-color: $bg;
  padding: 1.5rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;
}

.headerGray {
  @include header(var(--contrast-low));

  .title {
    display: flex;
    align-items: center;
    :first-child {
      margin-inline-end: 0.5rem;
    }
  }
}

.headerWhite {
  @include header(var(--background-secondary));

  p {
    @include typography.textStrong;
  }
}

.textSuccess {
  color: var(--success);
}

.text {
  @include typography.text;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  padding: 1rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;
  align-content: center;

  h3 {
    margin: 0.75rem 0;
  }

  p {
    margin: 0.75rem 0;
  }
  .overviewWrapper {
    display: flex;
    .overview {
      background-color: var(--background);
      border: 1px solid var(--contrast-low);
      border-radius: variables.$radius-medium;
      padding: 1rem;
      min-height: 16.25rem;
      margin-inline-end: 1rem;
      width: 33%;

      .icon {
        padding-top: 0.7rem;
      }
    }

    :last-child {
      margin: 0;
    }
  }

  :global(.column) {
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;
  }
}

.header {
  display: flex;
  align-items: center;
  background-color: var(--background-secondary);
  padding: 1.5rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;
}

.textGray {
  color: var(--text-secondary);
}

.card {
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  padding: 1rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 1rem 0;

  p {
    margin: 0.75rem 0;
  }
}
.buttonWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;

  :global(.button) {
    padding: 0 8rem;
  }
}

.questionName {
  @include typography.textSmall;
  padding-inline-start: 0.5rem;
}

.questionError {
  @include typography.textTiny;
  color: var(--error);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.textStrong {
  @include typography.textStrong;
}

@include variables.mobile() {
  .card {
    .overviewWrapper {
      flex-direction: column;
      .overview {
        width: 100%;
        margin-bottom: 1rem;
      }
      :last-child {
        margin: 0;
      }
    }
  }
}
