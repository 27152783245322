@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.infoList {
  @include typography.text;
  list-style: inside;
}

.headerLabel {
  color: var(--text-secondary);
}

.addNewPlanModal {
  background-color: var(--background);
  display: flex;
  padding: 1.5rem;

  .tableLabel {
    @include typography.textSmall;
    color: var(--text);
    vertical-align: middle;
  }

  .valueColumn {
    width: 25%;
    padding: 0.5rem !important;
  }

  .modalTable {
    border-collapse: separate;

    th,
    td {
      padding: 1rem;
    }
  }
}

.subIBModal {
  background-color: var(--background);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .subIBModalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .inputField {
      width: 40%;

      @include variables.mobile() {
        width: 100%;
      }
    }
  }
}
