@use '../../ui/styles/typography';
@use '../../layout/variables';

.field {
  position: relative;

  &.hasTextDanger {
    color: var(--primary);
  }

  &.fullWidth {
    width: 100%;
  }

  .fieldBackground {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .select {
    width: 100%;
    height: auto;
    border: 1px solid var(--contrast-medium-low);
    border-radius: variables.$radius-medium;
    background: var(--background-secondary);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    padding: 0.5rem 0.75rem;
    padding: {
      top: 0.5rem;
      right: 0.75rem;
      bottom: 0.375rem;
      left: 0.75rem;
    }
    min-height: 3.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.mini {
      min-height: initial;
    }

    &.inputError {
      border-color: var(--error);
    }

    &.disabled {
      cursor: not-allowed;
      color: var(--disabled);
      background-color: var(--contrast-low);
    }

    .labelValueContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;

      .label {
        @include typography.textTiny;
        color: var(--text-secondary);
        margin: 0;

        &.hasTextDanger {
          color: var(--error);
        }
      }

      .selectedOption {
        @include typography.textStrong;
        display: flex;
        align-items: center;
        gap: 6px;

        &.mini {
          @include typography.textSmall;
        }

        .icon {
          background-color: var(--contrast-low);
          border-radius: variables.$radius-small;
          padding: 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .placeholder {
      color: var(--text-secondary);
    }
  }

  .optionsList {
    position: absolute;
    left: 0;
    right: 0;
    z-index: map-get(variables.$zIndex, selectFieldDropdown);
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    gap: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &.primary {
      top: 100%;
      margin-top: 0.5rem;
      border: 1px solid var(--contrast-medium-low);
      background: var(--background-secondary);
      border-radius: variables.$radius-medium;
      overflow-y: auto;
      max-height: 10rem;
      gap: 0.25rem;

      .option {
        padding: 0.5rem 1rem;
        transition: all 0.2s ease-in-out;
        border-radius: variables.$radius-large;

        &:hover {
          background: var(--contrast-low);
        }

        &.selectedOption {
          background: var(--contrast-low);
          color: var(--contrast-max);

          .optionLabel {
            color: var(--contrast-max);
          }
        }

        .optionLabel {
          color: var(--contrast-high);
        }
      }
    }

    &.secondary {
      bottom: 100%;
      margin-bottom: 0.5rem;
      border: 1px solid var(--contrast-low);
      background: var(--surface-secondary);
      border-radius: variables.$radius-xlarge;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
      text-align: center;
      justify-content: center;
      align-items: center;

      .option {
        width: 100%;
        padding: 0.5rem 0;
        text-align: center;
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        border-radius: variables.$radius-xlarge;

        .optionLabel {
          color: var(--contrast-high);
        }

        &.selectedOption,
        &:hover {
          background-color: var(--contrast-low);
          color: var(--contrast-max);

          .optionLabel {
            color: var(--contrast-max);
          }
        }
      }

      .optionLabel {
        color: var(--contrast-high);
      }
    }
  }

  .hint {
    color: var(--text-secondary);
    margin-top: 0.25rem;
  }

  .errorMessage {
    margin-top: 0.25rem;
    color: var(--error);
    padding-left: 0.25rem;
  }
}
