@use '../../ui/styles/typography';
@use '../../layout/variables';

.hasPositionTop {
  margin-top: 2rem !important;
}

.sortButton {
  background: var(--background) !important;
}

.error {
  @include typography.textSmall;
  color: var(--error);
  display: flex;
  align-items: center;
}

.text {
  @include typography.text;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--background);
  border-radius: variables.$radius-medium;
  gap: 4rem;
}

.box {
  text-align: start !important;
}

.expandableParent {
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0 !important;

  td {
    &:first-child {
      border-radius: variables.$radius-xlarge 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 variables.$radius-xlarge 0 0 !important;
    }
  }
}

.expandable {
  background-color: var(--background-secondary);

  td {
    border-radius: 0 0 variables.$radius-xlarge variables.$radius-xlarge !important;
  }

  td:hover {
    background-color: var(--background-secondary);
  }
}

.secondaryTextSmall {
  @include typography.textSmall;
  color: var(--text-secondary);
}

.infoIcon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  width: 0.75rem;
  height: 0.75rem;
  margin-inline-start: 0.25rem;
}

.hasNoPadding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.trimShort {
  @include typography.textTrimShort;
}

.trimMid {
  @include typography.textTrimMid;
}

.trimLong {
  @include typography.textTrimLong;
}

.customRow {
  width: 11.25rem;
}

.nameRow {
  width: 13.75rem;
}

.dateRow {
  width: 8.125rem;
}

.amountRow {
  text-align: end !important;
}

.table th:last-child,
.table td:last-child {
  width: 4.375rem;
  text-align: end !important;
}

.transactionTypeName {
  display: flex;
  align-items: center;
  width: max-content;
}
