@use '../../../layout/variables';

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 1rem;
  margin-top: 1rem;

  &.mobileResize {
    @include variables.mobile {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
  }

  @include variables.mobile {
    gap: 0.5rem;
  }
}
