@use '../../layout/variables';

html,
body {
  height: 100%;
}

.overlay {
  background-color: inherit;
  position: relative;
  overflow: hidden;

  .wrapper {
    z-index: map-get(variables.$zIndex, loader);
    background-color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding: 0;
    flex-direction: row;
  }
}

.wrapper {
  color: var(--text);
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(
    100% - var(--page-header-height, 0px) - var(--nav-bar-height, 0px) -
      var(--status-banner-height, 0px)
  );
  flex-direction: column;
  padding: 2rem 0;
  gap: 2rem;

  .icon {
    animation: rotation 0.4s infinite linear;
    line-height: 0;

    &.stopAnimation {
      animation: none;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }
}

.isModal {
  background: var(--background-secondary);
}

@include variables.mobile() {
  .wrapper {
    .icon {
      margin-bottom: 1rem;
    }

    padding: 1rem;
  }
}
