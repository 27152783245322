@use '../../../ui/styles/typography';

.greyText {
  color: var(--text-secondary);
}

.subtitle {
  @include typography.textSmall;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: var(--text);
  margin-top: 0.25rem;
}

.editButton {
  @include typography.text;
  display: flex;
  align-items: center;
  color: var(--text);
  cursor: pointer;
}

.mobileAddButton {
  width: 100%;
}

.indentedColumn {
  padding-inline-start: 1rem !important;
}

.networkTable {
  margin-bottom: 0.5rem;
}
