@use '../../ui/styles/typography';
@use '../../layout/variables';

.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
}

.title {
  color: var(--text-secondary);
}

.clickable {
  @include typography.textSmall;
}

.textSmall {
  @include typography.textSmall;
}
