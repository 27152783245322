@use '../../ui/styles/typography';
@use '../../layout/variables';

.container {
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;

  .inviteSection {
    padding: 2rem;
    background-color: var(--background-secondary);
    width: 50%;
    margin-inline-end: 0.75rem;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;

    .link {
      color: var(--text-interactive);
      cursor: pointer;
    }

    .selector {
      @include typography.textStrong;
      margin: 1.5rem 0;
    }
  }

  .linkSection {
    padding: 2rem;
    background-color: var(--background-secondary);
    width: 50%;
    margin-inline-start: 0.75rem;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .documents {
      display: flex;
      @include typography.text;
      width: 100%;
    }

    > div {
      display: flex;
      flex-direction: column;
      margin: 0.75rem 0;

      > p:first-child {
        @include typography.textStrong;
        align-items: center;
        justify-content: space-between;
      }

      > p:last-child {
        color: var(--text-secondary);
      }
    }
  }

  @include variables.mobile() {
    flex-direction: column;

    .inviteSection {
      width: 100%;
    }

    .linkSection {
      width: 100%;
      margin: 1rem 0;

      div {
        margin: 0.25rem 0;
      }
    }
  }
}

.title {
  background: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1.75rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include variables.mobile() {
    margin-top: 1rem;
  }
}

.copyAR {
  position: absolute;
  left: 1.25rem;
  top: 1.875rem;
}

.isPageHeaderVisible {
  visibility: hidden;
  height: 0;
  min-height: 0;
  padding: 0;
}
