@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
  margin: auto;

  padding: 1rem;

  :global(.button) {
    width: 100%;
  }

  .action {
    display: flex;

    button:first-child {
      margin-inline-end: 0.5rem;
    }
  }
}

.formWithCurrency {
  display: flex;
  :global(.field):first-child {
    flex-grow: 1;
    margin-inline-end: 0.5rem;
  }
  :global(.field):last-child {
    width: 100px;
  }
}
