@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  background: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

.levelButton {
  @include typography.textSmall;
  padding: 0;
}

.header {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.75rem;

  .headerTextStyles {
    color: var(--text-secondary);
    font-weight: variables.$font-weight-medium;
  }

  .name {
    min-width: 40%;
  }

  .price {
    min-width: 30%;
  }

  .status {
    min-width: 25%;
    text-align: center;
  }
}

.subHeader {
  display: flex;

  .name {
    min-width: 40%;

    @include variables.mobile() {
      flex: 1 0 0;
    }
  }

  .price {
    min-width: 30%;
  }

  .status {
    min-width: 25%;
    text-align: center;

    @include variables.mobile() {
      text-align: center;
    }
  }

  .control {
    min-width: 5%;
    padding-inline-start: 0.75rem;

    @include variables.mobile() {
      min-width: 15% !important;
      text-align: end;
    }
  }
}

.dataItem {
  padding-top: 1rem;
  margin-inline-start: 1rem;

  .titleWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    @include variables.mobile() {
      flex-direction: column;
      align-items: flex-start;
      padding-inline-end: 1.5rem;
    }

    .name {
      flex: 0 0 40%;
      padding-inline-end: 0.5rem;
      flex-direction: column;
      align-items: flex-start;
    }

    .price {
      @include typography.textSmall;
      flex: 0 0 30%;
      padding-inline-end: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include variables.mobile() {
        margin: 1.5rem 0;
        padding-inline-end: 0;
        width: 100%;
      }
    }

    .status {
      flex: 0 0 25%;
      text-align: center;

      @include variables.mobile() {
        display: flex;
        justify-content: center;
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
}

.dataDescription {
  display: flex;
  flex: 0 0 45%;
  max-width: 290px;

  @include variables.mobile() {
    display: none;
  }
}

.mobileDataDescription {
  display: none;

  @include variables.mobile() {
    display: block;
  }
}

.products {
  .product {
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    background: var(--background-secondary);
    border-radius: variables.$radius-xlarge;
    padding: 1.5rem;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    @include variables.mobile() {
      padding: 1rem;
    }
  }
}

.standaloneProductsHeader {
  margin-top: 2.5rem;
  margin-bottom: 1.5rem;

  @include variables.mobile() {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

.separator {
  background: var(--background-gray);
  height: 1px;
  margin: 1rem 0;
  opacity: 80%;
}

.statusIcon {
  position: absolute;
  margin-inline-start: -2rem;
}

.description {
  padding-inline-start: 2rem;
}

.textSecondary {
  color: var(--text-secondary);
}
