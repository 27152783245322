@use '../../ui/styles/typography';
@use '../../layout/variables';

.listContainer {
  display: flex;
  align-items: stretch;
  margin-top: 1rem;
  gap: 2rem;
  padding: 1.5rem;
  background: var(--background-secondary);
  border-radius: variables.$radius-large;

  @include variables.mobile() {
    flex-direction: column;
  }

  .video {
    border-radius: variables.$radius-large;
    width: 100%;
    height: auto;

    @include variables.mobile() {
      width: 100%;
      min-width: 100%;
      margin-bottom: 2rem;
    }
  }

  .list {
    flex: 0 0 25%;
    gap: 0.25rem;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: scroll;

    @include variables.mobile() {
      min-width: 100%;
      margin-left: 0;
      max-height: 200px;
    }

    .item {
      cursor: pointer;
      padding: 0.75rem 0.5rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: variables.$radius-medium;

      &:hover {
        background-color: var(--contrast-low);
      }

      .name {
        @include typography.textSmall;
      }

      &.isSelected {
        background-color: var(--contrast-low);

        .name {
          @include typography.textSmallStrong;
        }

        .duration {
          color: var(--text);
        }
      }

      .duration {
        margin-left: auto;
        color: var(--text-secondary);
      }
    }
  }
}

.courses {
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.75rem;
    background: var(--background-secondary);
    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;
    padding: 1.5rem;
    cursor: pointer;

    .itemWrapper {
      padding: 0.5rem 0.75rem;
    }

    &:hover {
      background-color: var(--contrast-low);
      border-radius: variables.$radius-medium;
    }
  }
}
