@use '../styles/typography';

.validationPoint {
  @include typography.textTiny;

  .validationPointBox {
    display: flex;
    padding-bottom: 0.5rem;
    align-items: center;

    .iconBox {
      margin-inline-end: 0.25rem;
      display: flex;
    }
  }
}
