@use '../../ui/styles/typography';

.wrapper {
  display: flex;
  align-items: baseline;

  &.centered {
    align-items: center;
  }

  .checkboxWrapper {
    position: relative;
    height: 16px;
    width: 16px;

    &.disabled {
      cursor: default;
    }

    .input {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }

    .icon {
      cursor: pointer;

      .checkboxIcon {
        height: 16px;
        width: 16px;
        fill: none;
        stroke: var(--contrast-high);
      }

      &.active {
        .checkboxIcon {
          fill: var(--contrast-high);
          stroke: none;
        }

        &.disabled {
          .checkboxIcon {
            fill: var(--disabled);
            stroke: none;
          }
        }
      }

      &.disabled {
        .checkboxIcon {
          stroke: var(--disabled);
        }
      }
    }
  }

  .contentWrapper {
    margin-inline-start: 1rem;

    .hint {
      display: block;
      color: var(--text-secondary);
    }

    &.disabled {
      cursor: default;
    }
  }
}

.errorMessage {
  color: var(--error);
  padding-inline-start: 2.5rem;
  margin-top: 0.25rem;
}
