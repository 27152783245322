@use '../../../layout/variables';

.signupWrapper {
  display: flex;
  box-shadow: 0 8px 40px rgba(0, 0, 0, 0.04);
  background: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem;
  margin-top: 1.5rem;
  gap: 0.5rem;
  flex-wrap: wrap-reverse;

  @include variables.mobile() {
    padding: 0;
    margin-top: 1rem;
    flex-direction: column;

    section:first-child {
      order: 2;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: none;
      margin-top: 0;
      margin-inline-start: 0;
      padding: 1rem;
      background: var(--background-secondary);

      > form > h3 {
        padding-bottom: 1.5rem;
      }
    }

    &.reversed {
      flex-direction: column-reverse;
    }
  }

  &.noBackground {
    @include variables.mobile() {
      box-shadow: none;
      background-color: var(--background);
    }
  }
}
