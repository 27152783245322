@use '../../../ui/styles/typography';
@use '../../../layout/variables';

$tooltip-margin: 30px;
$tooltip-margin-new: 0.5rem;
$tooltip-arrow-size: 6px;
$tooltip-info-color: var(--contrast-max);

.tooltipWrapper {
  max-width: 100%;
  display: inline-block;
  position: relative;

  svg {
    display: flex;
  }

  .tooltip {
    @include typography.textSmall;
    position: absolute;
    border-radius: variables.$radius-small;
    inset-inline-start: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    color: var(--text);
    z-index: 100;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    opacity: 90%;
    max-width: 300px;
    text-wrap: wrap;
    overflow-y: visible;
    height: auto;
    width: max-content;
    color: var(--text-inverse);
    background: $tooltip-info-color;
    pointer-events: none;

    @include variables.mobile() {
      max-width: 200px;
    }

    span {
      text-wrap: wrap;
      word-break: break-word;
    }

    .checkIcon {
      height: 18px;
      width: 18px;
    }

    &.active {
      pointer-events: initial;
    }

    &.top {
      bottom: calc(100% + 10px);

      &::before {
        top: 100%;
        border-top-color: $tooltip-info-color;
      }
    }

    &.right {
      inset-inline-start: calc(100% + $tooltip-margin);
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &::before {
        inset-inline-start: calc(#{$tooltip-arrow-size} * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: $tooltip-info-color;
      }
    }

    &.bottom {
      top: calc(100% + 10px);

      &::before {
        bottom: 100%;
        border-bottom-color: $tooltip-info-color;
      }
    }

    &.left {
      inset-inline-start: auto;
      inset-inline-end: calc(100% + $tooltip-margin);
      top: 50%;
      transform: translateX(0) translateY(-50%);

      &::before {
        inset-inline-start: auto;
        inset-inline-end: calc(#{$tooltip-arrow-size} * -2);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: $tooltip-info-color;
      }
    }

    &.topRight {
      bottom: calc(100% + 10px);
      inset-inline-start: 0;
      transform: none;

      &::before {
        top: 100%;
        inset-inline-start: calc(50% - 10px);
        border-top-color: $tooltip-info-color;
      }
    }

    &.topLeft {
      inset-inline-start: auto;
      inset-inline-end: 0;
      bottom: calc(100% + 10px);
      transform: none;

      &::before {
        top: 100%;
        inset-inline-start: calc(100% - ($tooltip-margin / 2));
        border-top-color: $tooltip-info-color;
      }
    }

    &.bottomRight {
      top: calc(100% + 10px);
      inset-inline-start: 0;
      transform: none;

      &::before {
        bottom: 100%;
        inset-inline-start: calc(50% - 10px);
        border-bottom-color: $tooltip-info-color;
      }
    }

    &.bottomLeft {
      inset-inline-start: auto;
      inset-inline-end: 0;
      top: calc(100% + 10px);
      transform: none;

      &::before {
        bottom: 100%;
        inset-inline-start: calc(100% - ($tooltip-margin / 2));
        border-bottom-color: $tooltip-info-color;
      }
    }
  }

  &.new {
    .tooltip {
      padding: 0.38rem 0.88rem;
      border-radius: 0.875rem;

      &.top {
        bottom: calc(100% + #{$tooltip-margin-new});
      }

      &.right {
        inset-inline-start: calc(100% + #{$tooltip-margin-new});
      }

      &.bottom {
        top: calc(100% + #{$tooltip-margin-new});
      }

      &.left {
        inset-inline-end: calc(100% + #{$tooltip-margin-new});
      }
    }
  }
}
