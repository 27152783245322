@use '../../layout/variables';
@use '../../ui/styles/typography';

.closeBox {
  padding: 1rem 0.15rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 1rem;
  transition: box-shadow 0.1s ease-in-out;

  &.showShadow {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

.variantsScroller {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5rem;
  direction: initial;

  @include variables.mobile() {
    padding: 1rem;
    justify-content: center;
    height: 100%;
    max-height: initial;
    align-items: start;
    align-content: start;
    overflow-y: scroll;
    padding-bottom: 8rem;
  }

  .optionWrapper {
    padding: 0.5rem 1rem;

    .option {
      display: flex;
      color: var(--text);
      border-radius: variables.$radius-small;
      border: 1px solid transparent;
      justify-content: flex-end;
      text-align: end;
      align-items: center;
      gap: 1rem;
      cursor: pointer;

      @include variables.mobile() {
        justify-content: flex-start;
        text-align: start;
      }

      &.active {
        color: var(--primary);
      }

      &:hover {
        background-color: var(--contrast-low);
      }
    }
  }
}
