@use '../../layout/variables';
@use '../../ui/styles/typography';

.modal {
  background: var(--background-secondary);
  border-radius: variables.$radius-medium;
  overflow-x: hidden;

  .serviceModal {
    display: flex;
    align-items: center;
    position: relative;

    @include variables.mobile() {
      flex-direction: column;
    }

    .image {
      max-width: 400px;
      height: 100%;
      width: auto;
      flex-shrink: 0;
      align-self: stretch;

      @include variables.mobile() {
        height: auto;
        width: 100%;
        max-width: initial;
        max-height: 200px;
        object-fit: contain;
        margin-top: 3rem;
      }
    }

    .text {
      display: flex;
      padding: 3rem 3rem 2rem 3rem;
      align-items: flex-start;
      flex-direction: column;
      gap: 2rem;
      flex: 1 0 0;

      @include variables.mobile() {
        padding: 1.5rem;
        align-items: center;
      }
    }

    .close {
      position: absolute;
      top: 32px;
      right: 32px;

      @include variables.mobile() {
        padding: 0.5rem;

        left: 16px;
        top: 16px;
        right: initial;

        border-radius: 40px;
        background: var(--background);

        box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
      }
    }
  }
}
