@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.iBWallet {
  width: 100%;

  .walletsContainer {
    padding-top: 1rem;

    .walletsContainerScroll {
      gap: 1.5rem;
      display: flex;
      align-items: stretch;

      .wallet {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-width: 290px;
        gap: 1rem;

        .header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .currency {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .status {
            display: flex;
            flex-direction: column;
            text-align: end;

            .statusTitle {
              color: var(--text-secondary);
            }
          }
        }

        .info {
          .walletName {
            margin-top: 0.25rem;
            color: var(--text-secondary);
          }
        }

        .buttons {
          margin-top: auto;
          display: flex;
          gap: 1rem;

          @include variables.mobile {
            gap: 0.5rem;
          }

          .button {
            flex: 1;
          }

          .infoCard {
            flex: 1;
            width: 100%;
          }
        }
      }
    }
  }
}
