@use '../../ui/styles/typography';

.phoneColumn {
  width: 150px;
}

.statusColumn {
  width: 232px;
}

.deleteColumn {
  width: 75px;
}

.primaryColumn {
  width: 200px;

  .innerPrimary {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    svg {
      height: 19px;
    }
    &.isDisabled {
      pointer-events: none;
    }

    .setPrimary {
      color: var(--text-interactive);
      margin-inline-start: 0.5rem;
      &.isDisabled {
        pointer-events: none;
      }
    }
  }
}
