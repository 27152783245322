@use '../../ui/styles/typography';
@use '../../layout/variables';

.searchInput {
  @include typography.textSmall;
  display: flex;
  position: relative;
  align-items: center;

  .icon {
    position: absolute;
    left: 1 * 1rem;

    @include variables.mobile() {
      left: 0.5rem;
    }
  }

  input {
    width: 100%;
    outline: none;
    padding: 1rem;
    padding-inline-start: (2 * 2rem);
    border-radius: variables.$radius-medium;
    background-color: var(--background-secondary);
    border: 1px solid var(--contrast-medium-low);
    color: var(--text);

    @include variables.mobile() {
      padding-inline-start: 2rem;
    }
  }
}
