@use '../../../ui/styles/typography';

.body {
  padding-top: 2rem;
  color: var(--text);
  background-color: var(--background-secondary);

  input[type='radio'] {
    margin-inline-end: 1rem;
  }

  .wrapper {
    .walletWrapper {
      .accountWrapper {
        border-top: 1px solid var(--contrast-low);
        padding-bottom: 0.5rem;

        .account {
          padding-top: 0.5rem;
          padding-inline-start: 4rem;
        }
      }
    }

    .wallet {
      padding: 0.5rem 2rem;
    }

    :last-child .wallet {
      border: 0;
    }

    .balance {
      @include typography.textSmall;
      color: var(--text-secondary);
      padding: 0 2rem;
    }
  }

  .grayText {
    color: var(--text-secondary);
  }

  .primaryText {
    color: var(--primary);
  }

  :global(.is-link) {
    margin: 0 0.25rem;
    text-decoration: none;
  }
}

.footer {
  background-color: var(--background-secondary);

  button {
    color: var(--text);
  }
}

.textStrong {
  @include typography.textStrong;
}

.textSmall {
  @include typography.textSmall;
}
