@use '../../layout/variables';

.notificationWrapper {
  margin: 0 0 2.5rem 0 !important;

  @include variables.mobile() {
    margin-bottom: 1.75rem !important;
  }

  .notificationSubtitle {
    color: var(--text);
  }
}
