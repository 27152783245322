@use '../../ui/styles/mixins';
@use '../../layout/variables';

.btn {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  height: auto;
  color: inherit;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &[disabled],
  &fieldset[disabled] {
    background-color: initial;
    border-color: initial;
    opacity: initial;
  }

  &:hover {
    color: inherit;
  }

  &.selectable {
    color: var(--text);
    border: none;
    border-radius: 50%;
    padding: 0.5rem 0.75rem;

    @include mixins.button-bg(
      transparent,
      var(--contrast-low),
      var(--text),
      transparent,
      var(--text),
      var(--press)
    );
  }

  &.hoverable {
    display: flex;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: variables.$radius-full;
    width: fit-content;

    &:hover {
      background: var(--contrast-low);
    }
  }

  &.new {
    border-radius: 50%;

    &.secondary {
      border: none;

      .contentWrapper {
        gap: 0.25rem;
      }

      &.disabled {
        opacity: 1;
      }

      @include mixins.button-bg(
        var(--background-bg2),
        var(--state-background-hover),
        var(--text),
        var(--background-content) !important,
        var(--text),
        var(--state-background-press)
      );
    }

    &.ghost {
      border: none;

      @include mixins.button-bg(
        transparent,
        var(--state-hover),
        var(--text),
        var(--foreground-disabled) !important,
        var(--text),
        var(--state-press)
      );
    }

    &.size-L {
      padding: 1rem;
    }

    &.size-M {
      padding: 0.5rem;
    }

    &.size-S {
      padding: 0.38rem;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  & * {
    cursor: pointer;
  }
}
