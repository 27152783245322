@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.questionAnswerField {
  background-color: var(--background);
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid var(--contrast-low);
  border-radius: variables.$radius-large;

  :global(.control) {
    margin-top: 1rem;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5rem;

  &:first-child {
    margin-inline-end: 0.5rem;
  }

  @include variables.mobile() {
    padding: 0 2rem;
    margin: 0;

    &:first-child {
      margin-inline-end: 0.5rem;
    }
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  padding: 2rem;
  border: 1px solid var(--contrast-low);
  order: 3;

  @include variables.mobile() {
    margin-inline-start: 0;
    padding: 1rem;
    border: none;

    &.reversed {
      order: 1;
    }
  }

  > form > h3 {
    padding-bottom: 1.5rem;
  }
}

.textStrong {
  @include typography.textStrong;
}
