@use '../../ui/styles/typography';
@use '../../layout/variables';

html,
body {
  height: 100%;
}

.modalCardBody {
  padding-bottom: 2.5rem;
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0 !important;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-direction: column;

  .row {
    display: flex;
    padding: 1rem;
    width: 20rem;

    div:first-child {
      flex-grow: 1;
      width: 50%;
    }

    div:last-child {
      text-align: end;
    }
  }
}

.footer {
  justify-content: center !important;
  color: var(--text);
  background-color: var(--background);
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.5rem;

  .icon {
    margin: 1rem;
    margin-top: 1.5rem;
  }
}
