@use '../../ui/styles/typography';
@use '../../layout/variables';

$columnSpacing: 1rem;

.ibQuestionnaire {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;

  .error {
    color: var(--error);
  }

  .questionBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .fieldGroup {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: $columnSpacing;

      @include variables.mobile() {
        flex-direction: column;
      }

      .selectorBox {
        border-radius: variables.$radius-medium;
        border: 1px solid var(--contrast-medium-low);
        display: flex;
        padding: 0.75rem 1rem;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex: 1 0 0;
        min-width: calc(50% - $columnSpacing);
      }

      .inputField {
        width: 100%;
      }
    }
  }

  .agreementBoxWrapper {
    margin-top: 1rem;
  }

  .submit {
    width: 100%;
    text-align: center;

    .submitButton {
      min-width: 290px;

      @include variables.mobile() {
        width: 100%;
      }
    }
  }
}
