@use '../../layout/variables';
@use '../styles/typography';

.popover {
  z-index: 10000;

  @include variables.mobile() {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;

    &.isOpen {
      pointer-events: auto;
    }
  }

  .popoverInner {
    position: absolute;
    top: 74px;
    inset-inline-end: 0;
    background-color: var(--background);
    border: none;
    border-radius: variables.$radius-medium;
    color: var(--text);
    width: auto;
    padding: 0.5rem;
    pointer-events: none;
    clip-path: inset(100%);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);

    &.isDark {
      box-shadow: 0px 4px 16px 0px rgba(255, 255, 255, 0.2);
    }

    @include variables.mobile() {
      justify-content: center;
      grid-template-columns: 1fr;
      position: fixed;
      border-radius: initial;
      top: 0;
      height: 100%;
      max-height: initial;
      align-items: start;
      align-content: start;
      padding: 0;
    }

    @include variables.mobile() {
      min-width: 80%;
    }

    &.isOpen {
      pointer-events: auto;
    }
  }
}
