@use '../../ui/styles/typography';
@use '../../layout/variables';

.tr {
  border: 2px solid;
}

tr.selected {
  background-color: #cc0000;
  background-image: linear-gradient(90deg, #890000, #cc0000);
}

.table tr.selected td {
  color: var(--text-inverse);
}

.divider {
  height: 1px;
  background: var(--background-gray);
}

.tableWrapper {
  overflow-x: auto;
  overflow-y: clip;
  padding: 0 3rem 0.5rem;
  margin: 0 -3rem 1rem;

  transition: padding 0.3s ease-in-out;
}

.table {
  border-collapse: separate;
  border-style: hidden;
  background-color: transparent;
  border-spacing: 0;
  padding: 0;
  width: 100%;
  height: auto;
  overflow-y: clip;
  min-width: 900px;
  overflow-x: auto;

  &.tableSmall {
    min-width: initial;
  }

  thead,
  .thead {
    background: transparent !important;
    display: table-header-group;

    th {
      @include typography.textTiny;
      padding: 1.25rem 0.5rem;
      padding-bottom: 0;
      color: var(--text-secondary) !important;
      border: none;

      &:first-child {
        padding-inline: 1.5rem 0;
      }

      &:last-child {
        padding-inline: 0 1.5rem;
      }
    }
  }

  tbody tr {
    background-color: var(--background-secondary);
    border-radius: variables.$radius-xlarge variables.$radius-xlarge variables.$radius-xlarge
      variables.$radius-xlarge;
    height: 48px;
    margin-bottom: 0.5rem;

    td:first-child {
      padding-inline: 1.5rem 0.5rem;
    }

    td:last-child {
      padding-inline: 0.5rem 1.5rem;
    }

    td {
      @include typography.textSmall;
      color: var(--text);
      position: relative;
      vertical-align: inherit;
      border: none;
      padding: 1.25rem 0;
      padding-inline: 0.5rem 0.5rem;

      &.noPaddingCell {
        padding: 0;
      }

      &.noResult {
        border-radius: 16px !important;
        width: 100%;
      }

      &:has(strong) {
        @include typography.textSmallStrong;
      }

      strong {
        color: var(--text);
      }
    }

    @include variables.desktop() {
      td:first-child {
        padding-inline: 1.5rem 0;
      }

      td:last-child {
        padding-inline: 0 1.5rem;
      }
    }

    td {
      &.expanded {
        padding: 1.5rem;
        padding-top: 0;
      }
    }
  }

  tbody tr:first-child td:first-child {
    border-top-left-radius: variables.$radius-xlarge;
  }
  tbody tr:first-child td:last-child {
    border-top-right-radius: variables.$radius-xlarge;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: variables.$radius-xlarge;
  }
  tbody tr:last-child td:last-child {
    border-bottom-right-radius: variables.$radius-xlarge;
  }

  &.inactive {
    tbody tr td {
      color: var(--disabled) !important;
    }
  }
}

.expandableRowContent {
  padding: 0 !important;

  .expandableRowIcon {
    border-radius: variables.$radius-small;
    border: 1px solid var(--contrast-low);
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    cursor: pointer;
  }
}

.subTableRowContent {
  .subTableRowIcon {
    height: 30px;
    width: 2px;
    float: left;
  }

  .subTableRowLastIcon {
    width: 12px;
    height: 15px;
  }
}

.table.tableSeparate {
  border-spacing: 0 0.5rem;

  tbody tr {
    td {
      &:first-child {
        border-radius: variables.$radius-xlarge 0 0 variables.$radius-xlarge;
      }

      &:last-child {
        border-radius: 0 variables.$radius-xlarge variables.$radius-xlarge 0;
      }
    }
  }
}

.tableBodyMargined {
  &:before {
    line-height: 1em;
    content: '\200C';
    display: block;
  }
}

.table {
  .tbody.tableBodySeparate::after {
    display: block;
    content: '';
    height: 0.5rem;
  }
}

[dir='rtl'] .table tbody tr td:first-child {
  border-radius: 0 variables.$radius-xlarge variables.$radius-xlarge 0;
}

[dir='rtl'] .table tbody tr td:last-child {
  border-radius: variables.$radius-xlarge 0 0 variables.$radius-xlarge;
}

[dir='rtl'] .table th,
[dir='rtl'] {
  text-align: right !important;
}
