@use '../../../layout/variables';

.snackbar {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: var(--text-inverse);
  border-radius: variables.$radius-large;
  background: var(--contrast-max);
  padding: 0.5rem 0.75rem;
  min-height: 48px;
  max-width: 520px;
  min-width: 200px;

  @include variables.mobile() {
    width: 100%;
  }

  .leftWrapper {
    display: flex;
    gap: 0.75rem;
    align-items: center;
  }
}

.snackbarContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: map-get(variables.$zIndex, popUp);
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;

  @include variables.mobile() {
    bottom: initial;
    top: 1.5rem;
    right: 1.5rem;
  }
}
