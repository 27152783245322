@use '../../layout/variables';
@use '../../ui/styles/typography';

.dropdownList {
  position: absolute;
  display: flex;
  inset-inline-end: 0;
  min-width: 11rem;
  padding: 0.5rem;
  flex-direction: column;
  z-index: map-get(variables.$zIndex, selectFieldDropdown);
  align-items: flex-start;
  gap: 0.25rem;
  border-radius: variables.$radius-xlarge;
  border: 1px solid var(--contrast);
  background: var(--contrast-min);

  box-shadow: 0rem 0.313rem 0.625rem 0rem rgba(0, 0, 0, 0.05),
    0rem 0.125rem 2.5rem 0px rgba(0, 0, 0, 0.04);

  &.bottom {
    top: 100%;
    margin-top: 1.25rem;
  }

  &.top {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
}
