@use '../../ui/styles/typography';
@use '../../layout/variables';

.tableExpandedRow {
  cursor: pointer;
}

.subscriptionsTable {
  table-layout: fixed;
  min-width: auto;

  .isNotExpandedRow {
    td:last-child {
      background-color: var(--background-secondary);
    }
  }
  td {
    background-color: var(--background-secondary);
    border: none;

    &.transparent {
      background-color: transparent;
    }
  }

  .headerTextStyles {
    color: var(--text-secondary);
    font-weight: variables.$font-weight-medium;
  }
}

.titleWrapper {
  max-width: 290px;
}

.box {
  background-color: var(--background-secondary);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  @include variables.mobile() {
    padding: 1rem;
  }
}

.content {
  background: var(--background);
  border-radius: variables.$radius-medium;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  margin: 1rem 0;
  padding: 1.5rem 1.5rem;
}

.table {
  border-collapse: separate;
  border-style: hidden;
  background-color: transparent;
  border-spacing: 0;

  thead,
  .thead {
    th {
      @include typography.textTiny;
      padding: 16px 0 8px 24px;
      color: var(--text-secondary) !important;
    }
  }
}

.row {
  td {
    @include typography.textSmall;
    color: var(--text);
    width: 100%;
    padding: 0.5rem 0;
  }
}

.text {
  color: var(--text) !important;
}

.buttonWidth {
  max-width: 350px;
}
