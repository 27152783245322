@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.textStrong {
  @include typography.textStrong;
}
.box {
  width: 100%;
  background: var(--background-secondary);
  box-shadow: 0px 0.5rem 3.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  flex-grow: 1;
}
