@import '../../../../layout/variables';
@import '../../../../ui/styles/colors';
@import '../../../../ui/styles/typography';

.progressBarsBlock {
  width: 100%;

  .progressBarBlock {
    width: 100%;
    margin-top: 1rem;

    .progressBarBlockHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;

      .progressBarBlockTitle {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    .progressBarBlockProgress {
      width: 100%;
      display: flex;
      gap: 0.5rem;

      .progressBarSection {
        min-width: 0;
        flex: 1;

        .progressBarLabelWrapper {
          display: flex;
          justify-content: center;

          .progressBarLabel {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
