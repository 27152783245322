@use '../../ui/styles/typography';
@use '../../layout/variables';

.modalBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 0.75rem;
  background: var(--contrast-hint);
}

.closeButton {
  width: 24px;
  height: 24px;
}
