.iconButton {
  > svg {
    fill: #121212;
  }
}

.body {
  border-radius: 0 !important;
  margin-inline-start: 0 !important;
}
