@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  padding: 3rem 0;
  .subtitle {
    margin-top: 1.5rem;
    color: var(--text-secondary, #868686);
  }
}

.disclaimer {
  display: flex;
  flex-direction: column;
  margin: 1.5rem auto 0;
  max-width: 75%;
  gap: 0.5rem;
  @include variables.mobile() {
    margin: 1.5rem 0 0;
    max-width: fit-content;
  }
}

.disclaimerText {
  text-align: center;
  color: var(--text-secondary);
}
