.messagesWrapper {
  margin: 2rem 0;

  .messageItem {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    font-size: 0.75rem;
    margin: 0.5rem 0;

    p {
      width: 100%;
      margin-bottom: 0.375rem;
      padding: 0 1.25rem 0.125rem 0;
    }

    &.messageHidden {
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
        font-size: 0.75rem;
        padding: 0 1.25rem 0.125rem 0;
      }
    }
  }
}
