@use '../../layout/variables';
@use '../../ui/styles/typography';

.info {
  @include typography.text;
  span:first-child {
    color: var(--error);
    text-transform: uppercase;
  }
  padding-bottom: 1.5rem;
}
.formWithCurrency {
  display: flex;
  :global(.field):first-child {
    flex-grow: 1;
    margin-inline-end: 0.5rem;
  }
  :global(.field):last-child {
    width: 100px;
  }
}
