@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.form {
  h3 {
    padding-top: 1.5rem;
  }

  .row {
    display: flex;

    div:first-child {
      flex: 1;
      margin-inline-end: 0.25rem;

      @include variables.mobile() {
        flex: 2;
      }
    }

    div:last-child {
      flex: 1;
    }
  }

  .rowPhone {
    display: flex;

    div:first-child {
      flex: 2;
      margin-inline-end: 0.25rem;

      @include variables.mobile() {
        flex: 3;
      }
    }

    div:last-child {
      flex: 3;
    }
  }

  :global(.field) {
    position: relative;

    > label > span {
      position: absolute;
      top: 18px;
      right: 0;
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5rem auto 0 auto;
    padding: 0 120px;
  }

  .row {
    display: flex;
  }
}

.datePicker {
  z-index: 11;
  height: 3.5rem;
  border-radius: variables.$radius-medium;
  resize: none;
  border: 1px solid var(--contrast-medium-low);
}

.confirmationWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0;
  line-height: 1.5rem;
  margin: auto;

  .verification {
    text-align: center;
    padding-top: 1.5rem;
  }

  .buttonWrapper {
    padding-top: 1.5rem;
  }
}

:global(.react-datepicker-popper) {
  z-index: 11 !important;
}

.plainButton {
  @include typography.text;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--text-interactive);
}

.modal {
  margin-top: 2rem;
}

input[type='checkbox'] {
  margin-inline-end: 0.5rem;
}

.infoCard {
  svg {
    margin-left: 0.25rem;
  }
}
