@use '../../ui/styles/typography';
@use '../../layout/variables';

.documentsPending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1rem;
  text-align: center;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;

  @include variables.mobile() {
    width: 100%;
  }

  .subtitle {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .text {
    margin-bottom: 0.5rem;
    max-width: 800px;
  }
}
