@use '../../ui/styles/typography';
@use '../../layout/variables';

.card {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-large;
  padding: 3rem;
  box-shadow: 0px 0.5rem variables.$size-1 rgba(0, 0, 0, 0.04);
  gap: 1rem;

  @include variables.mobile() {
    padding: 3rem 1.5rem;
  }

  .title {
    color: var(--text);
    text-transform: capitalize;
  }

  .text {
    color: var(--text-secondary);
    text-align: center;
    flex-grow: 1;
  }

  .startButton,
  .startButtonLink {
    width: 100%;
  }
}
