@use '../../layout/variables';
@use '../../ui/styles/typography';

@mixin badge($color, $border-color) {
  @include typography.textTiny;
  display: inline-flex;
  box-sizing: border-box;
  text-transform: capitalize;
  padding: 0.25rem 0.5rem;
  color: $color;
  border: 1px solid $border-color;
  border-radius: variables.$radius-small;
}

.badge:not(.isNew) {
  &.primary {
    @include badge(var(--primary), var(--primary));
  }

  &.secondary {
    @include badge(var(--contrast-low), var(--contrast-low));
  }

  &.tertiary {
    @include badge(var(--text-interactive), var(--text-interactive));
  }

  &.active,
  &.approved,
  &.success {
    @include badge(var(--success), var(--success));
  }

  &.successNew,
  &.surfaceSuccess {
    @include badge(var(--success-new), var(--success-new));
  }

  &.surfaceSuccess {
    @include typography.textSmallStrong;
    background-color: variables.$surface-success;
    border: 0;
  }

  &.pending,
  &.warning {
    @include badge(var(--warning), var(--warning));
  }

  &.cancelled,
  &.declined,
  &.failed,
  &.rejected,
  &.reversed,
  &.danger {
    @include badge(var(--error), var(--error));
  }

  &.new {
    @include badge(var(--primary), var(--primary));
    text-transform: uppercase;
  }

  &.completed,
  &.ended,
  &.expired,
  &.light {
    @include badge(var(--disabled), var(--disabled));
  }

  &.demo {
    display: inline-flex;
    box-sizing: border-box;
    text-transform: capitalize;

    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;

    color: variables.$white;
    background-color: variables.$blue;
    border: none;
    border-radius: variables.$radius-small;
  }

  &.live {
    display: inline-flex;
    box-sizing: border-box;
    text-transform: uppercase;

    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;

    color: variables.$white;
    background-color: var(--primary);
    border: none;
    border-radius: variables.$radius-small;
  }

  &.inactive {
    display: inline-flex;
    box-sizing: border-box;
    text-transform: uppercase;

    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    line-height: 1rem;

    color: variables.$white;
    background-color: variables.$disabled;
    border: none;
    border-radius: variables.$radius-small;
  }
}

.badge.isNew {
  @include typography.textSmall;

  display: inline-flex;
  padding: 0.375rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2rem;
  color: var(--foreground-white);
  white-space: nowrap;

  &.hasStartIcon {
    padding: 0.375rem 1rem 0.375rem 0.5rem;
  }

  &.hasEndIcon {
    padding: 0.375rem 0.5rem 0.375rem 1rem;
  }

  &.clickable {
    cursor: pointer;
  }

  &.green {
    background-color: var(--badge-success);
  }

  &.blue {
    background-color: var(--badge-blue);
  }

  &.red {
    background-color: var(--badge-red);
  }

  &.yellow {
    background-color: var(--badge-yellow);
  }

  &.black {
    background-color: var(--badge-black);
  }
}
