@use '../../layout/variables';
@use '../../ui/styles/typography';

.ibSignupPage {
  padding: 3rem;
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 3.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include variables.mobile() {
    padding: 1.5rem;
  }

  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .title {
      @include variables.mobile {
        @include typography.textStrong;
      }
    }

    .clickable {
      @include typography.textSmall;
      color: var(--text-secondary);
      cursor: pointer;
    }
  }

  .wrapper {
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;

    @include variables.mobile() {
      flex-direction: column;
    }

    .formInputWrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @include variables.mobile() {
        width: 100%;
      }

      p {
        padding: 0.5rem 0;
        width: fit-content;
      }
    }

    .carouselWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--background);
      border-radius: variables.$radius-large;
      width: 50%;
      padding: 2.5rem 0;

      @include variables.mobile() {
        width: 100%;
        padding: 0.625rem 0;
        margin: 1rem 0;
      }
    }
  }
}

.feedbackWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55%;
  margin: 0 auto;
  gap: 1.5rem;

  @include variables.mobile {
    width: 100%;
  }

  .header {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
  }

  .codeField {
    border: 1px solid var(--contrast-low);
    background-color: var(--background);
    padding: 0.5rem 1rem;
    border-radius: variables.$radius-medium;
    width: 100%;

    .codeWrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .submitButton {
    max-width: 240px;
    margin-top: 1rem;

    @include variables.mobile() {
      @include typography.textSmallStrong;
      height: 48px;
      max-width: 100%;
    }
  }
}

.earnModal {
  display: flex;

  @include variables.mobile() {
    flex-direction: column;
  }

  .item {
    flex-basis: 50%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;

    p {
      width: fit-content;
    }

    h3 {
      margin-bottom: 0.5rem;
    }

    a {
      margin-top: 1.5rem;
      text-decoration: underline;
      width: fit-content;
    }
  }
}

.loadingWrapper {
  display: flex;
  width: 100%;
  min-width: 100px;
  min-height: 10rem;
  justify-content: center;
  align-items: center;
}
