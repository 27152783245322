@use '../../../layout/variables';

.customTitle {
  text-align: left !important;
  padding-inline-start: 2.5rem;

  @include variables.mobile() {
    text-align: center !important;
  }
}

[dir='rtl'] .customTitle {
  text-align: right !important;
}
