.infoNumberIcon {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoNumberCircle {
  font-size: 14px;
  color: #ffffff;
  width: 20px;
  height: 20px;
  background-color: var(--text-interactive);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Corrects alignment for digit '4' */
.correction {
  padding-right: 1px;
}
