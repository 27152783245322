@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  padding: 5rem 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin: 2rem 0;
  }

  :global(.button) {
    padding: 0 6rem;
  }
}
