@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.dateFilter {
  white-space: nowrap;
  flex: 1;

  .tabs {
    display: flex;

    .tab {
      margin: 0 !important;
      flex: 1;
      justify-content: flex-start !important;

      .customLabel {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .calendarPopup {
        right: -2.44rem;
        top: -1.25rem;
      }
    }
  }
}
