@use '../../ui/styles/typography';

.text {
  @include typography.text;
}

.radio {
  margin-inline-end: 1rem;
}

a,
.link {
  color: var(--text-interactive);
  text-decoration: none;

  &:hover {
    color: var(--text-interactive);
  }
}

.cardWrapper {
  padding: 1rem !important;
}

.bonusItemsWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  max-height: 270px;
  overflow-y: scroll;

  .bonusItems {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid var(--contrast-low);
    padding: 1.25rem 1.5rem 1rem 1.5rem;
  }
}
