@use '../layout/variables';
@use '../ui/styles/_typography.scss';

.backgroundIcon {
  position: absolute;
}

.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
}

.activatedBackgroundIconLeft {
  top: 60px;
  left: -324px;
}

.activatedBackgroundIconRight {
  top: 128px;
  right: -654px;
}

.walletActionButton {
  border-radius: variables.$radius-large;
  padding: 0.5rem 0.75rem;
}

.textAlignEnd {
  text-align: end;
}

.content {
  max-width: 52rem !important;

  .info {
    text-align: center;
    margin-bottom: 1.125rem;
    padding: 0 1.5rem;
    @include variables.mobile() {
      padding: 0;
    }
  }

  .semiTransparent {
    opacity: 56%;
  }
}

.formTitle {
  @include typography.h2;
}

.depositMethod {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;
  background-color: var(--background);
  padding: 1.25rem;
  border-radius: variables.$radius-large;
  overflow-wrap: anywhere;

  .logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    margin-inline-end: 0.625rem;
    background-color: var(--always-white);
    padding: 0.5rem;
    border-radius: variables.$radius-small;
  }
}

.depositMethodWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.625rem;

  @include variables.mobile() {
    grid-template-columns: 1fr;
  }
}
