@use '../../ui/styles/typography';
@use '../../layout/variables';

.banner {
  margin-top: -1px;
  width: 100%;
  background: var(--text);

  .body {
    color: var(--background-secondary);
  }

  strong,
  a {
    text-decoration: none;
    color: var(--background-secondary) !important;
  }

  :global(.container) {
    padding: 1rem;
  }

  :global(.level-right) {
    padding-inline-start: 2rem;
  }

  .acceptButton {
    @include typography.textSmallStrong;
    background: var(--contrast-high);
    border-radius: variables.$radius-full;
    color: var(--background-secondary);
  }
}
