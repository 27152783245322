@use '../../../layout/variables';

.customTitle {
  text-align: start !important;
  padding-inline-start: 2.5rem;

  @include variables.mobile() {
    text-align: center !important;
  }
}
