@use '../../ui/styles/typography';
@use '../../layout/variables';

.body {
  @include typography.text;
  color: var(--text);
  background-color: var(--background-secondary);
  line-height: 1.5rem;

  .action {
    display: flex;

    button {
      width: 100%;
    }
  }
}

.footer {
  background-color: var(--background-secondary);
  button {
    color: var(--text);
  }
  @include variables.mobile() {
    @include typography.textSmall;
  }
}
