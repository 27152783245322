@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.bottomSelectorsWrapper {
  display: flex;
  margin-top: 0.5rem;

  :global(.column) {
    padding-inline-start: 0 !important;
  }
}

.bottomSelector {
  flex: 4;
  margin-right: 1rem;
}

.createButton {
  flex: 2;
  margin-left: 2.5rem;
}

.qrCodeCard {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  gap: 1.5rem;
  margin-top: 2.5rem;
  border-radius: 1rem;
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 2.5rem 0 rgba(0, 0, 0, 0.04);
  max-width: 15rem;

  .qrCodeCardChips {
    display: flex;
    gap: 1rem;
  }
}

@include variables.mobile() {
  .bottomSelectorsWrapper {
    flex-direction: column;
  }

  .bottomSelector {
    flex: unset;
    margin-right: 0;
    margin-top: 1rem;
  }

  .createButton {
    flex: unset;
    margin-left: 0;
    margin-top: 2.5rem;
  }

  .qrCodeCard {
    margin: 2.5rem auto 0;
  }
}

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
}

.hasVerticalMargin {
  @include variables.mobile() {
    margin: 2rem 0 1.5rem 0;
  }
}
