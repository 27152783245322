@use '../../layout/variables';

@mixin h1 {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0;
  font-weight: variables.$font-weight-regular;

  @include variables.mobile() {
    font-size: 32px;
  }
}

@mixin h2 {
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.01rem;
  font-weight: variables.$font-weight-semi-bold;

  @include variables.mobile() {
    font-size: 26px;
    line-height: 32px;
  }
}

@mixin h3 {
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: variables.$font-weight-semi-bold;

  @include variables.mobile() {
    font-size: 20px;
  }
}

@mixin h4 {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: variables.$font-weight-medium;

  @include variables.mobile() {
    font-size: 18px;
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

@mixin textHero {
  font-size: 40px;
  line-height: 40px;
  letter-spacing: 0;
  font-weight: variables.$font-weight-bold;

  @include variables.mobile() {
    font-size: 32px;
  }
}

@mixin textLarge {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01rem;
  font-weight: variables.$font-weight-regular;
}

@mixin textLargeStrong {
  @include textLarge;
  letter-spacing: 0;
  font-weight: variables.$font-weight-semi-bold;
}

@mixin text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02rem;
  font-weight: variables.$font-weight-regular;
}

@mixin textStrong {
  @include text;
  letter-spacing: 0;
  font-weight: variables.$font-weight-semi-bold;
}

@mixin textSmall {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02rem;
  font-weight: variables.$font-weight-regular;
}

@mixin textSmallStrong {
  @include textSmall;
  letter-spacing: 0.01rem;
  font-weight: variables.$font-weight-semi-bold;
}

@mixin textTiny {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02rem;
  font-weight: variables.$font-weight-medium;
}

@mixin textTinyStrong {
  @include textTiny;
  font-weight: variables.$font-weight-semi-bold !important;
}

@mixin textMicro {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.02rem;
  font-weight: variables.$font-weight-medium;
}

@mixin textTrimShort {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15ch;
}

@mixin textTrimMid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20ch;
}

@mixin textTrimLong {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
}

@mixin inherit {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: inherit;
}

@mixin textTruncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
