@use '../../ui/styles/typography';
@use '../../layout/variables';

.field {
  position: relative;

  :global(label) {
    z-index: 10;
    top: 8px;
    left: 12px;
    position: absolute;
    color: var(--text-secondary);

    &[dir='rtl'] {
      left: auto;
      right: 12px;
    }
  }

  .hideIcon {
    padding: 1rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;

    &[dir='rtl'] {
      right: auto;
      left: 0;
    }
  }

  .input {
    color: var(--text);
    height: 3.5rem;
    border-radius: variables.$radius-medium;
    padding-inline-end: 3rem;
    resize: none;
    border-color: var(--contrast-medium-low);
    background-color: var(--background-secondary);

    &[dir='rtl'] {
      padding-inline-start: 2.5rem;
      padding-inline-end: calc(0.75em - 1px);
    }

    &.inputError {
      border: 2px solid var(--error);
      text-indent: -1px;
    }
  }

  &:has(:global(label)) {
    .input {
      padding-top: 20px;
    }
  }

  :global(input::placeholder) {
    color: var(--text-secondary);
  }

  :global(input::placeholder) {
    color: var(--text-secondary);
  }

  .errorMessage {
    margin-top: 8px;
    text-indent: 12px;
    color: var(--error);
  }

  .label {
    @include typography.textTiny;
  }
}
