@use '../../ui/styles/typography';
@use '../../layout/variables';

.marketDataClassificattion {
  border-radius: variables.$radius-xlarge;
  background-color: var(--background-secondary);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  padding: 1.5rem;
  color: var(--text);
  margin-top: 1rem;

  .statusValue {
    color: var(--success-new);
  }
}
