.modal {
  .body {
    background-color: var(--background);

    .option {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      padding-inline-start: 1.5rem;
      border-bottom: 1px solid var(--contrast-low);
    }
  }
}
