@use '../layout/variables';

.notifications {
  .backButton {
    padding-inline-start: 0;
    padding-top: 2rem;

    @include variables.mobile() {
      display: none;
    }
  }

  .header {
    margin-top: 3rem;
    margin-bottom: 1rem;
    @include variables.mobile() {
      margin-top: 0;
    }
  }

  .readAll {
    display: none;

    @include variables.mobile() {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 0.75rem;
    }
  }

  .notificationsNotFound {
    display: flex;
    margin-top: 5rem;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .notificationsNotFoundTitle {
      margin-top: 1.5rem;
      margin-bottom: 2.5rem;
    }

    .goBackButton {
      min-width: 120px;
    }
  }

  .removing {
    max-height: 0;
    padding: 0;
    border: none;
  }
}
