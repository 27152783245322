@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  .round {
    @include typography.textSmallStrong;
    color: var(--text);
  }

  .button {
    width: 100%;
  }

  .icon {
    span {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .title {
    width: 100%;
  }

  .subtitle {
    @include typography.textSmall;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 0.5rem;
  }
}

.resultCurrentRanking {
  background: linear-gradient(127.56deg, var(--primary-focused) 0%, var(--primary) 95.22%);

  div,
  span {
    color: var(--text-on-primary-color) !important;
  }
}
