@use '../../ui/styles/typography';
@use '../../layout/variables';

:global(.modal-card-head) {
  border-color: var(--contrast-low);
}

.body {
  @include typography.text;
  color: var(--text);
  background-color: var(--background-secondary);
  border-radius: 0 !important;
  margin: 0 !important;
  text-wrap: initial;

  .action {
    display: flex;

    button {
      width: 100%;
    }
  }

  :global(.progress) {
    &::-webkit-progress-value {
      transition: width 0.5s ease;
    }
  }
}

.footer {
  background-color: var(--background-secondary);
  button {
    color: var(--text);
    margin: 0 !important;
  }
  @include variables.mobile() {
    @include typography.textSmall;
  }
}
