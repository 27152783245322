@use '../../ui/styles/typography';
@use '../../layout/variables';

.text {
  @include typography.text;
}

.referralCodeWrapper {
  display: flex;
  width: 100%;

  &.partners {
    justify-content: flex-end;

    @include variables.mobile {
      min-width: initial;
    }
  }

  .referralCode,
  .referralCodePartners {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;

    @include variables.mobile {
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      padding: 0;
      width: 100%;
      gap: 0;
    }

    .referralLabel {
      color: var(--text-secondary);
    }

    .referralCodesBox {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      @include variables.mobile() {
        align-items: flex-start;
        flex-direction: row;
      }

      .referralValue {
        color: var(--text);
      }

      .splitter {
        @include typography.h4;
        color: var(--text);

        @include variables.mobile {
          display: none;
        }
      }
    }
  }

  .referralCode {
    @include variables.mobile() {
      align-items: center;
      gap: 0.25rem;
    }

    .referralLabel {
      @include variables.mobile {
        @include typography.textTiny;
      }
    }

    .referralCodesBox {
      @include variables.mobile() {
        align-items: flex-start;
        flex-direction: row;
        gap: 0.25rem;
      }

      .referralValue {
        background-color: var(--background-secondary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: variables.$radius-small-medium;
        padding: 0.125rem 0.5rem 2px 0.5rem;
        gap: 0.25rem;
      }
    }
  }

  .referralCodePartners {
    .referralLabel {
      &.mobile {
        display: none;
      }

      @include variables.mobile() {
        display: none;

        &.mobile {
          display: block;
        }
      }
    }

    .referralCodesBox {
      @include variables.mobile() {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
      }

      .referralValue {
        @include variables.mobile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          gap: 1rem;
        }
      }
    }
  }
}
