.backButton {
  padding: 1rem;
  display: flex;
  align-items: center;
}

.platformTypes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.footer {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;

  gap: 1rem;
}
