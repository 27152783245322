@use '../../layout/variables';

.box {
  background-color: var(--background-secondary);
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  flex-wrap: wrap;
}

.boxLeft {
  display: flex;
  align-items: center;
  height: 100%;
}

.boxRight {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  min-height: 23rem;
  border-radius: variables.$radius-xlarge;
}
