@import '../ui/styles/colors';

$icon-tertiary: $black-400;
$icon-tertiary-dark: $black-300;
$icon-secondary: $black-600;
$icon-secondary-dark: $black-50;
$icon-disabled: $black-300;
$icon-disabled-dark: $black-500;

:root {
  --text-color: var(--text);
  --logo-custom-color: var(--primary);
  --logo-text-color: var(--text);
  --logo-text-inverse-color: var(--text-inverse);
  --icon-tertiary: #{$icon-tertiary};
  --icon-secondary: #{$icon-secondary};
  --icon-disabled: #{$icon-disabled};
}

[data-theme='dark'] {
  --logo-custom-color: var(--primary);
  --logo-text-color: var(--text);
  --logo-text-inverse-color: var(--text-inverse);
  --icon-tertiary: #{$icon-tertiary-dark};
  --icon-secondary: #{$icon-secondary-dark};
  --icon-disabled: #{$icon-disabled-dark};
}

[data-product='etd'] {
  --logo-custom-color: var(--always-white);
  --logo-text-color: var(--always-white);
  --logo-text-inverse-color: var(--text-inverse);
}

.normal {
  fill: var(--text);
}

:export {
  white: var(--contrast-min);
  text: var(--text);
  textInverse: var(--text-inverse);
  error: var(--error);
  warning: var(--warning);
  disabled: var(--disabled);
  success: var(--success);
  successNew: var(--success-new);
  textSecondary: var(--text-secondary);
  contrastLow: var(--contrast-low);
  contrastMedium: var(--contrast-medium);
  contrastMediumLow: var(--contrast-medium-low);
  contrastHigh: var(--contrast-high);
  primary: var(--primary);
  background: var(--background);
  backgroundSecondary: var(--background-secondary);
  textInteractive: var(--text-interactive);
  textOnPrimaryColor: var(--text-on-primary-color);
  contrastMedium: var(--contrast-medium);
  primaryFocused: var(--primary-focused);
  alwaysWhite: var(--always-white);
  iconTertiary: var(--icon-tertiary);
  iconSecondary: var(--icon-secondary);
  iconDisabled: var(--icon-disabled);
  foregroundSecondary: var(--foreground-secondary);
  foregroundDisabled: var(--foreground-disabled);
  foregroundAccent: var(--foreground-accent);
  foregroundNeutral: var(--foreground-neutral);
}

.themeStroke {
  &:not(.inverse) {
    stroke: var(--text);
  }

  &.inverse {
    stroke: var(--text-inverse);
  }
}

.btn {
  cursor: pointer;
}

.themeFill {
  &:not(.inverse) {
    fill: var(--text);
  }

  &.inverse {
    fill: var(--text-inverse);
  }
}
