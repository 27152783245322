@use '../../layout/variables';

.alert {
  display: flex;
  width: 100%;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-radius: variables.$radius-xlarge;
  border: 1px solid var(--warning);
  background: var(--warning-bg);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);

  @include variables.mobile() {
    border-color: transparent;
    border-radius: 0;
    padding: 0.5rem 1rem;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 0.625rem;
    justify-content: flex-start;

    .close {
      padding: 5px;
    }

    @include variables.mobile() {
      gap: 1rem;
      align-items: flex-start;
    }

    .text {
      display: flex;
      flex-direction: column;
    }
  }
}
