.platformTypes {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 1rem;
}

.modalCard {
  &#{&} {
    max-width: 560px;
  }
}
