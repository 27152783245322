@use '../../layout/variables';

.infoContent {
  display: flex;
  min-width: 25rem;
  max-inline-size: 25rem;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  position: absolute;
  z-index: map-get(variables.$zIndex, popUp);
  inset-inline-start: 50%;
  transform: translateX(-50%);

  border-radius: variables.$radius-large;
  background: var(--contrast-hint);
  box-shadow: 0px 0.313rem 0.625rem 0px rgba(23, 31, 39, 0.05),
    0px 2px 2.5rem 0px rgba(23, 31, 39, 0.04);
}
