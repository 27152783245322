@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  margin: 1rem 0;
  padding: 1.5rem 1.5rem;
  background: var(--background);
  border-radius: variables.$radius-medium;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
}
.form {
  color: var(--text);
  :global(.field) {
    position: relative;

    > svg {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }

  .datePicker {
    height: 3.5rem;
    border-radius: variables.$radius-medium;
    resize: none;
    border: 1px solid var(--contrast-medium-low);
  }

  .confirmation {
    background-color: var(--background);
    border-radius: variables.$radius-medium;
    padding: 1.5rem;
    border: 1px solid var(--contrast-medium-low);
    margin-bottom: 1.5rem;

    @include variables.mobile() {
      margin-bottom: 1rem;
    }

    ol {
      padding-inline-start: 2.5rem;
      list-style: circle;

      li {
        margin: 1rem 0;
      }
    }
  }
  h3 {
    margin-bottom: 1.5rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    gap: 1.5rem 1rem;

    .input {
      flex: 1 1;
    }

    .individualInput {
      flex: 1 1 50%;
      max-width: calc(50% - 0.5rem);
    }

    .checkBox {
      flex-basis: 100%;

      span {
        align-self: center;
      }
    }
  }

  .action {
    display: flex;
    justify-content: center;
    margin: 2rem 0;

    :global(.button) {
      padding: 0 6rem;
    }
  }
  @include variables.mobile() {
    .row {
      flex-direction: column;
      margin: 0;
      gap: 0;

      .input,
      .individualInput {
        width: 100%;
        max-width: 100%;
        margin: 0.5rem 0 !important;
      }

      .checkBox {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.labelBox {
  margin: 3rem 0 2rem 0;
}

.text {
  @include typography.text;
}
