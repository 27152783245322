@use '../../ui/styles/typography';
@use '../../layout/variables';

.body {
  @include typography.text;
  color: var(--text);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include variables.mobile() {
    flex-direction: column;
  }

  .item {
    flex-basis: 33.3333333333%;
    padding: 0.5rem;

    h3 {
      margin-bottom: 0.5rem;
    }
  }
  .list {
    list-style: inside;
    color: var(--primary);

    .listItem {
      @include typography.textLarge;
      padding-top: 0.5rem;
      @include typography.textSmall;
      .listItemText {
        color: var(--text);
      }
    }
  }
}
