@import '../../../layout/variables';
@import '../../styles/colors';
@import '../../styles/typography';

.progressBar {
  width: 100%;
  display: flex;
  gap: 0.25rem;

  .progressBarSection {
    width: 100%;
    height: 1rem;
    overflow: hidden;
    border-radius: 0.5rem;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }

    &.red {
      &::after {
        background: var(--progress-bar-primary);
      }
    }

    &.blue {
      &::after {
        background: var(--progress-bar-blue);
      }
    }

    &.isStroked {
      &::after {
        background: repeating-linear-gradient(
          135deg,
          var(--progress-bar-secondary),
          var(--progress-bar-secondary) 5px,
          transparent 5px,
          transparent 10px
        );
      }
    }
  }
}
