@use '../../../ui/styles/typography';
@use '../../../layout/variables';

$calendar-popup-shadow: 0px 5px 20px 0px rgba(11, 15, 25, 0.06);
$calendar-popup-shadow-dark: 0px 5px 20px 0px rgba(11, 15, 25, 0.06);

:root {
  --calendar-popup-shadow: #{$calendar-popup-shadow};
}

[data-theme='dark'] {
  --calendar-popup-shadow: #{$calendar-popup-shadow-dark};
}

.calendarPopupWrapper {
  position: relative;

  .popup {
    position: absolute;
    top: 0;
    transform: translateY(-100%);
    z-index: map-get(variables.$zIndex, popUp);
    background-color: var(--background-modal);
    border-radius: 2rem;
    box-shadow: var(--calendar-popup-shadow);
  }
}

.calendarPopupModalWrapper {
  .calendarPopupModal {
    background-color: var(--background-modal);
    border-radius: 2rem 2rem 0 0;
  }
}

.calendarPopupMobileContent {
  padding-top: 1.5rem;

  .calendarMobileHeader {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--background-divider);
  }

  .calendarMobilePeriod {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid var(--background-divider);
    padding: 1rem 0;
    width: 100%;

    input {
      @include typography.text;

      border: none;
      background-color: var(--background-bg2);
      padding: 0.5rem;
      border-radius: 0.875rem;
      min-width: 0;
      max-width: 6.6875rem;
      text-align: center;

      &:focus-visible {
        outline: none;
      }
    }
  }
}
