@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  color: var(--text);
  gap: 0.5rem;
  margin-top: 1rem;
  display: none;

  @include variables.mobile() {
    display: flex;
  }

  &.spacing {
    padding: 2.5rem;
  }

  &.justIcons {
    background: transparent;
  }

  .title {
    color: var(--text);
    margin-bottom: 1rem;
    width: 100%;
    @include variables.mobile() {
      text-align: center;
    }
  }
}
