@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.formFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title {
    padding: 0.5rem 0;
  }
}

.questionAnswerField {
  background-color: var(--background);
  padding: 1rem;
  border: 1px solid var(--contrast-low);
  border-radius: variables.$radius-large;
  :global(.control) {
    margin-top: 1rem;
  }

  &.radioError {
    border-color: var(--error);
  }

  .radioWidgetWrapper {
    flex-basis: auto;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16rem;
  margin: 2.5rem auto 0 auto;

  @include variables.mobile() {
    padding: 0;
    width: 100%;
    margin-top: 2rem;
  }
}

// TODO Refactor
.modal {
  :global(.modal-card-body) {
    border-radius: 0 !important;
    margin-inline-start: 0 !important;
    margin-top: 0 !important;
  }
}

.confirmationWrapper {
  @include typography.text;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  line-height: 1.5rem;
  background-color: var(--background-secondary);
  margin: 2.5rem auto;
  border-radius: variables.$radius-xlarge;

  .verification {
    text-align: center;
    padding: 1.5rem 1rem 0;
  }

  .buttonWrapper {
    padding-top: 1.5rem;
  }

  @include variables.mobile() {
    margin: 1.5rem;

    a {
      width: 100%;
    }
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  padding: 2rem;
  border: 1px solid var(--contrast-low);
  order: 3;

  @include variables.mobile() {
    padding: 1rem 1rem 1.5rem 1rem;
    margin-inline-start: 0;
    border: none;
    box-shadow: 0 8px 40px rgba(0, 0, 0, 0.04);
    background: var(--background-secondary);

    &.reversed {
      order: 1;
    }
  }
}

.text {
  @include typography.text;
}

.content {
  @include typography.text;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  align-items: center;
  width: 100%;

  @include variables.mobile() {
    row-gap: 1rem;
  }

  h1 {
    padding: 0.5rem 0;
  }

  .description {
    width: 37.5rem;

    @include variables.mobile() {
      width: 100%;
    }
  }
}

.infoSection {
  display: flex;
  align-items: center;
  padding: 3rem;
  flex-direction: column;
  gap: 2.5rem;

  .list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  h1 {
    text-align: center;
  }

  @include variables.mobile() {
    padding: 2rem 0.5rem;
  }
}

.testWrapper {
  display: flex;
  width: 100%;
  justify-content: center;

  .testInfo {
    padding: 1.5rem 0;
    max-width: 38rem;
    gap: 1.5rem;

    @include variables.mobile() {
      padding: 2rem 0.5rem;
      max-width: initial;
    }

    .button {
      margin-top: 1rem;
    }
  }
}

.bullet {
  display: inline-block;
  margin-top: 0.6rem;
  border-radius: 50%;
  padding: 0.1rem;
  background-color: var(--text);
  width: 0.1rem;
  height: 0.1rem;
  margin-inline-end: 0.6rem;
}
