.wrapper {
  padding-top: 0 !important;
  .button {
    border: none;
    background: transparent;
  }

  > div {
    display: flex;
    padding-bottom: 2rem;
    > h3 {
      margin: auto;
    }
  }
}
