@use '../../layout/variables';

.wrapper {
  border-radius: variables.$radius-xlarge;
  overflow: hidden;
  width: 100%;
  background-color: var(--background-secondary);
  min-height: calc(100% - 5rem);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  object-fit: contain;

  .sumsub {
    width: 100%;
    height: 100%;
  }
}

.thankYouPage {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .innerWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 5rem;
    flex: 1;

    @include variables.mobile() {
      flex-direction: column;
      gap: 1.875rem;
    }

    .notifyCard {
      flex-basis: 45%;
      flex-direction: column;
      padding: 1.25rem;
      border-radius: variables.$radius-xlarge;
      margin-top: 9rem;
      background-color: var(--background-secondary);
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

      &.compact {
        margin-top: 0;
        margin-bottom: 0;
      }

      @include variables.mobile() {
        flex-basis: auto;
        margin-top: 3rem;
        margin-bottom: 16rem;
      }

      .notifyCardTitleBox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        gap: 0.625rem;

        .text {
          color: var(--text-secondary);

          @include variables.mobile() {
            text-align: justify;
          }
        }
      }
    }
  }
}
