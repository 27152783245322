@use '../../layout/variables';

.carousel {
  height: auto;

  .carouselChild {
    height: 100%;
    padding: 5px 0;
  }
}

.mobileCarousel {
  display: flex;
  width: 100%;
  flex-direction: row;
  overflow-x: scroll;

  > * {
    width: 90%;
    flex: 0 0 auto;
    margin: 0 0.25rem;
  }
}
