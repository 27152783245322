@use '../../layout/variables';

@mixin chip($color, $border-color) {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  text-transform: capitalize;
  cursor: pointer;

  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
  line-height: 1.5rem;

  color: $color;
  border: 1px solid $border-color;
  border-radius: variables.$radius-small;
}

.light {
  @include chip(var(--text), var(--contrast-low));
  background-color: var(--contrast-low);

  .icon path {
    fill: var(--text);
  }
}

.dark {
  @include chip(var(--text-inverse), var(--contrast-high));
  background-color: var(--contrast-high);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  &.iconRight {
    right: variables.$icon-size;
  }

  &.iconRight > * {
    margin-inline-start: 0.45rem;
  }

  svg path {
    fill: var(--text-inverse);
  }
}
