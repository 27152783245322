@use '../../layout/variables';

.box {
  width: 100%;
  background: var(--background);
  border: 1px solid var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  flex-grow: 1;
}

.boxError {
  border-color: var(--error);
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 0 auto;
  padding: 0 120px;
}

.radioWidgetWrapper {
  flex-basis: auto;
}
