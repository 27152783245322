@use '../../ui/styles/typography';

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;

  .title {
    text-align: center;
    padding-bottom: 1.5rem;
    @include typography.h1;
  }

  .paragraph {
    text-align: center;
    padding-bottom: 1.5rem;
  }

  .button {
    margin-top: 1rem;
    padding: 0 4rem;
  }
}
