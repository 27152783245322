@use '../ui/styles/typography';
@use '../layout/variables';

.backButton {
  margin-top: 2rem;
  padding-inline-start: 0;
  position: relative;
  z-index: 2;

  @include variables.mobile() {
    display: none;
  }
}

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;

  .tabs {
    :global(.button) {
      margin-top: 0;
      margin-bottom: 0;
      color: var(--text);
      text-transform: none;
      @include typography.textSmallStrong;

      &.selected {
        border-bottom: none !important;
        color: yellow;
      }
    }
  }

  @include variables.mobile() {
    display: none;
  }
}

.emptySpace {
  margin-top: 1.5rem;

  @include variables.mobile() {
    margin-top: 0;
  }
}
