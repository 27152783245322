@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  display: flex;
  background: var(--background, #fafafa);
  height: 64px;
  align-items: center;

  .innerWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    &[dir='rtl'] {
      padding: 0.5rem 0.75rem 0.5rem 1rem;
    }
  }

  .leftContent {
    display: flex;
    align-items: center;

    .closeButton {
      margin-right: 0.5rem;

      &[dir='rtl'] {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }

    .tickmillAppIcon {
      width: 48px;
      height: 48px;
      border-radius: variables.$radius-medium;
      overflow: hidden;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .appInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0.5rem;

      &[dir='rtl'] {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      .iconContainer {
        &[dir='rtl'] {
          transform: scaleX(-1);
        }
      }
    }
  }

  .rightContent {
    display: flex;
    align-items: center;

    .link {
      color: var(--text-interactive);
      cursor: pointer;
    }
  }
}
