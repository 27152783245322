.header {
  border-color: var(--contrast-medium-low);
}

.body {
  color: var(--text);
  background-color: var(--background);
  line-height: 1.5rem;
  padding: 0;
  .option {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid var(--contrast-low);
    text-transform: capitalize;
    .sort {
      .sortBy {
        padding: 0 0.5rem;
        cursor: pointer;
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.footer {
  color: var(--text);
  background-color: var(--background);
}
