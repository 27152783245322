@use '../ui/styles/typography';
@use '../layout/variables';

.topWrapper {
  margin-top: 4rem;

  @include variables.mobile() {
    margin-top: 2rem;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    border-radius: variables.$radius-xlarge;

    @include variables.mobile() {
      display: initial;
      min-width: 100%;
    }

    .qrCode {
      padding: 2.5rem 2rem;
      background-color: var(--background);
      border-radius: variables.$radius-xlarge;
      min-width: 593px;
      flex: 1;
    }

    .inner {
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include variables.mobile() {
        padding: 0;
      }

      .passwordChangeTitle {
        margin-bottom: 1.5rem;
      }

      .resetPasswordValidationRulesWrapper {
        margin-top: 0.75rem;
      }

      .helpText {
        max-width: 320px;
        margin-top: 1.5rem;
        margin-bottom: 2.5rem;
        text-align: center;
        color: var(--text-secondary);
      }

      .signup {
        display: flex;
        justify-content: center;
        width: 100%;
        color: var(--text-secondary);
      }
    }
  }
}

.form {
  width: 100%;
  min-width: 20rem;

  @include variables.mobile() {
    min-width: 100%;
  }

  .submitButton {
    margin-top: 0.75rem;
  }

  .buttonBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include variables.mobile() {
      gap: 1rem;
    }
  }

  .field {
    &:not(:last-child) {
      margin-bottom: 2.5rem;
    }
  }
}

.validationPoint {
  @include typography.textTiny;

  .validationPointBox {
    display: flex;
    padding-bottom: 0.5rem;
    align-items: center;

    .iconBox {
      margin-inline-end: 0.25rem;
      display: flex;
    }
  }
}
