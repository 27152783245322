@use '../../../../ui/styles/typography';

.fromToFilter {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .fromTo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .input {
      @include typography.text;
      width: 100px;
      width: 50%;
      margin: 0;
      margin: 0.5rem;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .buttonItem {
    display: inline-block;
  }

  .error {
    color: var(--error);
  }
}
