@use '../../layout/variables';
@use '../../ui/styles/typography';

.documentCards {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .card {
    padding: 0.75rem 1rem;
    background-color: var(--background-secondary);
    border-radius: variables.$radius-large;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;

    .title {
      color: var(--text);
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 0.75rem;

      .badge {
        white-space: normal;
        text-overflow: clip;
      }
    }

    .underTitle {
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: var(--text-secondary);

      span {
        cursor: pointer;
      }

      button span {
        @include typography.textTiny;
      }
    }

    .docType {
      @include typography.textSmall;
      color: var(--text-interactive);
    }

    .button {
      width: 100%;
    }

    .icon {
      span {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .subtitle {
      @include typography.textSmall;
      display: flex;
      justify-content: space-between;

      .secondary {
        color: var(--text-secondary);
      }

      .contrastMax {
        color: var(--contrast-max);
      }

      .trimmedName {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20ch;
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;

      .delete {
        @include typography.textSmall;
        color: var(--error);
        display: flex;
        justify-content: flex-end;
      }

      .iconButton {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;

        &:hover {
          background-color: var(--contrast-low);
        }
      }
    }
  }
}

.error {
  color: var(--error);
}
