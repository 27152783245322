@use '../../layout/variables';

.note {
  display: flex;
  width: 100%;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.625rem;
  justify-content: space-between;
  border-radius: variables.$radius-large;
  background: var(--select-blue);
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
  font-size: 0.75rem;
  line-height: 1rem;

  .content {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    justify-content: flex-start;

    .text {
      display: flex;
      flex-direction: column;
    }
  }
}
