@use '../../../ui/styles/typography';
@use '../../../layout/variables';

$info-card-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05), 0px 2px 40px 0px rgba(0, 0, 0, 0.04);
$info-card-shadow-dark: 0px 5px 4px 0px rgba(0, 0, 0, 0.1),
  0px 4px 20px 0px rgba(255, 255, 255, 0.1);

:root {
  --info-card-shadow: #{$info-card-shadow};
}

[data-theme='dark'] {
  --info-card-shadow: #{$info-card-shadow-dark};
}

.mobileWrapper {
  max-width: 100%;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &.fullWidth {
    width: 100%;
  }
}

.infoCard {
  display: flex;
  max-width: 380px;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: variables.$radius-large;
  background: var(--contrast-hint);
  box-shadow: var(--info-card-shadow);

  @include variables.mobile() {
    max-width: 100%;
    margin: 1rem;
  }

  .mobileTitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobileText {
    width: 100%;
    display: flex;
    gap: 0.75rem;
    justify-content: space-between;
    align-items: flex-start;

    .close {
      padding: 0.313rem;
    }
  }

  .title {
    color: var(--text);
    text-wrap: wrap;
  }

  .text {
    color: var(--text);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    text-wrap: wrap;
  }
}
