@use '../../../layout/variables';
@use '../../../ui/styles/typography';

$anim-duration: 5;
$anim-speed: 0.65;

.loyaltyProgram {
  .buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.87rem;
    flex-wrap: wrap;

    @include variables.mobile {
      flex-direction: column;
      flex-flow: column-reverse;
      flex-wrap: nowrap;
    }

    .button {
      flex: 1;

      @include variables.mobile {
        flex: initial;
      }

      &.claimButtonActive {
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
          animation: shine #{$anim-duration}s ease-in-out infinite;
        }
      }
    }

    .checkButton {
      cursor: default;
      pointer-events: none;
    }
  }
}

@keyframes shine {
  0% {
    left: -100%;
    transition-property: left;
  }
  #{($anim-speed / ($anim-duration + $anim-speed) * 100%)},
  100% {
    left: 100%;
    transition-property: left;
  }
}
