@use '../../ui/styles/typography';
@use '../../layout/variables';

.luckyDrawModal {
  display: flex;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;

  @include variables.mobile() {
    width: auto;
    height: auto;
    max-height: 600px;
  }

  .wheelBox {
    background-color: var(--primary);
    border-start-start-radius: 14px;
    border-end-start-radius: 14px;
    width: 450px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: all ease 0.5s;

    .titleBox {
      display: none;
    }

    &.lightBg {
      background-color: transparent;
      justify-content: space-between;
    }

    @include variables.mobile() {
      height: 600px;
      border-radius: variables.$radius-large;
      max-height: 600px;
      padding: 28px;

      .titleBox {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;

        .title {
          color: var(--text);
          transition: color ease 0.5s;
          margin-bottom: 0;

          &.lightTitle {
            color: var(--text-on-primary-color);
          }
        }

        .logo {
          transform: scale(0.8);

          &.logoLight {
            svg {
              path {
                fill: var(--text-on-primary-color);
              }
            }
          }
        }
      }
    }

    &.mobile {
      width: 0;
      overflow: hidden;
      padding: 0;
      background-color: transparent;

      > div {
        opacity: 0;
      }
    }

    .prizeBoxWrap {
      width: 100%;
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease 0.5s;

      &.miniWrap {
        height: 220px;
      }

      .prizeBox {
        background-image: url('../../images/prize.png');
        background-size: 220px;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        height: 220px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1);
        transition: transform ease 0.5s;

        &.mini {
          transform: scale(0.5);
        }

        @include variables.mobile() {
          height: 280px;
          background-size: 280px;
        }

        .prizeText {
          color: var(--text-on-primary-color);
          max-width: 150px;
          text-align: center;
        }
      }
    }
  }

  .winningsBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .winnings {
      color: var(--text);
      margin-bottom: 15px;

      @include variables.mobile() {
        @include typography.textLargeStrong;
        text-align: center;
      }

      .highlight {
        color: var(--warning);
      }
    }

    @include variables.mobile() {
      .buttonBox {
        .submitButton {
          @include typography.textLarge;
          width: 100%;
          padding: 25px;
        }
      }
    }

    .note {
      color: var(--text-secondary);
      margin-bottom: 15px;

      @include variables.mobile() {
        @include typography.textLargeStrong;
        text-align: center;
      }
    }
  }

  .detailsBox {
    padding: 28px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.mobile {
      display: none;
    }

    .titleBox,
    .note,
    .titleBox {
      display: flex;
      justify-content: space-between;

      @include variables.mobile() {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
      }

      .title {
        color: var(--text);
        margin-bottom: 0;

        @include variables.mobile() {
          @include typography.textHero;
        }
      }

      .logo {
        transform: scale(0.8);
      }
    }

    .note {
      color: var(--text-secondary);

      @include variables.mobile() {
        @include typography.textStrong;
        text-align: center;
      }
    }

    .conditions {
      @include typography.textSmallStrong;
      color: var(--text);
      list-style-type: disc;
      padding-inline-start: 20px;
      margin-bottom: 20px;

      @include variables.mobile() {
        @include typography.textStrong;
        text-align: start;
      }
    }

    .interactiveBox {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include variables.mobile() {
        flex-direction: column;
      }

      .agreeBox {
        @include variables.mobile() {
          margin-inline-start: -5px;
        }

        .agree {
          @include typography.textTiny;
          display: flex;
          align-items: center;

          @include variables.mobile() {
            @include typography.textSmall;

            .checkbox {
              margin-inline-end: 15px;
              height: 20px;
              width: 20px;
            }
          }
        }

        .termsLink {
          color: var(--text-interactive);
          cursor: pointer;

          @include variables.mobile() {
            padding: 0;
          }
        }
      }
    }

    .buttonBox {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include variables.mobile() {
        margin-top: 1rem;
        width: 100%;
        justify-content: space-between;
      }

      .submitButton {
        padding: 15px 20px;

        @include variables.mobile() {
          @include typography.textLarge;
        }
      }
    }
  }

  .rightFlex {
    justify-content: flex-end !important;
  }
}
