@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.stepsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: calc(0.5rem + 2.5%);

  @include variables.mobile() {
    gap: 0;
  }

  .step {
    display: flex;
    align-items: center;

    &.active {
      @include typography.textSmallStrong;
    }
  }

  @include variables.mobile() {
    justify-content: space-around;
  }
}

.stepsBox {
  display: flex;
  flex-direction: column;

  @include variables.mobile() {
    padding: 1rem 0;
  }
}
