@use '../styles/typography';

.iconBlock {
  display: flex;
  flex-direction: column;
  height: 17.5rem;
  justify-content: center;
  width: 100%;
  padding: 0 5%;

  .icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 100%;
      max-width: 116px;
      min-width: 116px;
    }
  }

  .warning {
    margin-top: 2rem;
  }
}
