@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  cursor: pointer;
  color: var(--text);
  background: var(--background-secondary);
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: variables.$radius-xlarge;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);

  @include variables.mobile {
    padding: 0.75rem;
    border-radius: variables.$radius-large;
  }

  .cardImage {
    background: var(--background-light);
    border-radius: variables.$radius-medium;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 1;
    padding: 0 2.25rem;
    min-height: 240px;
    height: 240px;
    max-height: 240px;

    @include variables.mobile {
      padding: 0 1.25rem;
      min-height: 120px;
      height: 120px;
      max-height: 120px;
    }

    img {
      max-height: 100%;
      max-width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
    }
  }

  .title {
    @include variables.mobile() {
      @include typography.textSmallStrong;
    }
  }
}
