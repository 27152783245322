@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.sectionHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.88rem;
  border-bottom: 1px solid var(--background-divider);

  .title {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .iconWrapper {
    margin-right: 0.25rem;

    &.mobileOnly {
      display: none;

      @include variables.mobile {
        display: inline-block;
      }
    }
  }

  .seeMoreButton {
    color: var(--text);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.02rem;
    text-decoration-line: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
