@use '../../../../layout/variables';
@use '../../../../ui/styles/typography';

.wrapper {
  @include typography.text;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0;
  line-height: 1.5rem;
  margin: auto;
  margin-top: 1.5rem;
  text-align: center;

  @include variables.mobile() {
    margin-top: 0;
  }

  > span {
    padding-top: 1rem;
  }

  .buttonWrapper {
    flex-direction: column;
    align-items: center;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;
      padding: 0 5rem;

      @include variables.mobile() {
        padding: 0 2rem;
      }
    }
  }
}
