@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0.5rem;
}

.header {
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem 2rem;
  cursor: pointer;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin header($bg) {
  display: flex;
  align-items: center;
  background-color: $bg;
  padding: 1.5rem 1.5rem;
  border-radius: variables.$radius-xlarge;
  margin: 0.5rem 0;
}

.headerGray {
  @include header(var(--contrast-low));

  .title {
    display: flex;
    align-items: center;
    :first-child {
      margin-inline-end: 0.5rem;
    }
  }
}
