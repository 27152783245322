@use '../../layout/variables';
@use '../../ui/styles/typography';

:root {
  --text-color: var(--text);
}

[data-theme='dark'],
[data-product='etd'] {
  --text-color: var(--always-white);
}

.localeSelector {
  position: relative;
  display: flex;
  justify-content: center;

  @include variables.mobile() {
    padding: 1rem 0;
  }

  .selectedLanguage {
    display: flex;
    align-items: center;
    cursor: pointer;

    .labelBox {
      display: flex;
      align-items: center;
      color: var(--text-color);
      gap: 0.5rem;

      &.isMobile {
        color: var(--text);
      }

      .languageArrowBox {
        display: flex;
      }
    }

    .arrow {
      display: flex;

      svg path {
        fill: var(--text-color);
      }

      &.isMobile {
        svg path {
          fill: var(--text);
        }
      }
    }
  }
}

.countryFlag {
  border-radius: 50%;
  object-fit: cover;
  height: 24px !important;
  width: 24px !important;
}

.closeBox {
  padding: 1rem 0.15rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 1rem;
  transition: box-shadow 0.1s ease-in-out;

  &.showShadow {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

.variantsScroller {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  direction: initial;

  @include variables.mobile() {
    padding: 1rem;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    height: 100%;
    max-height: initial;
    align-items: start;
    align-content: start;
    overflow-y: scroll;
    padding-bottom: 8rem;

    &.isArabic {
      direction: rtl;

      .option {
        direction: ltr;
      }
    }
  }

  .option {
    display: flex;
    color: var(--text);
    padding: 0.5rem 1rem;
    border-radius: variables.$radius-small;
    border: 1px solid transparent;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    &:hover {
      background-color: var(--contrast-low);
    }

    &.isSelected {
      border-radius: var(--xs, 4px);
      border-color: var(--text-interactive);
      background: var(--surface-information);
      color: var(--text-interactive);

      .checkIcon {
        opacity: 1;
      }
    }

    .labelWrapper {
      display: flex;
      align-items: center;
      gap: 1rem;
      min-width: 148px;
      flex-wrap: nowrap;
      text-wrap: nowrap;
      width: max-content;
    }

    .checkIcon {
      display: flex;
      opacity: 0;
    }
  }
}
