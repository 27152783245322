@use '../../ui/styles/typography';
@use '../../layout/variables';

.footer {
  background-color: transparent;
  //padding: 0;
  flex-shrink: 0;
  /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 12rem;

  :global(.title),
  a {
    padding: 0.5rem 0.75rem;
  }

  :global(.level-item) {
    a {
      @include typography.textSmall;
      padding: variables.$size-7;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .copyright {
    color: var(--text);
  }
}

.signupFooter {
  background-color: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  width: 100%;
  align-items: flex-start;
  padding: 0;

  .wrapper {
    padding: 2.5rem 0;
    width: 100%;

    :global(.level-item) {
      flex-wrap: wrap;

      svg {
        margin: variables.$size-7;
      }
    }
  }
}

.appFooter {
  background-color: var(--contrast-low);
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  width: 100%;
  align-items: flex-start;
  padding: 0 1rem;

  .wrapper {
    padding: 2.5rem 0;

    @include variables.mobile() {
      padding: 2rem 1rem;
    }

    :global(.title),
    a {
      padding: 0.5rem 0.75rem;
    }

    :global(.is-link) {
      &:first-child {
        padding-inline-start: 0;
      }
    }

    :global(.level-item) {
      a {
        @include typography.textSmall;
        padding: variables.$size-7;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .copyright {
      color: var(--text);
    }

    .text {
      @include typography.textTiny;
      font-style: normal;
      color: var(--text-secondary);

      a {
        padding: 0;
      }
    }

    .chat {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      align-items: center;
      margin: -3rem 0 0 0;
      background: var(--contrast-low);
      padding: 1rem;
      border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0;

      :global(button) {
        background-color: transparent;
        border: none;
        cursor: pointer;

        > svg {
          margin-inline-start: 0.5rem;
        }
      }
    }
  }
}

@include variables.mobile() {
  .riskDisclosure {
    padding-bottom: 1rem;
  }
  .appFooter {
    padding: 2.5rem 1rem;

    &[dir='rtl'] {
      padding: 2.5rem 1rem 5rem 1rem;
    }

    .wrapper {
      padding: 0;

      .divider {
        display: none;
      }
    }

    :global(.level-left) {
      a {
        padding: 1rem 0;
      }

      + :global(.level-right) {
        margin-top: 0;
      }
    }

    :global(.level-item) {
      margin-bottom: 0;
    }
  }

  .copyright {
    justify-content: flex-start;
  }
}

.signupFooterText {
  @include typography.textTiny;
  color: var(--text-secondary);
  margin-bottom: 2rem;
}
