@use '../../layout/variables';

$paddingModalOutside: 1rem;
$paddingModalInside: 1rem;
$iconSize: 1.5rem;
$gapBetweenTextAndIcon: 0.75rem;

.container {
  :global(.modalWrapper) {
    padding: 0 1rem;
    padding-bottom: 1rem !important;
  }
}

.modalBody {
  border-radius: variables.$radius-large !important;
  padding: 1rem;
}

.iconWrapper {
  cursor: pointer;
  position: absolute;
  top: calc($paddingModalInside);
  right: calc($paddingModalOutside + $paddingModalInside);
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &[dir='rtl'] {
    right: unset;
    left: calc($paddingModalInside + $paddingModalOutside);
  }
}

.infoDescription {
  max-width: calc(100% - ($gapBetweenTextAndIcon + $iconSize));
}

.ctaLink {
  display: block;
  margin-top: 0.5rem;
  color: var(--text-interactive);
}
