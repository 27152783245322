.wrapper {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  & > * {
    width: 100%;
    margin-inline-start: 12px;
  }

  & > *:first-child {
    margin-inline-start: 0;
  }
}
