@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.walletHeaderWrapper {
  flex-direction: row;
  padding: 1rem 1.5rem;

  :nth-child(2) {
    align-items: flex-end;
  }

  @include variables.mobile() {
    padding-bottom: 2.2rem;
    margin: 0.75rem 0.75rem 1.5rem;
  }

  .header {
    display: flex;
    align-items: center;

    :first-child {
      margin-inline-end: 0.5rem;
    }

    .infoIcon {
      height: 14px;
    }

    .title {
      color: var(--text-secondary);
    }

    .subtitle {
      @include typography.h3;

      @include variables.mobile() {
        margin-top: 0.25rem;
      }
    }
  }
}

.actionWrapper {
  height: 100%;
  display: flex;
  align-items: center;

  .action {
    @include typography.textSmallStrong;
  }

  .infoIcon {
    padding: 0.5rem;
  }
}

.modal {
  overflow: visible !important;

  .content {
    display: flex;
    flex-grow: 1;
    gap: 1rem;

    .walletNumber {
      @include typography.text;
      word-break: break-word;
      flex-grow: 1;

      .textLabel {
        color: var(--text-secondary);
      }
    }
  }
}

.text {
  @include typography.text;

  .textLabel {
    color: var(--text-secondary);
  }
}

.radio {
  margin-inline-end: 1rem;
}
