@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.newLevelBanner {
  width: 100%;
  border-radius: 2rem;
  display: flex;
  align-items: start;
  padding: 1rem 1.125rem 0.75rem 1.16rem;
  position: relative;
  margin-top: 1rem;

  &.level2 {
    background: linear-gradient(239deg, #04c1fb -19.79%, #020295 73.43%);
  }

  &.level3 {
    background: linear-gradient(82deg, #002056 2.93%, #f623f9 94.76%);
  }

  &.level4 {
    background: linear-gradient(235deg, #01f168 -35.4%, #003417 63.16%);
  }

  &.level5 {
    background: linear-gradient(71deg, #000 15.98%, #ff9315 114%);
  }

  &.level6 {
    background: linear-gradient(81deg, #0f1a26 2.6%, #da0010 101.15%);
  }

  @include variables.mobile {
    padding: 0.875rem 1.5625rem 0.875rem 0.875rem;
  }

  .confetti {
    position: absolute;
    top: -0.3rem;
    inset-inline-start: -2.5rem;
  }

  .inner {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
    margin-inline-start: 1rem;
    flex: 1;

    @include variables.mobile {
      flex-direction: column;
      align-items: stretch;
    }

    .textWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;
      flex: 12;
      flex-shrink: 0;
      z-index: 1;

      @include variables.mobile {
        gap: 0.5rem;
      }

      .title {
        white-space: nowrap;
        color: var(--always-white);

        &.marginBottom {
          margin-bottom: 0.5rem;
        }
      }

      .text {
        color: var(--always-white);
      }
    }

    .hideButton {
      margin-inline-start: auto;
      border-radius: 1.5rem;
      background: rgba(255, 255, 255, 0.3);
      border: none;
      color: var(--always-white);
      padding: 0.25rem 0.75rem;
      cursor: pointer;
      flex: 1;

      @include variables.mobile {
        width: 100%;
        padding: 0.5rem 0.75rem;
      }
    }
  }

  &.maxLevel {
    padding: 0.5rem 2rem 0.75rem 1.16rem;

    @include variables.mobile() {
      padding: 1.125rem 2rem 1.125rem 1.16rem;
    }

    .inner {
      .maxLevelIcon {
        position: absolute;
        inset-inline-end: 1.125rem;
        bottom: 0.625rem;
        width: 11% !important;
      }
    }
  }
}

[dir='rtl'] {
  .newLevelBanner {
    padding: 1rem 1.16rem 0.75rem 1.125rem;

    @include variables.mobile {
      padding: 0.875rem 0.875rem 0.875rem 1.5625rem;
    }

    .confetti {
      transform: scaleX(-1);
    }
  }
}
