@use '../../ui/styles/typography';
@use '../../layout/variables';

.container {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include variables.mobile() {
    flex-direction: column;
    align-items: flex-start;
  }
}

.expandable {
  button {
    cursor: pointer;
    border-radius: 2rem;
    width: 100%;
    padding: 0.25rem 0.765rem;
    border: none;
  }
}

.subHeader {
  margin: 1.5rem 0;

  .currentTier {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  h5 {
    @include typography.textLarge;
    display: flex;
    flex-direction: column;
    gap: 0.125rem;

    span {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    a {
      width: fit-content;
      @include typography.textSmall;

      @include variables.mobile() {
        text-align: center;
      }
    }

    @include variables.mobile() {
      align-items: center;
    }
  }

  .rightTrack {
    @include variables.mobile() {
      text-align: center;
    }

    span {
      display: initial;
    }
  }
}

.wrapper {
  .header {
    padding: 1.5rem 1.5rem;
    border-radius: variables.$radius-xlarge;
    margin: 0;
    height: 4.5rem;
    background-color: var(--contrast-low);
  }
}
