@use '../../../layout/variables';
@use '../../../ui/styles/typography';

$gap: 1rem;

.spreadShareLevelBanners {
  width: 100%;
  display: flex;
  gap: $gap;
  margin-top: 1rem;
  flex-wrap: wrap;

  .box {
    padding: 0.5rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
    flex: 1;

    .percentage {
      color: var(--always-white);
      padding: 0.375rem;
      border-radius: 0.75rem;
    }

    p {
      min-width: 6rem;
    }

    &.current {
      min-width: calc(50% - #{$gap / 2});
      background: var(--background-content);

      .percentage {
        background: var(--background-selected);
      }
    }

    &.max {
      background: linear-gradient(86deg, #0f1a26 -4.38%, #da0010 96.04%);
      padding-inline-end: 2rem;

      .maxIcon {
        position: absolute;
        inset-inline-end: 0.25rem;
        top: 50%;
        transform: translateY(-50%);
      }

      .percentage {
        background: var(--background-accent-red);
      }

      p {
        color: var(--always-white);
        position: relative;
      }
    }
  }
}
