@use '../../ui/styles/typography';

.body {
  min-height: 10rem;
  padding: 0 !important;
  justify-content: flex-start !important;
  color: var(--text);
  background-color: var(--background-secondary);

  li {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid var(--contrast-low);
    height: 3.5rem;
    padding: 0 1.375rem;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      border: none;
    }

    > div {
      align-self: center;
    }

    &:first-child {
      border-top: 1px solid var(--contrast-low);
    }

    .searchBlock {
      position: absolute;
      margin-inline-start: -1.375rem;
      width: 100%;
      padding: 0 3rem 0 2rem;
      align-self: center;

      .input {
        background-color: var(--background-secondary);
        border-color: var(--contrast-medium-low);
        color: var(--text);

        &::placeholder {
          color: var(--contrast-medium-low);
          opacity: 1; /* Firefox */
        }
      }
    }
  }

  .labelWrapper {
    display: flex;
    align-items: center;
    padding: 0 2.125em;
    gap: 1rem;

    .countryFlag {
      border-radius: 50%;
      object-fit: cover;
      height: 24px !important;
      width: 24px !important;
    }
  }
}
