@use '../../layout/variables';

.notificationWrapper {
  z-index: map-get(variables.$zIndex, toast);
}

.notification {
  z-index: map-get(variables.$zIndex, toast);
  width: 100% !important;
  display: flex;
  background: var(--background);
  padding: 1.5rem;
  margin-bottom: variables.$block-spacing;
  border-radius: variables.$radius-xlarge;
  align-items: normal;
  margin-top: 1rem;

  @include variables.mobile() {
    padding: 1rem;
  }

  p {
    max-width: calc(95vw - 3rem);
    text-overflow: ellipsis;
  }

  &:global(.is-default) {
    border: 1px solid var(--contrast-medium-low);
    background: transparent;
  }

  &:global(.is-danger) {
    border: 1px solid var(--error);
    background: transparent;
  }

  &:global(.is-warning) {
    border: 1px solid var(--warning);
    background: transparent;
  }

  &:global(.is-success) {
    border: 1px solid var(--success);
    background: transparent;
  }

  .notificationContent {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin-inline-start: 0.31rem;

    .mainContent {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:first-child:not(:only-child) {
        margin-bottom: 0.25rem;
      }

      > p {
        margin-inline-start: 1rem;
        line-height: 1.5rem;
      }

      .title {
        color: var(--text);
        font-weight: variables.$font-weight-bold;
      }

      .subTitle {
        color: var(--text-secondary);
      }

      .extraMargin {
        margin-inline-start: 2.5rem;
      }

      .innerHtml {
        a {
          color: var(--text-interactive);
          text-decoration: none;
        }
      }
    }
  }
}
