.documentItemWrapper {
  margin-bottom: 1rem;

  .documentItem {
    display: flex;

    .documentName {
      display: flex;
      width: 5rem;
    }
  }
}
