@use '../ui/styles/typography';
@use '../layout/variables';

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;

  :global(button) {
    width: 100%;
  }

  .title {
    @include typography.textHero;
  }

  p {
    @include typography.text;
    color: var(--text-secondary);

    b {
      color: var(--text);
    }
  }

  .black {
    color: var(--text);
  }
}

.wrapper-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.round {
  min-width: 0.75rem;
  min-height: 0.75rem;
  background-color: var(--contrast-low);
  border-radius: variables.$radius-full variables.$radius-full;
  border: 0;
}

.active {
  background-color: var(--primary);
}

.padded {
  padding: 0.563rem 0.875rem;
}
