@use '../../layout/variables';
@use '../../ui/styles/typography';

@mixin disabled($text-color, $bg-color) {
  color: $text-color;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  @include variables.mobile() {
    gap: 0;
    margin-bottom: 0.25rem;
  }

  .option {
    display: flex;
    padding: 0.5rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    cursor: pointer;
    border-radius: variables.$radius-large;

    @include variables.mobile() {
      gap: 1rem;
      padding: 0.75rem;
    }

    &:hover {
      background: var(--contrast);
    }

    &:active,
    &:focus {
      background: var(--contrast-low);
    }
  }
}

.isDisabled {
  @include disabled(var(--disabled), var(--contrast-low));
}
