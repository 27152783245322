@use '../../layout/variables';
@use '../../ui/styles/typography';

.name {
  min-width: 15%;
}

@include variables.mobile() {
  .name {
    min-width: 20%;
  }

  .header {
    .title {
      .info {
        width: 85%;
      }
    }
  }
}
.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
  &:last-child {
    border: none;
  }
}

.card {
  margin-top: 1rem;
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  padding: 10rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin-top: 2rem;
  }

  :global(.button) {
    margin-top: 2rem;
    padding: 0 4rem;
  }
}

.container {
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
}

.info {
  color: var(--text);
  background: var(--background);
  border-radius: variables.$radius-xlarge;
  line-height: 24px;
  border: 1px solid var(--contrast-medium-low);
}

.modalContent {
  max-height: 300px;
}
