@use '../../../layout/variables';
@use '../../../ui/styles/typography';

$chart-tooltip-shadow: 0px 5px 10px 0px rgba(23, 31, 39, 0.05),
  0px 2px 40px 0px rgba(23, 31, 39, 0.04);
$chart-tooltip-shadow-dark: 0px 5px 10px 0px rgba(23, 31, 39, 0.05),
  0px 2px 40px 0px rgba(23, 31, 39, 0.04);

:root {
  --chart-tooltip-shadow: #{$chart-tooltip-shadow};
}

[data-theme='dark'] {
  --chart-tooltip-shadow: #{$chart-tooltip-shadow-dark};
}

.customTooltip {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: var(--background-modal);
  border-radius: 0.75rem;
  box-shadow: var(--chart-tooltip-shadow);

  .valueWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .circle {
      width: 0.83rem;
      height: 0.83rem;
      border-radius: 50%;
      border: 3px solid;
      flex-shrink: 0;

      &.yellow {
        border-color: var(--background-chart-yellow);
      }

      &.green {
        border-color: var(--background-chart-green);
      }

      &.blue {
        border-color: var(--background-chart-blue);
      }
    }
  }
}
