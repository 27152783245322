@use '../../layout/variables';
@use '../../ui/styles/typography';

$scrollContainerFade: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, var(--background) 116.17%);
$scrollContainerFadeDark: linear-gradient(90deg, var(--background) 0%, rgba(11, 15, 25, 0) 100%);

:root {
  --scroll-container-fade: #{$scrollContainerFade};
}

[data-theme='dark'] {
  --scroll-container-fade: #{$scrollContainerFadeDark};
}

.scrollContainerWrapper {
  width: 100%;
  position: relative;
  margin: 0 -0.5rem;
  width: calc(100% + 1rem);

  .scrollContainer {
    width: 100%;
    display: flex;
    overflow: visible;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 1rem 0.5rem 0 0.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .scrollContainerInner {
      flex: 1;
    }
  }

  .scrollContainerFade {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 7rem;
    pointer-events: none;
    z-index: 1;

    &.start,
    &.end {
      opacity: 0;
      background: var(--scroll-container-fade);
      transition: opacity 0.3s ease-in-out;

      &.active {
        opacity: 1;
      }
    }

    &.end {
      right: 0;
      transform: rotate(180deg);
    }

    &.start {
      left: 0;
    }
  }
}

.containerScrollable {
  .scrollContainer {
    overflow-x: auto;

    .scrollContainerInner {
      display: flex;
      white-space: nowrap;
    }
  }
}
