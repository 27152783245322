@use '../../ui/styles/typography';
@use '../../layout/variables';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 3rem variables.$size-3;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  box-shadow: 0 0.5rem variables.$size-1 rgba(0, 0, 0, 0.04);
  gap: 1rem;
  justify-content: space-between;

  .textBox,
  .actionBlock {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .actionBlock {
    .bottomSpaced:empty:before {
      content: ' ';
      white-space: pre;
    }
  }

  .title {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text {
    @include typography.text;
    color: var(--text-secondary);
  }
}

.textSmall {
  @include typography.textSmall;
}

.tooltipContainer {
  position: absolute;
  z-index: 1;
  color: var(--text);
  background-color: var(--background-secondary);
  border-radius: variables.$radius-large variables.$radius-large;
  box-shadow: var(--info-card-shadow);
  padding: 16px;
  bottom: 108px;
}
