.header {
  display: flex;
  cursor: pointer;
  position: relative;

  .arrow {
    &.asc:after,
    &.desc:after {
      margin-inline-start: 0.25rem;
      position: absolute;
      content: '\2191';
    }

    &.desc:after {
      transform: rotate(180deg);
    }
  }

  &.alignLeft {
    justify-content: flex-start;
  }

  &.alignCenter {
    justify-content: center;
  }

  &.alignRight {
    justify-content: flex-end;
  }
}
