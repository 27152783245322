@use '../ui/styles/typography';
@use '../layout/variables';

.topWrapper {
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  @include variables.mobile() {
    margin-top: 2rem;
  }

  .wrapper {
    min-width: 400px;
    display: flex;
    border-radius: variables.$radius-xlarge;

    .qrCode {
      padding: 2.5rem 2rem;
      background-color: var(--background);
      border-radius: variables.$radius-xlarge;

      flex: 1 1;
    }

    @include variables.mobile() {
      display: initial;
      min-width: 100%;
    }

    .inner {
      padding: 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @include variables.mobile() {
        padding: 0;
      }

      h2 {
        margin-bottom: 2.5rem;
      }

      .action {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .signup {
        @include typography.textSmall;
        flex: 1;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.625rem;

        .signUpLinkBtn {
          @include typography.text;
        }
      }
    }
  }
}

.form {
  width: 100%;
  min-width: 20rem;

  .field {
    border-radius: variables.$radius-medium;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .submitButton {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%;
  }

  @include variables.mobile() {
    min-width: auto;
  }
}

.textSmall {
  @include typography.textSmall;
}
