@use '../../layout/variables';
@use '../../ui/styles/typography';

.wrapper {
  margin-top: -1px;
  background-color: var(--primary);

  .innerWrapper {
    display: flex;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .message {
    display: flex;
    justify-content: center;
    width: 100%;

    .title {
      display: flex;
      justify-content: center;
      padding-inline: 1rem;
      width: 100%;
      color: var(--text-on-primary-color);
    }

    .readMore {
      margin-inline-start: 0.5rem;
      color: var(--text-on-primary-color);
      cursor: pointer;
    }
  }
}
