@use '../../../../ui/styles/typography';

.currencyFieldWrapper {
  width: 8.75rem;
}

.wallet {
  display: block;
  margin-bottom: 1rem;
}
