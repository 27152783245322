@use '../../ui/styles/typography';
@use '../../layout/variables';

.header {
  @include typography.textLargeStrong;
  color: var(--contrastMinDark);
  text-transform: none;
  font-weight: variables.$font-weight-semi-bold;
}

.body {
  @include typography.text;
  color: var(--contrastMinDark);
  background-color: var(--background);

  div:first-child {
    margin-bottom: 1.5rem;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  color: var(--text) !important;
  background-color: var(--background) !important;

  button {
    color: var(--contrastMinDark) !important;

    &:last-child {
      @include typography.textStrong;
    }
  }
}
