@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.addForm {
  padding: 1.75rem 1.5rem;
  background-color: var(--background);
  margin: 0;

  .countryCode {
    cursor: pointer;
    width: 12rem;
    height: 3.5rem;
  }

  .phoneNumber {
    height: 3.5rem;
  }

  .primaryCheckboxWrapper {
    margin-top: 1rem;

    .primaryCheckbox {
      @include typography.text;
      margin-inline-start: 0.25rem;
    }
  }
}

.button {
  @include typography.text;
  color: var(--text) !important;
}

.strongButton {
  color: var(--text) !important;
}

.mobileVerificationWrapper {
  background-color: var(--background);
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;

  .sent {
    @include typography.text;
    color: var(--text);
    margin-top: 1.5rem;
  }

  .error {
    @include typography.text;
    color: var(--error);
    margin-bottom: 0.5rem;
  }

  .fieldWrapper {
    display: flex;
    margin: {
      top: 1rem;
      bottom: 1rem;
    }

    .verificationBox {
      text-align: center;
      width: 3.125rem;
      height: 3.5rem;

      &:not(:last-child) {
        margin-inline-end: 1rem;
      }
    }
  }
}

.deleteModal {
  .header {
    background-color: var(--contrast-low);
  }
  .body {
    @include typography.text;
    color: var(--text);
    padding: 1.5rem 1.25rem;
    background-color: var(--background);
  }

  .title {
    color: var(--text);
  }

  .delete {
    @include typography.text;
    color: var(--error);
  }

  .cancel {
    color: var(--text);
  }
}

.footer {
  background-color: var(--background-secondary);
  border-top: 1px solid var(--contrast-low);
  justify-content: flex-end;
}

.verificationError {
  @include typography.text;
  color: var(--error);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.verificationBoxError {
  border-color: variables.$error !important;
}

.verificationBoxSuccess {
  border-color: variables.$success !important;
}
