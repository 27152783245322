@use '../../ui/styles/typography';
@use '../../layout/variables';

.stepsWrapper {
  display: flex;

  .step {
    @include typography.textSmall;
    display: flex;
    align-items: center;

    &.active {
      @include typography.textSmallStrong;
    }

    &:first-child {
      margin-inline-end: 3rem;

      @include variables.mobile() {
        margin-inline-end: 1rem;
      }
    }
  }

  @include variables.mobile() {
    justify-content: center;
  }
}

.container {
  min-width: 100%;
}

.wrapper {
  .warning {
    @include typography.textStrong;
  }

  .infoStrong {
    @include typography.textStrong;
    text-align: center;
  }

  .info {
    @include typography.text;
    text-align: center;
  }

  .textSecondary {
    @include typography.text;
    text-align: center;
    color: var(--text-secondary);
  }
}

.box {
  margin: 1rem 0;
  padding: 1.5rem 1.5rem;
  background: var(--background-secondary);
  border-radius: variables.$radius-large;
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
}

.supportOptions {
  width: 100%;
  background: var(--background);
  border-radius: variables.$radius-large;
  margin-inline-end: 0.5rem;
  padding: 2rem;
  flex: 1;

  @include variables.mobile() {
    margin: 0 0 2rem 0;
    display: none;
  }
}

.form {
  width: 100%;
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  padding: 2rem;
  border: 1px solid var(--contrast-low);

  @include variables.mobile() {
    margin-inline-start: 0;
    border: 0;
    padding: 0;
  }
}

.header {
  background-color: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
}

.fixedWidth {
  max-width: 55%;

  @include variables.mobile() {
    max-width: 100%;
  }
}
