@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.currencyFieldWrapper {
  width: 8.75rem;
}

.textSmall {
  @include typography.textSmall;
}

.link {
  color: var(--text-interactive);
  text-decoration: none;
}
.additionalNote {
  margin-bottom: 1rem;
}
