@use '../../ui/styles/typography';
@use '../../layout/variables';

.wrapper {
  position: relative;
  width: 100%;
  background-color: var(--background);
  margin-top: 2.75rem;

  .title {
    text-align: center;
    margin: 2.75rem 0 2.25rem 0;
  }

  .box {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: auto;
    padding-top: 2.75rem;
    max-width: 22.5rem;
    background: var(--background-secondary);

    box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;
  }

  .header {
    margin-bottom: 2.5rem;
  }

  .content {
    padding: 0 2.5rem 1.5rem 2.5rem;
  }

  .contentIcon {
    text-align: center;
    margin: 0 0 1rem 0;
  }

  .footer {
    height: 4.5rem;
    width: 100%;
    border-top: 1px solid var(--background);

    button {
      @include typography.textStrong;
      color: var(--text);
      background-color: transparent;
      border: none;
      outline: none;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }
}
