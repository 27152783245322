@use '../../ui/styles/typography';
@use '../../layout/variables';
@import '../formField/FormField.module.scss';

.textField {
  position: relative;

  :global(label) {
    z-index: 10;
    top: 0.35rem;
    left: 0.75rem;
    position: absolute;

    &[dir='rtl'] {
      left: auto;
      right: 0.665rem;
    }
  }

  .input {
    @include typography.text;
    height: 3.5rem;
    border-radius: variables.$radius-medium;
    resize: none;
    background-color: var(--background-secondary);
    color: var(--text);
    border: 1px solid var(--contrast-medium-low);

    &.disabled {
      color: var(--disabled);
      background-color: var(--contrast-low);
    }

    &.interactiveText {
      color: var(--text-interactive);
    }

    &:focus {
      outline: none;
    }

    &.inputReadOnly {
      background-color: var(--contrast-low);
      border-color: var(--contrast-medium-low);
      color: var(--text-secondary);
    }

    &.labledInput {
      padding-top: 1rem;
    }

    &.errored {
      border-width: 2px;
      border-color: var(--error);
    }

    &.isIconLeft {
      padding-inline-start: calc(#{variables.$icon-size} * 3);
    }

    &.isIconRight {
      padding-inline-end: calc(#{variables.$icon-size} * 3);
    }
  }

  .label {
    @include typography.textTiny;
    color: var(--text-secondary);

    &.isIconLeft {
      padding-inline-start: calc(#{variables.$icon-size} * 2);
    }

    &.isIconRight {
      padding-inline-end: calc(#{variables.$icon-size} * 2);
    }
  }

  .errorText {
    display: block;
    color: var(--error);
    margin-inline-start: 0.5rem;
  }

  .icon {
    display: block;
    position: absolute;
    top: 30%;

    &.iconLeft {
      left: variables.$icon-size;

      &[dir='rtl'] {
        left: auto;
        right: variables.$icon-size;
      }
    }

    &.iconRight {
      right: variables.$icon-size;

      &[dir='rtl'] {
        right: auto;
        left: variables.$icon-size;
      }
    }

    &.inputFocus {
      padding-top: 10px;
    }
  }
}
