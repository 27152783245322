@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);

  .cardHeader {
    display: flex;
    justify-content: space-between;

    .cardTitle {
      text-align: start;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    padding-top: 0.5rem;

    .label {
      color: var(--text-secondary);
      padding-inline-end: 0.5rem;
    }

    .value {
      color: var(--text);
    }
  }

  .expandIcon {
    border-radius: variables.$radius-small;
    border: 1px solid var(--contrast-low);
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    cursor: pointer;
  }

  .subAccount {
    background-color: var(--background);
    margin: 0.5rem -0.375rem 0 -0.375rem;
    padding: 0.375rem;
    border-radius: variables.$radius-medium;
  }
}
