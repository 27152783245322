@use '../../ui/styles/typography';
@use '../../layout/variables';

.stepsWrapper {
  display: flex;

  .step {
    display: flex;
    align-items: center;

    &.active {
      @include typography.textSmallStrong;
    }

    &:not(:last-child) {
      margin-inline-end: 3rem;

      @include variables.mobile() {
        margin-inline-end: 1rem;
      }
    }
  }

  @include variables.mobile() {
    justify-content: center;
  }
}

.container {
  min-width: 100%;
}

.wrapper {
  .warning {
    @include typography.textStrong;
  }

  .infoStrong {
    @include typography.textStrong;
    text-align: center;
  }

  .info {
    @include typography.text;
    text-align: center;
  }

  .textSecondary {
    @include typography.text;
    text-align: center;
    color: var(--text-secondary);
  }
}

.supportOptions {
  width: 100%;
  background: var(--background);
  border-radius: variables.$radius-large;
  margin-inline-end: 0.5rem;
  padding: 2rem;
  flex: 1;

  @include variables.mobile() {
    margin: 0 0 2rem 0;
    display: none;
  }
}

.form {
  padding: 1.5rem;
  width: 100%;
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  border: 1px solid var(--contrast-low);

  @include variables.mobile() {
    padding: 0;
    margin-inline-start: 0;
    border: 0;
  }
}

.helpIcon {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;

  span,
  a {
    margin-inline-start: 0.5rem;
  }
}

.supportLinksWrapper {
  display: grid;
  gap: 0.5rem;
  margin-top: 1.5rem;
  line-height: 1.5rem;

  .link {
    color: var(--text-interactive);
    text-decoration: underline;
  }
}

.supportLinksContainer {
  display: none;

  @include variables.mobile() {
    display: grid;
    margin-bottom: 2.25rem;
  }
}
