@use '../../../ui/styles/typography';
@use '../../../layout/variables';

:root {
  --nav-background-color: var(--contrast-low);
  --nav-color: var(--text);
}

[data-theme='dark'] {
  --nav-background-color: var(--contrast-low);
  --nav-color: var(--text);
}

[data-product='etd'] {
  --nav-background-color: var(--contrast-medium);
  --nav-color: var(--contrast-min);
}

.navWrapper {
  display: flex;
  flex-direction: column;

  .nav {
    background-color: var(--nav-background-color);
    color: var(--nav-color);
    padding: 1.75rem 0.5rem;
    z-index: map-get(variables.$zIndex, header);
    display: flex;
    align-items: center;

    .navInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      .navLogo {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .navMenu {
        display: flex;
        align-items: center;
        gap: 1rem;

        .barButtonWrapper {
          display: flex;
          gap: 1rem;

          .barButtonGroup {
            display: flex;
            align-items: center;

            .menuAction {
              position: relative;
            }
          }
        }
      }
    }
  }
}
