@use '../../../layout/variables';

.wrapper {
  .closeButton {
    border: none;
    background: transparent;
    padding: 0;
  }

  .innerWrapper {
    display: flex;
    margin: 2rem 0 1.5rem 0;

    @include variables.mobile {
      margin: 1rem 0;
    }

    > h3 {
      margin: auto;
    }
  }
}
