@use '../../../layout/variables';

.noteWrapper {
  display: flex;
  min-height: 440px;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  margin-top: 1.5rem;
  align-self: stretch;
  border-radius: variables.$radius-xlarge;
  background: var(--background-secondary);

  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);

  @include variables.mobile() {
    min-height: unset;
    padding: 2.5rem 1.5rem;
    margin-top: 0.5rem;
  }

  .textWrapper {
    display: flex;
    max-width: 608px;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    text-align: center;

    @include variables.mobile() {
      align-items: start;
      text-align: start;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
    justify-content: center;
    color: var(--text-secondary);

    @include variables.mobile() {
      align-items: start;
      gap: 1rem;
    }
  }
}
