@use '../../layout/variables';
@use '../../ui/styles/typography';

.header {
  background: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1rem 1.5rem;
  height: 5rem;
  display: flex;
  @include variables.mobile() {
    margin-top: 1rem;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;

    .infoIcon {
      padding: 0.5rem;
    }
  }
}

.profileWrapper {
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  margin-top: 1rem;
  padding: 1.5rem;
  display: flex;

  .logoWrapper {
    background: var(--background);
    border-radius: variables.$radius-xlarge;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem 2rem;
    width: 50%;

    .active {
      display: flex;
      margin: 2rem 0;

      .icon {
        margin-inline-end: 1rem;
      }
    }
  }

  .infoWrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--contrast-low);
    border-radius: variables.$radius-xlarge;
    padding: 2rem;
    margin-inline-start: 1rem;
    width: 50%;

    h3 {
      margin-bottom: 1rem;
    }
    p {
      margin: 0.175rem 0;
    }

    .profile {
      margin-top: 2rem;
    }

    .row {
      margin-top: 1rem;

      .link {
        color: var(--text-interactive);
        line-break: anywhere;
      }

      .flexText {
        flex-grow: 1;
      }
    }
  }

  .grayText {
    color: var(--text-secondary);
  }
}

@include variables.mobile() {
  .profileWrapper {
    flex-direction: column;

    .logoWrapper {
      width: 100%;
      margin-bottom: 1rem;
    }
    .infoWrapper {
      width: 100%;
      margin: 0;
    }
  }
}
