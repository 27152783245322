@use '../../ui/styles/typography';
@use '../../layout/variables';

.cardWrapper {
  width: 168px;
  color: var(--text);
  @include typography.textTiny;
  padding: 0.625rem 0.765rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  display: flex;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  min-width: 120px;
  max-width: 128px;

  &.isFirstItem {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  &.isLastItem {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &.isFirstItem[dir='rtl'] {
    border-radius: 0 1rem 1rem 0;
  }

  &.isLastItem[dir='rtl'] {
    border-radius: 1rem 0 0 1rem;
  }

  .tier {
    font-weight: 600;
    display: flex;
    align-items: baseline;

    @include variables.mobile() {
      flex-direction: column;
    }

    .tierAmount {
      display: none;
      font-weight: 700;

      @include variables.mobile() {
        display: block;
      }
    }
  }
  .minTierLots {
    white-space: nowrap;
  }

  .amount {
    font-weight: 700;

    @include variables.mobile() {
      display: none;
    }
  }

  &.isCurrentTier {
    background-color: #e9ffee;
  }

  &.isDisabled {
    color: var(--contrast-medium-low);
  }

  .expandable {
    height: 400px;
  }

  .button {
    cursor: pointer;
    border-radius: 2rem;
    padding: 0.25rem 0.765rem;
    border: none;

    &.isActive {
      background-color: var(--success-new);
      color: var(--background-secondary);
    }
  }

  &.isDisabled {
    color: var(--contrast-medium-low);
  }
}

.card {
  width: 100%;
  box-shadow: 0px -1px 0px 0px #ececec inset;

  .wrapper {
    display: flex;
    flex-direction: column;
  }

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0.625rem 1rem;
    gap: 1rem;
    align-items: center;
  }

  .tier {
    font-weight: 600;
    display: flex;
    align-items: baseline;
    @include variables.mobile() {
      flex-direction: column;
    }
  }

  .expandIcon {
    margin-left: auto;
  }

  &.isCurrentTier {
    background-color: #e9ffee;
  }

  &.isDisabled {
    color: var(--contrast-medium-low);
  }

  .expanded {
    padding: 0.625rem 1rem;
    color: var(--text-secondary);
  }

  .button {
    cursor: pointer;
    border-radius: 2rem;
    padding: 0.25rem 0.765rem;
    border: none;
    width: 100%;
    background-color: #00b728;
    height: 56px;
    color: var(--text-on-primary-color, #fff);
    @include typography.text;
    margin: 1rem 0;

    &:disabled {
      background: var(--contrast-low);
      cursor: not-allowed;
      color: var(--disabled);
    }
  }

  .info {
    color: var(--text-secondary);
  }
}

.subHeader {
  margin: 1.5rem 0;

  h5 {
    @include typography.textLarge;
    display: flex;
    flex-direction: column;
    gap: 0.768rem;
  }
}

.wrapper {
  .header {
    padding: 1.5rem 1.5rem;
    border-radius: variables.$radius-xlarge;
    margin: 0;
    height: 4.5rem;
    background-color: var(--contrast-low);
  }
}
