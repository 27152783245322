@use '../../ui/styles/typography';
@use '../../layout/variables';

.card {
  text-align: center;
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .phoneWrapper {
      color: var(--text);
      min-width: 108px;
    }

    .twoFA {
      color: var(--text);
      margin: 0 auto 0 0.5rem;
    }

    .actionWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .verify,
      .dots {
        cursor: pointer;
      }

      .dots {
        margin-inline-start: 0.75rem;
        display: flex;
      }
    }
  }

  .more {
    width: 100%;
  }
}

.label {
  color: var(--text);
  margin-top: 1rem;
}

.action {
  width: 100%;
  margin-top: 1.5rem;
}
