.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .action {
    display: flex;
    justify-content: center;

    .submitButton {
      min-width: 290px;
    }
  }

  .field {
    margin: 0;
  }

  .error {
    color: var(--error);
  }
}
