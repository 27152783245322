@use '../../ui/styles/typography';

.body {
  background-color: var(--background-secondary);
  color: var(--text);
  padding: 0 !important;
  @include typography.text;

  label,
  button {
    background: var(--background);
    border-bottom: 1px solid var(--contrast-low);
    padding: 2rem;
    color: var(--text);
    &:hover {
      color: var(--text-secondary);
    }
    &:last-child {
      border-bottom: none;
    }
  }

  :global(.radio) {
    margin: 0 !important;
  }

  svg {
    margin-inline-end: 0.5rem;
  }

  :global(button) {
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

.foot {
  button {
    color: var(--text);
  }
  background-color: var(--background-secondary);
  padding: 1rem !important;
}
