@use '../../styles/typography';
@use '../../../layout/variables';

.tableHeader {
  background: var(--contrast-low);
  border-radius: variables.$radius-xlarge;
  padding: 1rem 1.5rem;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  @include variables.mobile {
    padding: 1rem;
  }

  &.wrapOnMobile {
    @include variables.mobile {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .modulesWrapper {
        justify-content: center;
        width: 100%;
        flex-direction: column;

        > * {
          flex: 1;
        }

        .actionWrapper {
          width: 100%;

          .action {
            width: 100%;
          }
        }
      }
    }
  }

  &.hasExtraMarginTop {
    margin-top: 2rem;

    @include variables.mobile {
      margin-top: 1rem;
    }
  }

  .titleBlock {
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--text);

      @include variables.mobile {
        justify-content: space-between;
      }

      .back {
        margin-inline-end: 1rem;
        display: flex;
      }

      .gray {
        color: var(--text-secondary);
      }

      .titleBox {
        display: flex;

        .infoIcon {
          margin-inline: 0.5rem;
          display: flex;
          cursor: pointer;
        }
      }
    }

    .subtitle {
      color: var(--text);
    }
  }

  .action {
    @include variables.mobile {
      width: auto;
    }
  }

  .actionToggles {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: auto;

    @include variables.mobile {
      width: 100%;
    }

    .actionWrapper {
      display: flex;
      width: 100%;
      gap: 1.5rem;

      @include variables.mobile {
        gap: 1.5rem;
      }
    }
  }

  .actionButtonWrapper {
    display: flex;
    align-items: center;
    position: relative;
    gap: 0.5rem;

    .infoIcon {
      padding: 0.5rem;
      cursor: pointer;
    }

    .infoCard {
      @include variables.mobile {
        width: 100%;
      }
    }

    @include variables.mobile {
      width: 100%;

      &:not(:last-child) {
        margin-inline-end: 0;
      }
    }
  }

  .search {
    cursor: pointer;
  }

  .modulesWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .infoBlock {
    @include typography.textSmall;
    display: flex;
    gap: 0.5rem;
  }
}

.dots {
  display: flex;
  cursor: pointer;
}

.calculations {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include variables.mobile {
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-inline-start: 0.5rem;
    margin-inline-end: 0.5rem;

    &.calculation {
      justify-content: center;
    }
  }

  .calculationsItem {
    display: flex;
    align-items: center;

    &.preventWrap {
      white-space: nowrap;
    }

    &:not(:last-child) {
      margin-inline-end: 0.5rem;
    }

    .title {
      color: var(--text-secondary);
    }

    .value {
      color: var(--text);
      margin-inline-start: 0.25rem;

      &.link {
        color: var(--text-interactive);
        cursor: pointer;
      }
    }
  }
}

.reset {
  color: var(--error);
  cursor: pointer;
}

.mobileFilterToggles {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filterButton {
    margin-inline-start: 0.5rem;
  }

  .reset {
    padding: initial;
    padding-inline-start: 0.5rem;
  }

  .sortButton {
    margin-inline-start: 0.5rem;
    margin-inline-end: 0.5rem;
    text-align: end;
    text-transform: capitalize;
    background-color: var(--background);
  }
}

.searchWrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-inline-end: 1.5rem;

  @include variables.mobile() {
    justify-content: flex-start;
    padding-inline-end: 0;
  }

  .inputWrapper {
    display: flex;
    max-width: 22.75rem;
    width: 100%;
    justify-content: flex-end;

    @include variables.mobile() {
      max-width: 17.313rem;
      justify-content: flex-start;
    }
  }

  &.hide {
    display: none;
  }

  .input {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-style: italic;
    background-color: var(--background-secondary);
    border-color: var(--contrast-medium-low);
    color: var(--text);

    width: 100%;
    flex: 1 1 auto;

    &::placeholder {
      color: var(--disabled);
    }
  }
}

.tabs {
  margin-inline-end: 1.5rem;
  margin-inline-start: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;

  @include variables.mobile() {
    width: 100%;
    justify-content: center;
    margin-top: 1.75rem;
  }

  .tab {
    @include typography.text;
    color: var(--text-secondary);
    cursor: pointer;

    &:not(:last-child) {
      margin-inline-end: 1.5rem;
    }

    &.active {
      color: var(--text);
    }
  }
}

.expandedDetails {
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
  background: var(--contrast-medium-low);
  border-radius: 0 0 variables.$radius-xlarge variables.$radius-xlarge;
  @include typography.textSmallStrong;
}

.detailsContent {
  text-align: center;
  background: var(--contrast-low);
  border-top: 1px var(--contrast-medium-low) solid;
  padding: 0.625rem 1rem;
}
