@use '../../ui/styles/typography';
@use '../../layout/variables';

:global(.modal-card-head) {
  border-color: var(--contrast-low);
}

.body {
  @include typography.text;
  color: var(--text);
  background-color: var(--background-secondary);
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include variables.mobile() {
    flex-direction: column;
  }

  > div {
    padding: 0.5rem;
    flex: 1 1 0px;

    h3 {
      margin-bottom: 0.5rem;
    }
  }
}

.footer {
  background-color: var(--background-secondary);
  button {
    color: var(--text);
  }
  @include variables.mobile() {
    @include typography.textSmall;
  }
}
