@use '../../layout/variables';
@use '../styles/typography';

.testWidget {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;

  .questionAnswerField {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--background);
    padding: 1rem;
    border: 1px solid var(--contrast-low);
    border-radius: variables.$radius-large;

    .textWrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .questionName {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    :global(.control) {
      margin-top: 1rem;
    }

    &.radioError {
      border-color: var(--error);
    }

    .marginBottom {
      margin-bottom: 1rem;
    }
  }
}
