@use '../../layout/variables';
@use '../../ui/styles/typography';

.column {
  table-layout: fixed;
  width: 14%;
}

.strikethrough {
  text-decoration-line: line-through;
  color: var(--disabled);
}
