@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.noResults {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--background-secondary);
  box-shadow: 0 8px 40px rgb(0 0 0 / 4%);
  border-radius: variables.$radius-xlarge;
  margin-top: 2rem;
  margin-bottom: 1rem;
  gap: 2.5rem;
  min-height: 400px;
  padding: 3rem;
  text-align: center;

  > div {
    max-width: 600px;

    @include variables.mobile() {
      max-width: initial;
    }
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    .icon {
      background-color: var(--contrast-low);
      border-radius: variables.$radius-full;
      padding: 1.5rem;
    }
  }

  @include variables.mobile() {
    min-height: 570px;
    padding: 2.5rem 1.5rem;
  }
}
