@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.form {
  height: 100%;

  h3 {
    padding-top: 1.5rem;
  }

  .row {
    display: flex;

    div:first-child {
      flex: 1 5%;
      margin-inline-end: 0.25rem;

      @include variables.mobile() {
        flex: 2;
      }
    }

    div:last-child {
      flex: 3;
    }
  }

  input[type='checkbox'] {
    &.hasIncreasedSize {
      margin-inline-end: 1rem !important;
      transform: scale(1.5);
    }
  }
}

.countryCode {
  // Otherwise Italian label doesn't fit (WCA-868)
  width: 155px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2.5rem auto 0 auto;
  padding: 0 120px;

  @include variables.mobile() {
    padding: 0;
    width: 100%;
  }
}

.additionalText {
  color: var(--text-secondary);
}

// TODO Refactor
.plainButton {
  @include typography.text;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--text-interactive);
}

// TODO Refactor
.modal {
  margin-top: 2rem;
}
