@use '../../layout/variables';
@use '../../ui/styles/typography';

$tabsCount: 2;
$gapBetweenTabs: 1rem;

:global(.modal .modalCard.twoFATurnOffModal) {
  max-width: 460px;
  @include variables.mobile() {
    max-width: 100%;
  }
}

:global(.modal .modalCard.googleAuthenticatorBox) {
  overflow-y: auto;
}

.boxWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;
  margin-top: 1rem;

  .box {
    background-color: var(--background-secondary);
    padding: 1.5rem;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;
    flex-basis: calc((100% - 2rem) / 3);
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include variables.mobile() {
      flex-basis: 100%;
    }

    .boxTitle {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding-bottom: 1rem;
    }

    .boxList {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .themeOption {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .form {
      .submitButton {
        display: flex;
        margin: {
          top: 1.5rem;
          inline-start: auto;
          inline-end: auto;
        }
      }

      input[type='checkbox']:checked + label::after {
        display: block;
        position: absolute;
        left: 3px;
        top: 3px;
      }

      @include variables.mobile() {
        input[type='checkbox'] {
          -ms-transform: scale(2); /* IE */
          -moz-transform: scale(2); /* FF */
          -webkit-transform: scale(2); /* Safari and Chrome */
          -o-transform: scale(2); /* Opera */
          transform: scale(2);
          margin-inline-start: 0.5rem;
        }
      }
    }
  }

  .boxFill {
    flex-grow: 1;
  }

  .notifications {
    color: var(--text);
    background-color: inherit;

    thead th {
      padding: 0 !important;
    }

    tbody td {
      padding: 0 !important;
    }

    .header {
      .label {
        color: var(--text);
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;

        .infoIcon {
          cursor: pointer;
          display: flex;
          width: 12px;
          height: 12px;
          margin-inline-end: 0.25rem;
        }
      }
    }

    .notificationItem {
      .name {
        @include typography.textStrong;
        color: var(--text);
        width: 260px;

        .info {
          display: flex;
          margin-bottom: 1.5rem !important;
          color: var(--text-secondary);
        }
      }

      .options {
        width: 120px;
        margin-inline-start: auto;

        .optionWrap {
          display: flex;
          justify-content: center;
        }

        .option {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  .twoFASubtitle {
    max-width: 30rem;

    @include variables.mobile() {
      max-width: 100%;
    }
  }

  .twoFALabel {
    color: var(--text);
  }

  .textContrastMediumLow {
    color: var(--contrast-medium-low);
  }
}

.twoFAModalDivider {
  background: var(--contrast-low);
  width: 100%;
  height: 1px;
}

.twoFAModalRadioListItem {
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid var(--contrast-low);
}

.twoFATurnOffModalWrapper {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: variables.$radius-xlarge;

  .twoFATurnOffModalButtons {
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end;

    @include variables.mobile() {
      flex-direction: column-reverse;
      gap: 1rem;
    }
  }
}

.googleAuthenticatorBox {
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  max-width: 474px;
  padding: variables.$size-3;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include variables.mobile() {
    padding: 1rem;
  }

  .title {
    font-size: variables.$size-4-1;
    font-weight: variables.$font-weight-semi-bold;

    @include variables.mobile() {
      font-size: variables.$size-4-2;
    }
  }

  .divider {
    display: block;
    background-color: var(--contrast-low);
    border: none;
    height: 1px;
    margin-top: 1rem;

    @include variables.mobile() {
      margin-top: 0.75rem;
    }
  }

  .content {
    margin-top: 1rem;
    padding: 0.5rem;

    @include variables.mobile() {
      margin-top: 0.75rem;
    }

    .stepTitle {
      line-height: 1.5rem;
      margin-left: 0.62rem;
    }

    .iconsBox {
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      flex-wrap: wrap;

      svg {
        height: 2.5rem;
        width: fit-content;
      }

      :not(:last-child) {
        margin-right: 10px;
      }
    }

    .qrCodeWrapper {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      gap: 0.75rem;

      .qrCodeImg {
        width: 7.5rem;
        height: 7.5rem;
        border: 1px solid variables.$contrast-medium-low;
        border-radius: variables.$radius-medium;
      }

      .qrCodeMainContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;

        @include variables.mobile {
          margin-left: 0;
        }

        .qrCodeTitle {
          margin-top: 0.37rem;
          font-weight: variables.$weight-semibold;
          line-height: 1.5rem;
        }

        .qrCodeControl {
          display: flex;
          align-items: center;
          margin: 0.5rem 0.25rem 0;
          gap: 0.5rem;
          box-sizing: border-box;
        }

        .qrCode {
          margin: 0;
          flex: 1;

          input {
            background-color: var(--background-secondary);
            color: var(--text);
            font-size: variables.$size-7;
            font-weight: variables.$weight-medium;
            height: 2.5rem;
            text-align: center;
          }
        }
      }
    }

    .verificationTitleBox {
      display: flex;
      align-items: flex-start;
      line-height: 1.5rem;
    }

    .verificationSubtitle {
      line-height: 1.5rem;
      margin-left: 2rem;
      margin-top: 0.25rem;
      font-weight: variables.$weight-semibold;
    }

    .verificationFieldWrapper {
      margin-left: 2rem;

      @include variables.mobile() {
        margin-left: 0;
      }

      .verificationField {
        margin-top: 0.62rem;
      }
    }
  }

  .controlBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.75rem;
    @include variables.mobile() {
      flex-direction: column-reverse;
      row-gap: 0.75rem;
    }

    > :last-child {
      margin-left: 0.5rem;

      @include variables.mobile() {
        margin-left: 0;
      }
    }
  }
}

.recoveryCodesModalCard {
  overflow: visible !important;
  max-width: 31.25rem !important;

  @include variables.mobile() {
    max-width: 100% !important;
  }

  .recoveryCodesModalInner {
    padding: 1.5rem;
    background-color: var(--background-secondary);
    border-radius: 1rem;

    @include variables.mobile() {
      padding: 1.5rem 1rem 2rem;
      border-radius: 1rem 1rem 0 0;
    }

    .recoveryCodesModalHeader {
      border-bottom: 1px solid var(--contrast-low);
      padding-bottom: 1rem;
    }

    .recoveryCodesModalSection {
      padding: 1rem 0;
    }

    .recoveryCodesModalSectionCodes {
      display: flex;
      align-items: center;
      justify-content: center;

      .recoveryCodesModalColumns {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: var(--contrast);
        padding: 1.25rem;
        border-radius: 1rem;

        ul {
          li {
            margin-top: 0.5rem;

            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    .recoveryCodesModalSectionButtons {
      display: flex;
      justify-content: space-between;
    }

    .recoveryCodesModalButton {
      span {
        color: var(--contrast-high);

        :global(.icon:first-child:last-child) {
          margin: 0 0.5rem 0 0;
        }
      }
    }

    .recoveryCodesModalButtonMobile {
      align-self: center;
      width: 2.5rem;
      height: 2.5rem;

      & + & {
        margin-left: 0.5rem;
      }

      :global(.icon:first-child:last-child) {
        margin: 0;
      }
    }

    .recoveryCodesModalFooter {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid var(--contrast-low);
      padding-top: 1rem;

      .recoveryCodesModalCancelTooltip {
        @include variables.mobile() {
          width: 100%;
        }

        .recoveryCodesModalCancelButton {
          &:not(:last-child) {
            margin: 0;
          }

          @include variables.mobile() {
            width: 100%;
          }
        }
      }

      .recoveryCodesModalCancelTooltipCard {
        @include variables.mobile() {
          margin: 0;
          top: 0;
        }
      }
    }
  }
}

.tooltipContainer {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-start: 0.25rem;
}

.tooltipWrapper {
  max-width: 12.5rem;
}

.smsSwitchTooltip {
  width: 17.25rem;
  white-space: wrap;
  left: 0 !important;
  top: -0.5rem !important;
  transform: translateX(0) translateY(-100%);
}

.tabContainer {
  display: flex;
  gap: 2rem;

  @include variables.mobile() {
    flex-direction: column;
    gap: 1rem;
  }
}

.tabHeaders {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-right: 2rem;
  min-width: fit-content;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: variables.$contrast-low;
    right: 0;

    @include variables.mobile() {
      width: 100%;
      height: 1px;
      right: unset;
      bottom: -0.5rem;
    }
  }

  .tabButtonsContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include variables.mobile() {
      flex-direction: row;
    }

    .tabButton {
      position: initial;
      width: fit-content;
      justify-content: flex-start;
      color: var(--text-secondary);
      padding: 0.5rem 0;

      @include variables.mobile() {
        position: relative;
        padding: 0;
      }
    }

    .active {
      color: var(--text);

      &:after {
        content: '';
        z-index: 1;
        width: 1px;
        height: calc((100% / $tabsCount) - $gapBetweenTabs);
        background-color: variables.$primary;
        position: absolute;
        right: -2rem;
        pointer-events: none;

        @include variables.mobile() {
          width: 100%;
          height: 1px;
          right: unset;
          bottom: -0.5rem;
        }
      }

      span {
        @include variables.mobile() {
          @include typography.textStrong;
        }
      }
    }
  }
}

.tabContent {
  width: 100%;
  border-radius: 0 0 8px 8px;

  .box {
    width: 100%;

    .shortMarginDivider {
      margin: 1rem 0;
    }
    .largeMarginDivider {
      margin: 1.5rem 0;
    }
    .shortMarginDivider,
    .largeMarginDivider {
      display: block;
      background-color: var(--contrast-low);
      border: none;
      height: 1px;

      @include variables.mobile() {
        margin: 1rem 0;
      }
    }

    .boxTitle {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    .twoFASubtitle {
      color: var(--text-secondary);
      max-width: 470px;
      margin-top: 1rem;
    }
  }

  .textContrastMediumLow {
    color: var(--text-secondary);
  }

  .twoFAMethodContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;

    .twoFAMethodWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .twoFALabel {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-right: 0.5rem;
      &.hasBadge {
        gap: 0.5rem;
      }
      &.has2FAEmailSet {
        gap: 0.75rem;
      }
    }
  }

  .changePasswordForm {
    max-width: 400px;

    @include variables.mobile() {
      max-width: 100%;
    }
  }

  .changePasswordDescription {
    display: inline-block;
    margin: 1rem 0;
  }

  .changePasswordFormField {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .changePasswordSubmitWrapper {
    display: flex;
    justify-content: center;

    .submitButton {
      @include variables.mobile() {
        width: 100%;
      }
    }
  }

  .infoIconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.viewRecoveryCodes {
  @include variables.mobile() {
    width: 100%;
  }
}
