@use '../../ui/styles/typography';
@use '../../layout/variables';

.rules {
  display: flex;
  flex-wrap: wrap;
  margin: 0.625rem 0;

  .ruleBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    min-height: 4.75rem;
    height: auto;
    padding: 0.25rem;

    &.isTwoRules {
      min-width: 50%;
    }

    &.isThreeRules {
      min-width: 33.33%;
    }

    &.isFourRules {
      min-width: 25%;
    }

    &.isFiveRules {
      min-width: 20%;
    }

    @include variables.mobile {
      width: 50%;
      min-width: 160px !important;

      &:last-child {
        width: 100%;
      }
    }
  }

  .rule {
    @include typography.textSmall;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--contrast-low);
    padding: 0.75rem;
    border-radius: 0.75rem;
    height: 100%;
    width: 100%;

    .ruleLabel {
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-wrap: initial;

      .ruleLabelText {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      @include variables.mobile {
        white-space: initial;
      }
    }

    .ruleValue {
      @include typography.textSmallStrong;
      display: flex;
      white-space: nowrap;
    }

    &.ruleError {
      background: linear-gradient(127.56deg, var(--primary-focused) 0%, var(--primary) 95.22%);
      color: var(--text-on-primary-color) !important;
    }
  }
}

.warningMessage {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  @include variables.mobile() {
    align-items: flex-start;
  }
}
