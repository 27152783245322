@use '../ui/styles/typography';
@use '../layout/variables';

.adminRoutes {
  @include typography.textSmall;
  padding: 1.5rem 0 2.5rem 0;

  @include variables.mobile() {
    display: none;
  }
}

.sidebar {
  background-color: var(--background-secondary);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  padding: 1.5rem 1.5rem;
}

.warning {
  color: var(--text-secondary);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;

  strong {
    color: var(--text-secondary);
  }
}

.important {
  color: var(--error);
}

.paymentModal {
  max-width: 22.5rem;
}

.subscriptionsBox {
  display: flex;
  gap: 1rem;

  @include variables.mobile {
    flex-direction: column-reverse;
    margin-right: 0;
    margin-top: 0;
  }

  .subscriptionsTemplateWrapper {
    flex-direction: column;
    gap: 1rem;
    width: 65%;
    margin-top: 0;
    padding-top: 0;

    .subscriptionsHeader {
      margin-bottom: 1rem;

      @include variables.mobile() {
        margin-top: 1rem;
      }
    }

    .thirdPartySubscriptionsHeader {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    @include variables.mobile() {
      width: 100%;
    }
  }

  .sidebarWrapper {
    flex: none;
    width: 34%;

    @include variables.mobile() {
      padding-left: 0;
      width: 100%;
    }
  }
}
