@use '../../../../ui/styles/typography';

.chipFilter {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .name {
    color: var(--text);
  }

  .chipsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .buttonItem {
    display: inline-block;
  }
}
