@use '../../../../../ui/styles/typography';
@use '../../../../../layout/variables';

.mobileVerificationWrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @mixin textDefaults {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.25rem;
  }

  .title {
    @include textDefaults;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .text {
    @include textDefaults;
  }

  .codeBox {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .codeField {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .fieldBlock {
    width: 3.125rem;

    .verificationBox {
      text-align: center;

      &.verificationBoxSuccess {
        border-color: variables.$success !important;
      }

      &.verificationBoxError {
        border-color: variables.$error !important;
      }
    }
  }

  .validationError {
    @include typography.text;
    color: var(--error);
  }

  .skipButtonBox {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1.5rem;

    button {
      max-width: 260px;
    }
  }
}
