@use '../../../layout/variables';
@use '../../../ui/styles/typography';

$banner-background: #000000;
$banner-background-gradient: linear-gradient(243deg, #d20026 0.16%, rgba(0, 0, 0, 0) 66.78%);
$banner-background-gradient-reversed: linear-gradient(
  97deg,
  #d20026 0.16%,
  rgba(0, 0, 0, 0) 66.78%
);
$banner-border-radius: 1.5rem;

.maxLevelBanner {
  width: 100%;
  border-radius: $banner-border-radius;
  display: flex;
  align-items: flex-start;
  padding: 0 5.13rem 0.75rem 1.16rem;
  position: relative;
  margin-top: 1rem;
  background-color: $banner-background;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    width: 14.75rem;
    height: 100%;
    background: $banner-background-gradient;
    border-radius: 0 $banner-border-radius $banner-border-radius 0;
  }

  @include variables.mobile {
    padding: 0 4.35rem 0.75rem 1.16rem;
  }

  .icon {
    margin-top: 0.75rem;
  }

  .confetti {
    position: absolute;
    top: 0;
    inset-inline-start: -1.3rem;
  }

  .medal {
    margin-inline-start: auto;
    position: absolute;
    inset-inline-end: 1.13rem;
    top: 0;

    @include variables.mobile {
      inset-inline-end: 0.43rem;
    }
  }

  .textWrapper {
    padding-top: 0.91rem;
    margin-inline-start: 1rem;
    position: relative;
  }

  .title {
    white-space: nowrap;
    color: var(--always-white);
  }

  .text {
    color: var(--always-white);
  }
}

[dir='rtl'] {
  .maxLevelBanner {
    padding: 0 1.16rem 0.75rem 5.13rem;

    &::before {
      inset-inline-end: 0;
      border-radius: $banner-border-radius 0 0 $banner-border-radius;
      background: $banner-background-gradient-reversed;
    }

    @include variables.mobile {
      padding: 0 1.16rem 0.75rem 4.35rem;
    }

    .confetti {
      transform: scaleX(-1);
    }
  }
}
