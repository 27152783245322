@use '../../layout/variables';
@use '../../ui/styles/typography';

.option {
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--background);
  border-bottom: none;
  background-color: var(--surface-secondary);
  border-radius: variables.$radius-medium;

  label {
    padding: 1rem;
    background-color: transparent;

    @include variables.mobile() {
      padding: 0.5rem 1rem;
    }
  }

  @include variables.mobile() {
    padding: 0;
  }
}
