@use '../../ui/styles/typography';
@use '../../layout/variables';

.carousel {
  padding-bottom: 1.875rem;

  .carouselChild {
    height: 100%;
    padding: 0 3.125rem;

    @include variables.mobile() {
      padding: 0 1.375rem;
    }

    .iconContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .subWrapper {
        width: 33.3333%;
        max-width: 150px;
        max-height: 200px;
        height: auto;

        &:first-child {
          padding-inline-end: 10px;

          @include variables.mobile() {
            padding-inline-end: 5px;
          }
        }

        &:not(:last-child, :first-child) {
          padding-inline-end: 5px;
          padding-inline-start: 5px;

          @include variables.mobile() {
            padding-inline-end: 2.5px;
            padding-inline-start: 2.5px;
          }
        }

        &:last-child {
          padding-inline-start: 10px;

          @include variables.mobile() {
            padding-inline-start: 5px;
          }
        }

        @include variables.mobile() {
          max-width: 100px;
        }
      }

      .clientBaseIcon {
        width: 100%;
        height: 100%;
        background-color: var(--background-secondary);
        padding: 16px 23px;
        border-radius: variables.$radius-xlarge;

        @include variables.mobile() {
          max-width: 100px;
          padding: 7px 12.5px;
        }
      }
    }

    .iconWrapper {
      background-color: var(--background-secondary);
      border-radius: variables.$radius-xlarge;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 20px;
      max-height: 200px;

      &.smallPad {
        padding: 15px;
      }

      @include variables.mobile() {
        max-height: 150px;
        padding: 15px 5px;
      }

      .icon {
        width: auto;
        height: 100%;
        box-sizing: border-box;

        @include variables.mobile() {
          max-height: 110px;
          max-width: 170px;
        }
      }

      &.dualIcon {
        justify-content: space-evenly;

        .icon {
          max-width: 50%;
          height: auto;
        }
      }
    }

    .title {
      color: var(--primary);
      padding-top: 1.25rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
    }

    .terms {
      padding-top: 1rem;
      color: var(--primary);
    }

    .list {
      list-style: inside;
      color: var(--primary);

      .listItem {
        @include typography.textLarge;
        padding-top: 0.5rem;

        .listItemText {
          color: var(--text);
        }
      }
    }

    strong {
      @include typography.textLargeStrong;
    }
  }
}
