@use '../../layout/variables';
@use '../../ui/styles/typography';

.exchangeWrapper {
  display: flex;
  width: 60%;
  padding: 1rem 4px;
  justify-content: center;
  border: 1px solid var(--contrast-low);
  border-radius: variables.$radius-medium;
  margin: 0 auto 1.5rem auto;
  @include typography.textSmall;
  span:first-child {
    color: var(--text-secondary);
  }
  .calculation {
    font-weight: variables.$font-weight-semi-bold;
  }
  .icon {
    margin-inline-start: 0.785rem;
    svg {
      vertical-align: middle;
    }
  }
  @include variables.mobile() {
    margin: 0 0 1.5rem 0;
    width: 100%;
  }
}
