@use '../../ui/styles/typography';
@use '../../layout/variables';

.text {
  @include typography.text;
}

.radio {
  margin-inline-end: 1rem;
}

.balances {
  display: flex;
  justify-content: space-between;

  .wallets {
    width: calc(60% - 0.5rem);
    margin-inline-end: 1rem;

    @include variables.mobile() {
      width: 100%;
      margin-inline-end: 0;
    }
  }

  .tradingAccounts {
    width: calc(40% - 0.5rem);

    @include variables.mobile() {
      width: 100%;
    }
  }
}

.tabsWrapper {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
