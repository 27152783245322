@use '../../layout/variables';
@use '../../ui/styles/typography';

.vendorModal {
  display: flex;
  align-items: stretch;
  background: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  overflow: hidden;
  flex-wrap: wrap;

  .closeButton {
    display: flex;
    padding: 0.5rem;
    align-items: flex-start;
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: variables.$radius-full;
    background: var(--background-secondary);
    box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.04);
  }

  .logoBox {
    width: 30%;
    min-width: calc(min(300px, 100%));
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    background-color: var(--always-white);
    padding: 3rem 0;
    box-sizing: border-box;

    .logo {
      width: 100%;
      max-height: 100px;
      height: auto;
      object-fit: contain;
    }
  }

  .contentBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: auto;
    min-width: 70%;
    padding: 3rem 3rem 2rem 3rem;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;

    @include variables.mobile() {
      padding: 1.5rem;
    }

    &.parentWrapped {
      align-items: stretch;
    }

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.5rem;
    }
  }

  .description {
    white-space: pre-wrap;
  }

  .icon {
    top: 16px;
    left: 16px;
    position: absolute;
    background: var(--background-secondary);
    border-radius: variables.$radius-full;
  }

  img {
    max-height: 300px;
    max-width: 80%;
  }

  .image {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: variables.$radius-xlarge 0 0 variables.$radius-xlarge;
  }

  .imageMobileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 1.5rem 0;
    border-radius: variables.$radius-medium variables.$radius-medium 0px 0px;
    max-height: 200px;
    width: 100%;
  }

  .button {
    width: 100%;
  }
}
