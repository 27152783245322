.tableWrapper {
  min-height: 19rem;
  margin-top: 0.5rem;
}

.dropdownIconContainer {
  position: relative;
  cursor: pointer;
}

.tradingAccountTitleWrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
