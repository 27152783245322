@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.wrapper {
  display: flex;
  margin-top: 1rem;

  @include variables.mobile() {
    flex-wrap: wrap;
  }
}

.header {
  background-color: var(--contrast-low);
  margin-top: 2rem;
  border-radius: variables.$radius-xlarge;
  @include variables.mobile() {
    margin-top: 1rem;
  }

  .title {
    text-transform: capitalize;
  }
}

.wrapper {
  .section + .section {
    margin-inline-start: 16px;
  }
}

.section {
  margin-bottom: 1.5rem;
  flex-grow: 1;
  width: 50%;

  h3 {
    margin-bottom: 1rem;
  }

  .box {
    width: 100%;
    background: var(--background-secondary);
    box-shadow: 0px 0.5rem 3.5rem rgba(0, 0, 0, 0.04);
    border-radius: variables.$radius-xlarge;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    flex-grow: 1;
  }

  .questionAnswerField {
    background-color: var(--background);
    padding: 1rem;
    margin: 1rem 0;
    border: 1px solid var(--contrast-low);
    border-radius: variables.$radius-large;

    :global(.control) {
      margin-top: 1rem;
    }
  }
}

.submitWrapper {
  width: 190px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.detailsWrapper {
  text-align: end;
}

.text {
  @include typography.text;
}

.textStrong {
  @include typography.textStrong;
}

.successTitle {
  @include typography.h3;
  margin-bottom: 1rem;
}

.successContent {
  min-width: 100%;
}
