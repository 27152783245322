@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.ibTransactionHistory {
  width: 100%;

  .transactionsContainer {
    padding-top: 1rem;

    .transactionsContainerScroll {
      gap: 1.5rem;
      display: flex;
      align-items: stretch;
    }

    .transactionsWrapper {
      flex: 1;
      min-width: 290px;
      max-width: 336px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &:only-child {
        max-width: none;
      }

      .transaction {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        min-width: 0;

        .transactionDetails {
          display: flex;
          align-self: center;
          justify-content: space-between;
          flex: 1;
          min-width: 0;

          .transactionDetailsCol {
            display: flex;
            flex-direction: column;
            width: 50%;

            &:last-child {
              text-align: end;
            }

            .tooltipWrapper {
              display: flex;
              align-self: flex-start;

              &.alignEnd {
                align-self: flex-end;
                justify-content: flex-end;
              }

              .transactionName {
                @include typography.textTruncate;

                display: block;
              }
            }

            .amountGreen {
              color: var(--text-positive);
            }
          }
        }
      }
    }
  }
}
