@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.addBankAccount {
  margin-inline-end: auto !important;
  @include variables.mobile() {
    display: none;
  }
}

.bankAccountLabel {
  display: flex;
  gap: 1rem;

  @include variables.mobile() {
    flex-direction: column;
    gap: 0;

    &:last-child {
      @include typography.textSmall;
    }
  }
}

.mobileAddBankAccount {
  display: none !important;
  background: var(--background) !important;
  z-index: 2;
  border-bottom: 1px solid var(--contrast-low) !important;
  padding: 1.25rem 1rem;
  gap: 1rem;
  align-items: center;
  position: sticky;
  top: -1px;
  margin-top: -1px;

  @include variables.mobile() {
    display: flex !important;
  }

  .icon {
    background-color: var(--contrast-low);
    border-radius: variables.$radius-full;
  }

  span {
    color: var(--text);
    text-align: start;
  }
}
