@use '../../ui/styles/typography';
@use '../../layout/variables';

.tradingView {
  margin-top: 1rem;
  @include variables.mobile() {
    margin-top: 0.5rem;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;

  .container {
    display: flex;
    max-width: 51rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    @include variables.mobile() {
      max-width: 22.5rem;
    }

    .textCentered {
      text-align: center;
    }

    .link {
      color: var(--text-interactive);
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .textSecondary {
    color: var(--text-secondary);
    &.isDisabled {
      color: var(--disabled);
    }
  }

  .nextSection {
    display: flex;
    min-width: fit-content;
    min-width: -webkit-fill-available;
    padding: 1.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    border-radius: 1rem;
    border: 1px solid var(--success);
    background: var(--surface-secondary);

    .steps {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 1rem;
      align-self: stretch;

      @include variables.mobile {
        flex-direction: column;
      }

      .step {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;

        @include variables.mobile() {
          align-items: center;
        }

        .textInteractive {
          color: var(--text-interactive);
        }
      }
    }
  }
  .buttonContainer {
    @include variables.mobile() {
      width: 100%;
      padding: 1rem;
    }
  }
  .button {
    min-width: 15rem;
    @include variables.mobile() {
      width: 100%;
    }
  }
}
