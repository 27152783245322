@use '../../ui/styles/typography';
@use '../../layout/variables';

.body {
  @include typography.textSmall;
  padding: 0;
  color: var(--text);
  background-color: var(--background-secondary);
  overflow-y: scroll;

  .option {
    display: flex;
    flex-direction: column;
    background: var(--background);
    border-bottom: 1px solid var(--contrast-low);
    padding: 1.5rem;

    @include variables.mobile() {
      padding: 1.25rem 1rem;
    }

    &.compact {
      @include variables.mobile() {
        padding: 0.5rem 1rem;
      }
    }

    .subSelect {
      margin-top: 1.5rem;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    .label {
      @include typography.text;
      word-break: break-word;
    }

    .hint,
    .content {
      @include typography.textSmall;
      color: var(--text-secondary);
    }
  }

  :global(.radio) {
    margin: 0 !important;
  }

  :global(button) {
    background-color: transparent;
    border: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
}

.foot {
  background-color: var(--background-secondary);

  button {
    color: var(--text);
  }

  @include variables.mobile() {
    @include typography.textSmall;
  }
}
