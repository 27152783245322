@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  padding: 1.5rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .cardHead {
    width: 100%;
    cursor: pointer;
  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .title,
  .subtitle {
    .label {
      color: var(--text-secondary);
      text-align: start;
      max-width: 30%;
    }

    .name {
      color: var(--text);
      width: 100%;
      text-align: start;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .value {
      color: var(--text);
      max-width: 70%;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  }

  .subtitle {
    @include typography.textSmall;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--text-secondary);
    padding-top: 0.5rem;
  }

  .address {
    text-align: end;
  }

  .remove {
    display: flex;
    justify-content: flex-end;
    color: var(--error);
    width: 100%;
  }

  .more {
    @include typography.textSmall;
    display: flex;
    justify-content: flex-end;
    color: var(--text-interactive);
    width: 100%;
  }
}
