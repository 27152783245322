@use '../../ui/styles/typography';

.status {
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-inline-end: 0.5rem;
  }
}

.verified {
  color: var(--success);
}

.pending {
  cursor: pointer;
  color: var(--text-interactive);
}
