@use '../../ui/styles/typography';

.radio {
  margin-inline-end: 1rem;
}

.label {
  @include typography.text;
}

.inactive {
  box-shadow: none;
  opacity: 0.5;
}
