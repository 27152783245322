.number {
  color: var(--text);
}

.body {
  background-color: var(--background);
}

.primary,
.delete {
  padding: 0.906rem 1.375rem;
  display: flex;
  align-items: center;

  .label {
    margin-inline-start: 1.375rem;
  }
}

.divider {
  background-color: var(--contrast-low);
  height: 1px;
  margin: 0;
}

.primary {
  color: var(--text);
}

.delete {
  color: var(--error);
}
