@use '../../../layout/variables';
@use '../../../ui/styles/typography';

.modalContainer {
  max-width: 360px;
}

.noMargin {
  margin: 0 !important;
}

.error {
  color: var(--error);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 37.5rem;
  margin: auto;

  :global(.button) {
    width: 100%;
  }

  .action {
    display: flex;
    margin-top: 1.5rem;
    gap: 0.5rem;
  }

  .info {
    @include typography.text;

    span:first-child {
      color: var(--error);
      text-transform: uppercase;
    }
  }

  .balance {
    color: var(--text-secondary);
    padding-inline-start: 1rem;

    :last-child {
      margin-inline-start: 0.25rem;
    }
  }

  .amountWrapper {
    display: flex;
    gap: 0.5rem;

    .first {
      flex-grow: 1;
    }

    .last {
      width: 6rem;
    }
  }

  .grayText {
    color: var(--text-secondary);
  }

  .walletNotice {
    background-color: var(--warning-bg);
    display: flex;
    gap: 0.625rem;
    padding: 0.5rem;
    border-radius: variables.$radius-full;

    .symbolWrapper {
      border-radius: 100px;
      background: var(--contrast-min);
      padding: 0.25rem;
      height: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .symbol {
        color: var(--text);
        padding: 0.15rem;
        height: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        border: 1.5px solid var(--text);
      }
    }
  }
}

.currency {
  @include typography.textStrong;
  background-color: var(--background);
  padding: 1rem 0 1rem 2rem;
  border-bottom: 1px solid var(--contrast-low);
}

.primaryText {
  color: var(--primary);
}

.marginBottom {
  margin-bottom: 0.5rem;
}
