@use '../../../../layout/variables';

.formSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: variables.$radius-large;
  padding: 2rem;
  border: 1px solid var(--contrast-low);

  @include variables.mobile() {
    margin-inline-start: 0;
    padding: 1rem;
  }

  .form {
    h3 {
      padding-bottom: 1.5rem;
    }

    .field {
      border: 1px solid var(--contrast-medium-low);
      box-sizing: border-box;
      border-radius: variables.$radius-medium;
      padding: 1rem;
      display: flex;
      gap: 1rem;
    }

    .isActive {
      border: 1.5px solid var(--text);
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2.5rem auto 0 auto;
      padding: 0 120px;

      @include variables.mobile() {
        padding: 0;
        width: 100%;
      }
    }
  }

  .fewSteps {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
