// Red
$red-900: #560000;
$red-800: #700000;
$red-700: #910000;
$red-600: #ba0000;
$red-500: #cc0000;
$red-400: #d63333;
$red-300: #dd5454;
$red-200: #e88a8a;
$red-100: #efb0b0;
$red-50: #fae6e6;
$a-500: #ff0022;
$a-400: #ed0037;

:export {
  red900: $red-900;
  red800: $red-800;
  red700: $red-700;
  red600: $red-600;
  red500: $red-500;
  red400: $red-400;
  red300: $red-300;
  red200: $red-200;
  red100: $red-100;
  red50: $red-50;
  a500: $a-500;
  a400: $a-400;
}

// Black
$black-900: #06090c;
$black-800: #0b0f19;
$black-700: #1f2837;
$black-600: #404a59;
$black-500: #5c6676;
$black-400: #8d97a6;
$black-300: #bac3d1;
$black-200: #d4dbe7;
$black-100: #ebeff6;
$black-50: #f3f6fb;

:export {
  black900: $black-900;
  black800: $black-800;
  black700: $black-700;
  black600: $black-600;
  black500: $black-500;
  black400: $black-400;
  black300: $black-300;
  black200: $black-200;
  black100: $black-100;
  black50: $black-50;
}

// Blue
$blue-900: #04386b;
$blue-800: #06498c;
$blue-700: #075eb5;
$blue-600: #0979e8;
$blue-500: #0a85ff;
$blue-400: #3b9dff;
$blue-300: #5badff;
$blue-200: #8ec7ff;
$blue-100: #b3d9ff;
$blue-50: #e7f3ff;

:export {
  blue900: $blue-900;
  blue800: $blue-800;
  blue700: $blue-700;
  blue600: $blue-600;
  blue500: $blue-500;
  blue400: $blue-400;
  blue300: $blue-300;
  blue200: $blue-200;
  blue100: $blue-100;
  blue50: $blue-50;
}

// Green
$green-700: #009100;
$green-600: #00ba00;
$green-500: #00cc00;
$green-400: #33d633;
$green-300: #54dd54;
$green-200: #8ae88a;
$green-100: #b0efb0;
$green-50: #e6fae6;

:export {
  green700: $green-700;
  green600: $green-600;
  green500: $green-500;
  green400: $green-400;
  green300: $green-300;
  green200: $green-200;
  green100: $green-100;
  green50: $green-50;
}

// Yellow
$yellow-700: #aa751a;
$yellow-600: #da9622;
$yellow-500: #f0a525;
$yellow-400: #f3b751;
$yellow-300: #f5c36d;
$yellow-200: #f8d69b;
$yellow-100: #fae3bb;
$yellow-50: #fef6e9;

:export {
  yellow700: $yellow-700;
  yellow600: $yellow-600;
  yellow500: $yellow-500;
  yellow400: $yellow-400;
  yellow300: $yellow-300;
  yellow200: $yellow-200;
  yellow100: $yellow-100;
  yellow50: $yellow-50;
}

// White
$white: #ffffff;

:export {
  white: $white;
}

// Contrast
$contrast: #f8f8f8;
$contrast-dark: #1d1d1d;
$contrast-hint: $white;
$contrast-hint-dark: #292929;
$contrast-min: $white;
$contrast-min-dark: #121212;
$contrast-low: #ececec;
$contrast-low-dark: #353535;
$contrast-medium-low: #bebebe;
$contrast-medium-low-dark: #5e5e5e;
$contrast-medium: #848484;
$contrast-medium-dark: #848484;
$contrast-medium-high: #606060;
$contrast-medium-high-dark: #aeaeae;
$contrast-high: #3e3e3e;
$contrast-high-dark: #e3e3e3;
$contrast-max: #000000;
$contrast-max-dark: $white;
$surface-secondary: $white;
$surface-secondary-dark: #252525;

:export {
  contrast: $contrast;
  contrastDark: $contrast-dark;
  contrastHint: $contrast-hint;
  contrastHintDark: $contrast-hint-dark;
  contrastMin: $contrast-min;
  contrastMinDark: $contrast-min-dark;
  contrastLow: $contrast-low;
  contrastLowDark: $contrast-low-dark;
  contrastMediumLow: $contrast-medium-low;
  contrastMediumLowDark: $contrast-medium-low-dark;
  contrastMedium: $contrast-medium;
  contrastMediumDark: $contrast-medium-dark;
  contrastMediumHigh: $contrast-medium-high;
  contrastMediumHighDark: $contrast-medium-high-dark;
  contrastHigh: $contrast-high;
  contrastHighDark: $contrast-high-dark;
  contrastMax: $contrast-max;
  contrastMaxDark: $contrast-max-dark;
  surfaceSecondary: $surface-secondary;
  surfaceSecondaryDark: $surface-secondary-dark;
}

// Background
$background: #fafafa;
$background-dark: #121212;
$background-secondary: $white;
$background-secondary-dark: #252525;
$background-gray: #e5e5e5;
$background-gray-dark: #848484;
$background-divider: $black-100;
$background-divider-dark: $black-700;
$background-selected: $blue-500;
$background-selected-dark: $blue-500;
$background-accent-red: $red-500;
$background-accent-red-dark: $red-500;
$background-btn-primary: $red-500;
$background-btn-primary-dark: $red-600;
$background-content: $black-50;
$background-content-dark: $black-700;
$background-modal: $white;
$background-modal-dark: $black-700;
$background-bg: $white;
$background-bg-dark: $black-800;
$background-bg2: $black-50;
$background-bg2-dark: $black-600;
$background-chart-yellow: $yellow-500;
$background-chart-yellow-dark: $yellow-500;
$background-chart-green: $green-700;
$background-chart-green-dark: $green-700;
$background-chart-blue: $blue-500;
$background-chart-blue-dark: $blue-500;
$progress-bar-primary: $a-500;
$progress-bar-primary-dark: $a-500;
$progress-bar-secondary: $black-200;
$progress-bar-secondary-dark: $black-400;
$progress-bar-blue: $blue-500;
$progress-bar-blue-dark: $blue-500;

:export {
  background: $background;
  backgroundDark: $background-dark;
  backgroundSecondary: $background-secondary;
  backgroundSecondaryDark: $background-secondary-dark;
  backgroundGray: $background-gray;
  backgroundGrayDark: $background-gray-dark;
  backgroundDivider: $background-divider;
  backgroundDividerDark: $background-divider-dark;
  backgroundSelected: $background-selected;
  backgroundSelectedDark: $background-selected-dark;
  backgroundAccentRed: $background-accent-red;
  backgroundAccentRedDark: $background-accent-red-dark;
  backgroundBtnPrimary: $background-btn-primary;
  backgroundBtnPrimaryDark: $background-btn-primary-dark;
  backgroundContent: $background-content;
  backgroundContentDark: $background-content-dark;
  backgroundModal: $background-modal;
  backgroundModalDark: $background-modal-dark;
  backgroundBg: $background-bg;
  backgroundBgDark: $background-bg-dark;
  backgroundBg2: $background-bg2;
  backgroundBg2Dark: $background-bg2-dark;
  backgroundChartYellow: $background-chart-yellow;
  backgroundChartYellowDark: $background-chart-yellow-dark;
  backgroundChartGreen: $background-chart-green;
  backgroundChartGreenDark: $background-chart-green-dark;
  backgroundChartBlue: $background-chart-blue;
  backgroundChartBlueDark: $background-chart-blue-dark;
  progressBarPrimary: $progress-bar-primary;
  progressBarPrimaryDark: $progress-bar-primary-dark;
  progressBarSecondary: $progress-bar-secondary;
  progressBarSecondaryDark: $progress-bar-secondary-dark;
  progressBarBlue: $progress-bar-blue;
  progressBarBlueDark: $progress-bar-blue-dark;
}

// Foreground
$foreground-primary-red: $red-500;
$foreground-primary-red-dark: $a-500;
$foreground-primary: $black-700;
$foreground-primary-dark: $white;
$foreground-secondary: $black-600;
$foreground-secondary-dark: $black-100;
$foreground-tertiary: $black-500;
$foreground-tertiary-dark: $black-200;
$foreground-neutral: $black-400;
$foreground-neutral-dark: $black-400;
$foreground-disabled: $black-300;
$foreground-disabled-dark: $black-500;
$foreground-white: $white;
$foreground-white-dark: $white;
$foreground-inverse: $white;
$foreground-inverse-dark: $black-700;
$foreground-accent: $blue-500;
$foreground-accent-dark: $blue-400;
$foreground-negative: $red-500;
$foreground-negative-dark: $red-500;

:export {
  foregroundPrimaryRed: $foreground-primary-red;
  foregroundPrimaryRedDark: $foreground-primary-red-dark;
  foregroundPrimary: $foreground-primary;
  foregroundPrimaryDark: $foreground-primary-dark;
  foregroundSecondary: $foreground-secondary;
  foregroundSecondaryDark: $foreground-secondary-dark;
  foregroundTertiary: $foreground-tertiary;
  foregroundTertiaryDark: $foreground-tertiary-dark;
  foregroundNeutral: $foreground-neutral;
  foregroundNeutralDark: $foreground-neutral-dark;
  foregroundDisabled: $foreground-disabled;
  foregroundDisabledDark: $foreground-disabled-dark;
  foregroundWhite: $foreground-white;
  foregroundWhiteDark: $foreground-white-dark;
  foregroundInverse: $foreground-inverse;
  foregroundInverseDark: $foreground-inverse-dark;
  foregroundAccent: $foreground-accent;
  foregroundAccentDark: $foreground-accent-dark;
}

// Text
$always-white: $white;
$text: #3c3c3c;
$text-primary: #3c3c3c;
$text-dark: #f0f0f0;
$text-secondary: #868686;
$text-secondary-dark: #8f8f8f;
$text-primary-dark: #8f8f8f;
$text-interactive: #0a98ff;
$text-interactive-dark: #0d82d8;
$text-positive: $green-500;
$text-positive-dark: $green-400;
$text-disabled: $black-300;
$text-disabled-dark: $black-500;
$text-neutral: $black-400;
$text-neutral-dark: $black-400;
$surface-information: #dde5f0;
$surface-information-dark: #dde5f0;
$surface-disabled: #ececec;
$surface-disabled-dark: #353535;
$surface-dark: #3e3e3e;
$text-on-primary-color: $white;
$text-on-primary-color-dark: $white;
$text-inverse: $white;
$text-inverse-dark: #000000;
$disabled: #b7b7b7;
$disabled-dark: #626262;
$primary: $red-500;
$primary-dark: #9f0000;
$primary-focused: #890000;
$primary-focused-dark: #710000;
$primary-disabled: $red-200;
$primary-disabled-dark: $red-800;
$interactive-hover: #0057ff;
$press: #e2e2e2;
$press-dark: #555;

:export {
  alwaysWhite: $always-white;
  text: $background;
  textDark: $text-dark;
  textSecondary: $text-secondary;
  textSecondaryDark: $text-secondary-dark;
  textPrimary: $text-primary;
  textPrimaryDark: $text-primary-dark;
  textInteractive: $text-interactive;
  textInteractiveDark: $text-interactive-dark;
  textPositive: $text-positive;
  textPositiveDark: $text-positive-dark;
  textDisabled: $text-disabled;
  textDisabledDark: $text-disabled-dark;
  textNeutral: $text-neutral;
  textNeutralDark: $text-neutral-dark;
  surfaceInformation: $surface-information;
  surfaceInformationDark: $surface-information-dark;
  surfaceDisabled: $surface-disabled;
  surfaceDisabledDark: $surface-disabled-dark;
  surfaceDark: $surface-dark;
  textOnPrimaryColor: $text-on-primary-color;
  textOnPrimaryColorDark: $text-on-primary-color-dark;
  textInverse: $text-inverse;
  textInverseDark: $text-inverse-dark;
  disabled: $disabled;
  disabledDdark: $disabled-dark;
  primary: $primary;
  primaryDark: $primary-dark;
  primaryFocused: $primary-focused;
  primaryFocusedDark: $primary-focused-dark;
  primaryDisabled: $primary-disabled;
  primaryDisabledDark: $primary-disabled-dark;
  press: $press;
  pressDark: $press-dark;
}

// Variables
$success-new: #00b728;
$success-new-dark: #00b728;
$success: #00b728;
$success-dark: #529631;
$success-old: #60aa3c;
$success-old-dark: #529631;
$error: #ef0000;
$error-dark: #d70b0b;
$surface-success: #e9ffee;
$surface-error: #f9eded;
$surface-error-dark: #241111;
$warning: #f19007;
$warning-dark: #e78700;
$warning-bg: #fef4e6;
$warning-bg-dark: #271e10;
$select-blue: #e6f5ff;
$select-blue-dark: #102d41;
$gold: #c1a74a;
$gold-dark: #aa9035;
$diamond: #292929;
$diamond-dark: #242424;
$blue: #050505;
$icon-positive: $green-500;
$icon-positive-dark: $green-700;
$icon-transaction: $black-300;
$icon-transaction-dark: $black-600;
$icon-transfer: #3b9dff;
$icon-transfer-dark: #075eb5;
$icon-tertiary: $black-400;
$icon-tertiary-dark: $black-300;
$icon-secondary: $black-600;
$icon-secondary-dark: $black-50;
$ib-transaction-history-skeleton-wrapper: $black-50;
$ib-transaction-history-skeleton-wrapper-dark: #202b37;
$ib-transaction-history-skeleton-box: #202b37;
$ib-transaction-history-skeleton-box-dark: $white;

:export {
  success: $success;
  successDark: $success-dark;
  successOld: $success-old;
  successOldDark: $success-old-dark;
  successNew: $success-new;
  error: $error;
  errorDark: $error-dark;
  surfaceSuccess: $surface-success;
  surfaceError: $surface-error;
  surfaceErrorDark: $surface-error-dark;
  warning: $warning;
  warningDark: $warning-dark;
  gold: $gold;
  goldDark: $gold-dark;
  diamond: $diamond;
  diamondDark: $diamond-dark;
  blue: $blue;
  iconPositive: $icon-positive;
  iconPositiveDark: $icon-positive-dark;
  iconTransaction: $icon-transaction;
  iconTransactionDark: $icon-transaction-dark;
  iconTransfer: $icon-transfer;
  iconTransferDark: $icon-transfer-dark;
  iconTertiary: $icon-tertiary;
  iconTertiaryDark: $icon-tertiary-dark;
  iconSecondary: $icon-secondary;
  iconSecondaryDark: $icon-secondary-dark;
  ibTransactionHistorySkeletonBox: $ib-transaction-history-skeleton-box;
  ibTransactionHistorySkeletonBoxDark: $ib-transaction-history-skeleton-box-dark;
  ibTransactionHistorySkeletonWrapper: $ib-transaction-history-skeleton-wrapper;
  ibTransactionHistorySkeletonWrapperDark: $ib-transaction-history-skeleton-wrapper-dark;
}

// State
$state-hover: $black-50;
$state-hover-dark: $black-600;
$state-active: $black-50;
$state-active-dark: $black-600;
$state-press: $black-100;
$state-press-dark: $black-500;
$state-background-hover: $black-100;
$state-background-hover-dark: $black-500;
$state-background-active: $black-100;
$state-background-active-dark: $black-500;
$state-background-press: $black-200;
$state-background-press-dark: $black-400;
$state-table-hover: $black-50;
$state-table-hover-dark: $black-700;

:export {
  stateHover: $state-hover;
  stateHoverDark: $state-hover-dark;
  stateActive: $state-active;
  stateActiveDark: $state-active-dark;
  statePress: $state-press;
  statePressDark: $state-press-dark;
  stateBackgroundHover: $state-background-hover;
  stateBackgroundHoverDark: $state-background-hover-dark;
  stateBackgroundActive: $state-background-active;
  stateBackgroundActiveDark: $state-background-active-dark;
  stateBackgroundPress: $state-background-press;
  stateBackgroundPressDark: $state-background-press-dark;
  stateTableHover: $state-table-hover;
  stateTableHoverDark: $state-table-hover-dark;
}

// Badge
$badge-success: $green-500;
$badge-success-dark: $green-600;
$badge-blue: $blue-500;
$badge-blue-dark: $blue-600;
$badge-red: $red-500;
$badge-red-dark: $red-600;
$badge-yellow: $yellow-500;
$badge-yellow-dark: $yellow-600;
$badge-black: $black-800;
$badge-black-dark: $black-500;

:export {
  badgeSuccess: $badge-success;
  badgeSuccessDark: $badge-success-dark;
  badgeBlue: $badge-blue;
  badgeBlueDark: $badge-blue-dark;
  badgeRed: $badge-red;
  badgeRedDark: $badge-red-dark;
  badgeYellow: $badge-yellow;
  badgeYellowDark: $badge-yellow-dark;
  badgeBlack: $badge-black;
  badgeBlackDark: $badge-black-dark;
}

// Border
$border-focused: $blue-200;
$border-focused-dark: $blue-700;
$border-secondary: $black-100;
$border-secondary-dark: $black-600;

:export {
  borderFocused: $border-focused;
  borderFocusedDark: $border-focused-dark;
  borderSecondary: $border-secondary;
  borderSecondaryDark: $border-secondary-dark;
}

// Tint
$accent-tint: $blue-50;
$accent-tint-dark: $blue-700;
$positive-tint: $green-50;
$positive-tint-dark: $green-700;
$negative-tint: $red-50;
$negative-tint-dark: $red-700;
$warning-tint: $yellow-50;
$warning-tint-dark: $yellow-700;

:export {
  accentTint: $accent-tint;
  accentTintDark: $accent-tint-dark;
  positiveTint: $positive-tint;
  positiveTintDark: $positive-tint-dark;
  negativeTint: $negative-tint;
  negativeTintDark: $negative-tint-dark;
  warningTint: $warning-tint;
  warningTintDark: $warning-tint-dark;
}
