@use '../../../../ui/styles/typography';
@use '../../../../layout/variables';

.radioError {
  border: 2px solid variables.$red;
  padding: 1rem;
  border-radius: variables.$radius-large;
}

.ukTerms {
  @include typography.textSmall;
  display: flex;
  gap: 1rem;
}

.nativeNameContainer {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.updatePhone {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .phoneWrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .countryCode {
      width: 120px;
      height: 56px;
    }

    .phoneNumberWrapper {
      width: 100%;

      .phoneNumber {
        flex: 1;
        height: 56px;
      }
    }
  }

  .buttonWrapper {
    display: flex;
    justify-content: space-around;

    button {
      width: 240px;

      @include variables.mobile() {
        width: 100%;
      }
    }
  }
}

.usWarning {
  .usWarningHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .subtitle {
    @include typography.textStrong;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  .text {
    @include typography.text;
    margin-bottom: 1rem;
  }

  .list {
    margin-top: 2rem;
    list-style: outside;
    padding-inline-start: 15px;

    .text {
      margin-bottom: 0;
    }
  }

  .buttonWrapper {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include variables.mobile {
      flex-direction: column;
    }

    button {
      &:first-child {
        margin-inline-end: 0.5rem;
        min-width: 48%;
      }

      &:last-child {
        min-width: 48%;
      }

      @include variables.mobile {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }
}

.form {
  h3 {
    padding-top: 1.5rem;
  }

  .genderForm {
    display: flex;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }

  :global(.field) {
    position: relative;

    > label > span {
      position: absolute;
      top: 18px;
      right: 0;
    }
  }

  :global(input[type='radio']) {
    accent-color: var(--text);
  }

  .regulator {
    margin-top: 1rem;
    cursor: pointer;

    :global(.control) {
      border: 1px solid var(--contrast-medium-low);
      box-sizing: border-box;
      border-radius: variables.$radius-medium;
      padding: 1rem;
    }

    .isActive {
      border: 1.5px solid var(--text-interactive);
    }

    .details {
      padding: 1rem;
      border: 1px solid var(--contrast-medium-low);
      box-sizing: border-box;
      border-radius: variables.$radius;
    }

    .regulatorDetails {
      display: flex;
      justify-content: space-around;
      //border: 1px solid var(--contrast-medium-low);
      border-radius: variables.$radius;
      margin: 0.5rem 0;
      padding: 0.75rem;
      gap: 0.25rem;

      .centerSmallText {
        text-align: center;
      }

      .optionWrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5rem auto 0 auto;
    padding: 0 120px;

    @include variables.mobile() {
      padding: 0;
      width: 100%;
    }
  }

  .row {
    display: flex;
  }
}

.formSection {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: variables.$radius-large;
  margin-inline-start: 0.5rem;
  padding: 2rem;
  border: 1px solid var(--contrast-low);

  @include variables.mobile() {
    border: none;
    margin-inline-start: 0;
    padding: 1rem;
    order: 3;

    &.reversed {
      order: 1;
    }
  }

  > form > h3 {
    padding-bottom: 1.5rem;
  }
}

.box {
  background-color: var(--background-secondary);
  padding: 1.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  flex-wrap: wrap;
}

:global(.react-datepicker-popper) {
  z-index: 11 !important;
}

.plainButton {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: var(--text-interactive);
  @include typography.text;
}

.modal {
  margin-top: 2rem !important;
}

input[type='checkbox'] {
  &.hasIncreasedSize {
    margin-inline-end: 1rem !important;
    transform: scale(1.5);
  }
}
.checkbox {
  margin-left: 0.25rem;
}

.bulletList {
  list-style: disc;
}

.error {
  color: var(--error);
  margin-bottom: 0.75rem;
}

.validationError {
  @include typography.text;
  color: var(--error);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.dateContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;

  .dateForm {
    @include typography.text;
    min-width: 7rem;

    @include variables.mobile() {
      min-width: 0;
    }

    &.monthField {
      @include variables.mobile() {
        min-width: 8rem;
      }
    }
  }
}

.productContainer {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  gap: 1.5rem;

  @include variables.mobile() {
    flex-direction: column;
    margin: 0;
  }

  .itemContainer {
    align-items: center;
    flex-direction: row;
    @include variables.mobile() {
      gap: 1rem;
      margin-right: 0;
      flex-direction: column;
      align-items: flex-start;
    }
    .label {
      padding: 0.25rem 0.5rem;
      background-color: var(--surface-dark);
      border-radius: variables.$radius-medium;
      span {
        color: var(--contrast);
      }
    }
    svg {
      margin-left: 0.5rem;
    }
  }

  .item {
    margin-right: 1rem;
  }
}

.textStrong {
  @include typography.textStrong;
}

.textSmallSecondary {
  @include typography.textSmall;
  color: var(--text-secondary);
}

.textSmall {
  @include typography.textSmall;
}

.mobileVerificationWrapper {
  .fieldBlock {
    width: 3.125rem;
    margin-left: 0.785rem;
    margin-bottom: 0.75rem;

    &:first-child {
      margin-left: 0;
      margin-inline-start: 0;
    }

    @include variables.mobile() {
      gap: 1rem;
    }
  }

  :global(button) {
    padding: 0;
  }

  .submitBtn {
    display: flex;
    align-items: center;
    width: 256px;
    margin: 2rem auto auto;

    @include variables.mobile() {
      width: 100%;
      margin: 7rem auto 0 auto;
    }
  }
}

.verificationBox {
  text-align: center;
}

.verificationBoxError {
  border-color: variables.$error !important;
}

.verificationBoxSuccess {
  border-color: variables.$success !important;
}

.errorWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 608px;
  padding: 3rem;
  margin: 7.25rem auto 0;
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge;
  box-shadow: 0 0.5rem 2.5rem 0 rgba(0, 0, 0, 0.04);

  @include variables.mobile() {
    max-width: 100%;
    padding: 0;
    margin: 7.75rem 0 0;
    background-color: transparent;
    box-shadow: none;
  }

  .errorContent {
    text-align: center;
    padding-top: 1.5rem;
    color: var(--text-secondary);
  }

  .errorLink {
    background: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
    color: var(--text-interactive);
    padding: 0;
    @include typography.text;
  }

  .goBackWrapper {
    padding-top: 0.5rem;
    color: var(--text-secondary);

    @include variables.mobile() {
      padding-top: 1rem;
    }
  }
}
