@use '../../layout/variables';
@use '../../ui/styles/typography';

.ibDashboardPage {
  display: flex;
  gap: 2.62rem;
  align-items: flex-start;

  @include variables.mobile() {
    flex-direction: column;
    align-items: stretch;
    gap: 1.87;
  }

  .col {
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    &:first-child {
      width: 60%;

      @include variables.mobile() {
        width: 100%;
      }
    }

    &:last-child {
      flex: 1;
    }

    @include variables.mobile() {
      flex: 1;
      gap: 1.87rem;
    }
  }
}
