@use '../../layout/variables';
@use '../../ui/styles/typography';

:global(.modal-background) {
  background-color: rgba(10, 10, 10, 0.6);
}

:global(.modal-card-head) {
  padding: 0 1.5rem;
  height: 4.5rem;
}

.modal {
  cursor: initial;
  text-align: initial;
  justify-content: center;
  z-index: map-get(variables.$zIndex, modal);
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  &:global(.is-active) {
    display: block;
    max-width: initial;
  }

  :global(.modal-card-body) {
    color: var(--text) !important;
    background-color: var(--background) !important;
    max-height: 60vh;
    overflow-y: auto;
  }

  .modalCard {
    margin-top: calc(#{0px} + #{variables.$modal-offset});
    max-width: 94%;
    width: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - #{variables.$modal-offset} - 1rem);
    overflow: visible;

    @include variables.mobile() {
      max-width: 100%;
      max-height: calc(100vh - #{variables.$modal-card-spacing});
      top: variables.$modal-card-spacing;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      padding: 20px;
      margin-top: 0;
    }
  }

  &.pullDown {
    .modalCard {
      margin-top: calc(#{0px} + #{variables.$navbar-height} + #{variables.$modal-offset});

      @include variables.mobile() {
        top: initial;
        bottom: 0;
        padding: 0;
        position: fixed;
        overflow-y: auto;
        left: 50%;
        right: initial;
        transform: translateX(-50%);
      }

      .modalWrapper {
        padding-bottom: 0;
      }
    }
  }

  .modalWrapper {
    position: relative;
    padding-bottom: 1rem;

    > header {
      background-color: var(--contrast-low);

      > p,
      :global(.modal-card-title) {
        @include typography.textLargeStrong;
        color: var(--text);
      }
    }

    > footer {
      justify-content: flex-end;
      border-top: 1px solid var(--contrast-low);
      padding: calc(1rem - 1px) 1rem 1rem;
      background-color: var(--background-secondary);

      @include variables.mobile() {
        padding-top: calc(0.5rem - 1px);
        padding-bottom: 0.5rem;
      }

      button {
        @include typography.textSmall;
        border: none;
        background-color: transparent;
        color: var(--text);
        &:hover {
          color: var(--text-secondary);
        }

        @include variables.mobile() {
          padding: 0 0.5rem;
        }

        &:last-child {
          @include typography.textSmallStrong;
        }
      }
    }
  }
}

.modalBackground {
  position: fixed;
}

@include variables.mobile() {
  :global(.modal-card-title) {
    width: 100%;
    word-break: break-word;
  }

  :global(.modal-card-body) {
    color: var(--text) !important;
    background-color: var(--background) !important;
    max-height: 50vh;
    overflow-y: auto;
  }
}

@include variables.desktop() {
  .modal {
    .modalCard {
      width: variables.$modal-content-width-desktop;
    }

    &:global(.is-small) .modalCard {
      width: variables.$modal-content-width;
    }

    &:global(.is-xsmall) .modalCard {
      width: variables.$modal-content-width-xsmall;
    }

    &:global(.is-large) .modalCard {
      width: variables.$modal-content-width-large;

      :global(.container) {
        padding: 0 5rem;
      }
    }
  }
}
