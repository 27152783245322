@use '../../ui/styles/typography';
@use '../../layout/variables';

.textSmall {
  @include typography.textSmall;
}

.noWrap {
  text-wrap: nowrap;
  overflow: hidden;
}

.borderRadius {
  border-radius: variables.$radius-xlarge !important;
}

.box {
  display: flex;
  border-radius: variables.$radius-medium;
  background-color: var(--background);
  gap: 0.5rem;

  .textSecondary {
    color: var(--text-secondary);
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--background);
  border-radius: variables.$radius-medium;
  gap: 4rem;
}

.expandableParent {
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0 !important;

  td {
    &:first-child {
      border-radius: variables.$radius-xlarge 0 0 0 !important;
    }

    &:last-child {
      border-radius: 0 variables.$radius-xlarge 0 0 !important;
    }
  }
}

.expandable {
  background-color: var(--background-secondary);

  td {
    border-radius: 0 0 variables.$radius-xlarge variables.$radius-xlarge !important;
  }

  td:hover {
    background-color: var(--background-secondary);
  }
}

.secondaryTextSmall {
  @include typography.textSmall;
  color: var(--text-secondary);
}

.transactionTypeName {
  display: flex;
  align-items: center;
  width: max-content;
}

.trimMid {
  @include typography.textTrimMid;
}

.disabled {
  color: var(--contrast-medium);
}

.visibilityHidden {
  visibility: hidden;
}

.infoWrapper {
  display: flex;
  justify-items: center;
  justify-content: center;
  gap: 0.25rem;

  .icon {
    padding-top: 0.25rem;
  }
}
