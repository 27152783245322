@use '../../layout/variables';
@use '../../ui/styles/typography';

.body {
  color: var(--text);
  background-color: var(--background-secondary);
  border-radius: variables.$radius-xlarge variables.$radius-xlarge 0 0 !important;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:last-child {
    margin-bottom: 0;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.5rem;

    .textTiny {
      color: var(--text-secondary);
    }
  }
}

.footer {
  display: flex;
  justify-content: center !important;
  background-color: var(--background-secondary) !important;
  border-top: 1px solid var(--background-gray);

  button {
    color: var(--text);
  }

  :global(.modal-card-foot) {
    justify-content: center;
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.5rem;

  .icon {
    margin: 1rem;
  }
}
