@use '../../../ui/styles/typography';
@use '../../../layout/variables';

.hasBorderBottom {
  border-bottom: 1px solid var(--contrast-low);
}

.tableWrapper {
  min-height: 19rem;
  margin-top: 0.5rem;
}

.dropdownIconContainer {
  position: relative;
  cursor: pointer;
}

.walletNameWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    padding-inline-end: 0.5rem;
    cursor: pointer;
  }
}
