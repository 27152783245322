@use '../../layout/variables';

.card {
  background: var(--background-secondary);
  box-shadow: 0 0.5rem 2.5rem rgba(0, 0, 0, 0.04);
  border-radius: variables.$radius-xlarge;
  padding: 10rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    margin-top: 2rem;
  }
}
