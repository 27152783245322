@use '../../ui/styles/typography';

.button {
  @include typography.text;
  cursor: pointer;
  border-bottom: 1px solid var(--contrast-low) !important;

  .iconWrap {
    display: flex;
  }
  &:last-child {
    border-bottom: none;
  }
}

.csvButton {
  display: flex;
}
