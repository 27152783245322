@use '../../../ui/styles/typography';

.appMarket {
  display: flex;
  flex-direction: column;
  color: var(--text);
  gap: 3rem;

  .codeWrapper {
    display: flex;
    justify-content: space-between;

    .title {
      margin-inline-start: 0.25rem;
    }

    .code {
      margin-top: 0.5rem;
    }
  }
}
