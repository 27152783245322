@use '../../../layout/variables';

:root {
  --nav-background-color: var(--contrast-low);
  --nav-color: var(--text);
}

[data-theme='dark'] {
  --nav-background-color: var(--contrast-low);
  --nav-color: var(--text);
}

[data-product='etd'] {
  --nav-background-color: var(--contrast-medium);
  --nav-color: var(--contrast-min);
}

.navigationWrapper {
  display: flex;
  flex-direction: column;

  .nav {
    position: relative;
    background-color: var(--nav-background-color);
    color: var(--nav-color);
    z-index: map-get(variables.$zIndex, header);

    .container {
      padding: 1rem 0.813rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .navbarItemWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;

        .burgerButton {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .menu {
      position: absolute;
      top: 62px;
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.24);
      z-index: map-get(variables.$zIndex, header);
      transition: all 0.3s ease-in-out;
      max-height: 0;
      overflow: hidden;
      color: var(--nav-color);

      &.active {
        max-height: 100vh;
      }

      .dropdown {
        background-color: var(--background);
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 1rem 0;
        gap: 0.5rem;
        overflow-y: auto;

        .divider {
          display: block;
          background-color: var(--text-secondary);
          border: none;
          height: 1px;
          margin: 0.5rem;
        }
      }
    }
  }
}

.navbarItem {
  color: var(--text-secondary);
  cursor: pointer;
  display: block;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  position: relative;

  &:hover,
  &.isActive {
    color: var(--primary);
  }
}
