@use '../../layout/variables';
@use '../../ui/styles/typography';

.card {
  text-align: center;
  padding: 0.75rem 1rem;
  background-color: var(--background-secondary);
  margin: 0.5rem 0;
  border-radius: variables.$radius-large;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  flex-direction: column;
  cursor: pointer;

  .title,
  .subtitle,
  .status {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .subtitle {
    @include typography.textSmall;

    > span {
      &:first-child {
        color: var(--text-secondary);
        text-align: start;
      }

      &:last-child {
        color: var(--text);
        text-align: end;
      }
    }
  }

  .error {
    color: variables.$error;
  }
}
